import { TranslationsApi } from 'oas';
import { useApiQuery } from '../use-api-query';
import { UseQueryResult } from '@tanstack/react-query';
import { useAuth } from 'auth';

export interface Translations {
    [key: string]: string;
}

export const useGetTranslations = (
    ...parameters: Parameters<typeof TranslationsApi.prototype.getTranslations>
): UseQueryResult<Translations> => {
    const auth = useAuth();

    return useApiQuery(
        ['translations', parameters],
        (apiConfig, axiosInstance) => {
            return new TranslationsApi(apiConfig, '', axiosInstance).getTranslations(...parameters);
        },
        {
            enabled: !!auth.user,
            staleTime: 3600 * 8 * 1000,
        },
    );
};
