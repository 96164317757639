import { Spinner, ThemeProvider } from '@SBGSports/referee-react';
import { Backdrop } from '@material-ui/core';
import { useRemoveDemoData } from 'api';
import { useTranslations } from 'i18n';
import { LegacyIframe, useIframeListener } from 'iframing';
import { MainLayout } from 'layout';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { appTitle, confirm, useSnackbar } from 'shared';
import { validate } from 'uuid';

const Timeline: React.FC = () => {
    const { __ } = useTranslations();
    const location = useLocation();

    const reloadRequest = useIframeListener<{ delay: number | undefined }>('__timeline_reload');
    const removeDemoDataRequest = useIframeListener<undefined>('__remove_demo_data');
    const { mutateAsync: removeDemoData } = useRemoveDemoData();
    const { displayValidationErrors, closeSnackbar } = useSnackbar();

    const [overlay, setOverlay] = React.useState(false);
    const [path, setPath] = React.useState('');
    React.useEffect(() => {
        if (reloadRequest !== undefined) {
            const iframe = document.getElementById('legacy-iframe') as HTMLIFrameElement | null;
            const delay = typeof reloadRequest.payload?.delay === 'number' ? reloadRequest.payload.delay : 0;

            if (iframe) {
                setTimeout(() => {
                    // Trigger reload
                    iframe.src += '';
                }, delay);
            }
        }
    }, [reloadRequest]);

    React.useEffect(() => {
        if (removeDemoDataRequest !== undefined) {
            (async () => {
                closeSnackbar();
                if (await confirm(__, __('demo_data.remove_demo_data_confirm'), { okButtonText: __('misc.remove') })) {
                    try {
                        setOverlay(true);
                        await removeDemoData();
                        window.location.reload();
                    } catch (responseError) {
                        setOverlay(false);
                        displayValidationErrors(responseError);
                    }
                }
            })();
        }
    }, [__, closeSnackbar, displayValidationErrors, removeDemoData, removeDemoDataRequest]);

    // If the hash is a guid, pass it through to the iframe src because it is an activity ID
    // This is used to navigate to a specific activity
    React.useEffect(() => {
        let newPath = '/';
        if (validate(location.hash.replace(/^#/, ''))) {
            newPath = `/${location.hash}`;
        }
        setPath(newPath);
    }, [location]);

    return (
        <MainLayout>
            <Helmet>
                <title>{appTitle(__('header.timeline'))}</title>
            </Helmet>
            <Backdrop open={overlay} style={{ zIndex: 2000 }}>
                <ThemeProvider theme="dark">
                    <Spinner />
                </ThemeProvider>
            </Backdrop>
            {path !== '' && <LegacyIframe path={path} />}
        </MainLayout>
    );
};

export default Timeline;
