import {
    Dialog as MuiDialog,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    DialogProps,
    DialogTitle as MuiDialogTitle,
    Typography,
} from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { ReactComponent as CloseIcon } from 'assets/icons/16px/cancel.svg';
import * as React from 'react';
import { IconButton } from './icon-button';

// See https://v4.mui.com/components/dialogs/#customized-dialogs

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
    });

export const Dialog = withStyles(styles)((props: DialogProps) => {
    return <MuiDialog disableRestoreFocus {...props} />;
});

export interface DialogTitleProps extends WithStyles<typeof styles> {
    children: React.ReactNode;
    onClose?: () => void;
    testId?: string;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, testId, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography
                variant="h3"
                style={{
                    borderBottom: 'solid 1px var(--r-grey-6)',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    margin: '0 0 -16px',
                    paddingBottom: '10px',
                }}
            >
                <div>{children}</div>
                {onClose ? (
                    <IconButton
                        data-testid={testId}
                        aria-label="close"
                        onClick={onClose}
                        style={{ marginLeft: 'auto' }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </Typography>
        </MuiDialogTitle>
    );
});

export interface DialogContentProps extends WithStyles<typeof styles> {
    children: React.ReactNode;
}

export const DialogContent = withStyles(styles)((props: DialogContentProps) => {
    const { children, classes, ...other } = props;
    return (
        <MuiDialogContent {...other} className={classes.root}>
            {children}
        </MuiDialogContent>
    );
});

export interface DialogActionsProps extends WithStyles<typeof styles> {
    children: React.ReactNode;
}

export const DialogActions = withStyles(styles)((props: DialogActionsProps) => {
    const { children, classes, ...other } = props;
    return (
        <MuiDialogActions {...other} className={classes.root}>
            {children}
        </MuiDialogActions>
    );
});
