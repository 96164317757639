import { AthleteThresholdsApi, ThresholdAlertSettings } from 'oas';
import { UseQueryResult } from '@tanstack/react-query';
import { mockResponse, useApiQuery } from '../use-api-query';
import { TfThresholdSet, transformThresholdSet } from './transform';

export const useGetThresholdSet: (
    ...parameters: Parameters<AthleteThresholdsApi['getThresholdSet']>
) => UseQueryResult<TfThresholdSet | null> = (
    id,
    include = ['thresholdSetAthletes', 'thresholdSetAthletes.athlete', 'thresholdSetAthletes.parameters'],
    options = {},
) =>
    useApiQuery(
        ['threshold_sets', id, include],
        (apiConfig, axiosInstance) => {
            return id !== ''
                ? new AthleteThresholdsApi(apiConfig, '', axiosInstance)
                      .getThresholdSet(id, include, options)
                      .then((response) => ({ ...response, data: transformThresholdSet(response.data) }))
                : mockResponse({
                      id: '',
                      name: '',
                      created_at: new Date(),
                      modified_at: new Date(),
                      is_deleted: 0,
                      athlete_count: 0,
                      thresholdSetAthletes: [],
                  });
        },
        options,
    );

export const useGetThresholdSets: (
    ...parameters: Parameters<AthleteThresholdsApi['listThresholdSets']>
) => UseQueryResult<TfThresholdSet[]> = (deleted, sort, include, options = {}) =>
    useApiQuery(
        ['threshold_sets', deleted, sort, include],
        (apiConfig, axiosInstance) =>
            new AthleteThresholdsApi(apiConfig, '', axiosInstance)
                .listThresholdSets(deleted, sort, include, options)
                .then((response) => ({ ...response, data: response.data.map(transformThresholdSet) })),
        options,
    );

export const useGetThresholdAlerts: (
    ...parameters: Parameters<AthleteThresholdsApi['getThresholdAlerts']>
) => UseQueryResult<ThresholdAlertSettings> = (options = {}) =>
    useApiQuery(['threshold_alerts'], (apiConfig, axiosInstance) =>
        new AthleteThresholdsApi(apiConfig, '', axiosInstance).getThresholdAlerts(options),
    );
