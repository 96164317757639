import { Customer, CustomerApi, User } from 'oas';
import { useApiQuery } from '../use-api-query';
import { UseQueryResult } from '@tanstack/react-query';

export const useGetCustomer = (): UseQueryResult<Customer> => {
    return useApiQuery(
        ['customer'],
        (apiConfig, axiosInstance) => new CustomerApi(apiConfig, '', axiosInstance).getCustomerInfo(),
        {
            staleTime: 3600 * 1000,
        },
    );
};

export const useGetUsers = (): UseQueryResult<User[]> => {
    return useApiQuery(['users'], (apiConfig, axiosInstance) =>
        new CustomerApi(apiConfig, '', axiosInstance).getCustomerUsers(),
    );
};
