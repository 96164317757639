import * as React from 'react';
import { NoticesApi } from 'oas';
import { UseQueryResult } from '@tanstack/react-query';
import { useApiQuery } from '../use-api-query';
import { TfNotice, useTransformNotice } from './transform';

export const useGetNotices = (): UseQueryResult<TfNotice[]> => {
    const result = useApiQuery(['notices'], (apiConfig, axiosInstance) =>
        new NoticesApi(apiConfig, '', axiosInstance).getNotices(),
    );

    const transformNotice = useTransformNotice();

    const data = React.useMemo(() => result.data?.map(transformNotice), [result.data, transformNotice]);

    return {
        ...result,
        data,
    } as UseQueryResult<TfNotice[]>;
};
