export const appTitle = (title = ''): string => {
    return `${title}${title && ' :: '}OpenField Cloud`;
};

interface StaticImageData {
    width: number;
    height: number;
}

// Returns width and height attributes for an image based one given dimension, scaling the other to maintain aspect ratio
export const imgDimensions = (
    image: StaticImageData,
    desiredSize?: { width?: number; height?: number },
): { width: number; height: number } => {
    let newWidth = image.width;
    let newHeight = image.height;

    if (desiredSize?.width !== undefined && desiredSize?.height === undefined) {
        newWidth = desiredSize.width;
        newHeight = image.height / (image.width / desiredSize.width);
    } else if (desiredSize?.height !== undefined && desiredSize?.width === undefined) {
        newHeight = desiredSize.height;
        newWidth = image.width / (image.height / desiredSize.height);
    }

    return {
        width: newWidth,
        height: newHeight,
    };
};
