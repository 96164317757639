import { AlertVariants, useAlerts } from '@SBGSports/referee-react';
import * as React from 'react';

export const useShowAlert = () => {
    const { showAlert, closeAlert } = useAlerts();
    const alertKey = React.useRef<string>();

    return React.useCallback(
        (title: string, content: React.ReactNode, variant: AlertVariants = 'alert', onClose?: () => void) => {
            if (alertKey.current) {
                closeAlert(alertKey.current);
            }
            alertKey.current = showAlert(title, content, {
                autoClose: true,
                variant,
                onClose,
                delay: 10000,
                dismissible: true,
            });
        },
        [closeAlert, showAlert],
    );
};
