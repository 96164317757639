import * as React from 'react';
import { Typography, Variables } from '@SBGSports/referee-react';
import { IconErrorWarningSmall } from '@SBGSports/referee-react/icons';
import { useGetPing } from 'api';

interface OfflineBannerProps {
    testId?: string;
}

const HEIGHT = 28;

export const OfflineBanner: React.FC<OfflineBannerProps> = ({ testId }) => {
    // Local state
    const [isBrowserOnline, setIsBrowserOnline] = React.useState(true);

    // API
    const { isFetched, data, isError } = useGetPing({
        staleTime: 0,
        refetchInterval: 30000,
    });

    const handleOffline = React.useCallback((event: { type: string }) => {
        setIsBrowserOnline(event.type === 'online');
    }, []);

    React.useEffect(() => {
        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOffline);
        // Cleanup
        return () => {
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('online', handleOffline);
        };
    }, [handleOffline]);

    if (isBrowserOnline && !isError && (!isFetched || data)) {
        return <></>;
    }

    return (
        <div
            data-testid={testId || 'offline-banner'}
            style={{
                background: Variables.css?.alert,
                color: Variables.css?.catapultWhite,
                boxShadow: Variables.css?.shadow1,
                height: HEIGHT,
                transitionProperty: 'all',
                transitionDuration: '500ms',
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                top: 0,
                width: '100vw',
                gap: 8,
            }}
        >
            <IconErrorWarningSmall />
            <Typography variant="body-2" style={{ color: Variables.css?.catapultWhite }}>
                Internet connection lost
            </Typography>
        </div>
    );
};
