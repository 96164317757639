import * as React from 'react';
import { useAuth } from 'auth';
import { MiniLayout } from 'layout';
import { Helmet } from 'react-helmet-async';
import { appTitle } from 'shared';
import { useHistory } from 'react-router-dom';
import { MfaForm } from './mfa-form';

export const Mfa: React.FC = () => {
    const { user, verifyMfa, logout } = useAuth();
    const [introMessage, setIntroMessage] = React.useState('');
    const history = useHistory();

    React.useEffect(() => {
        if (!user) {
            return;
        }

        setIntroMessage(user.mfa_message || '');
    }, [user]);

    React.useEffect(() => {
        if (!user?.require_mfa) {
            history.push('/');
        }
    }, [history, user]);

    return (
        <MiniLayout narrow>
            <Helmet>
                <title>{appTitle('MFA')}</title>
            </Helmet>
            {user && (
                <>
                    <p>{introMessage}</p>
                    <MfaForm onSubmit={verifyMfa} onCancel={logout} />
                </>
            )}
        </MiniLayout>
    );
};
export default Mfa;
