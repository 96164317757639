import * as React from 'react';
import {
    SelectOptions,
    SelectOptionProps,
    usePositioner,
    UnstyledButton,
    SelectOption,
    Button,
} from '@SBGSports/referee-react';
import { TableCellProps, Tooltip, TooltipProps } from '@material-ui/core';
import { ReactComponent as PencilIcon } from 'assets/icons/16px/pencil.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/16px/copy.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/16px/delete.svg';
import { IconButton, IconButtonProps, Table } from 'rsuite';
import { DATE_FNS_CASUAL_DATE_TIME_FORMAT, formatDuration, useDateFormatter } from 'shared';
import { IconCancelSmall, IconCheckSmall, IconMore } from '@SBGSports/referee-react/icons';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

const { Cell } = Table;

// Duration value should be in seconds
interface DurationCellProps extends TableCellProps {
    rowData?: Record<string, number>;
    dataKey?: string;
}
export const DurationCell: React.FC<DurationCellProps> = ({ rowData, dataKey, ...props }) => (
    <Cell {...props}>{formatDuration(dataKey && rowData ? rowData[dataKey] : 0)}</Cell>
);

interface DateTimeCellProps extends TableCellProps {
    rowData?: Record<string, Date>;
    dataKey: string;
    appendKey?: string;
}
export const DateTimeCell: React.FC<DateTimeCellProps> = ({ rowData, dataKey, appendKey, ...props }) => {
    let value = '';
    const formatDate = useDateFormatter();

    if (rowData?.[dataKey] instanceof Date) {
        value = formatDate(rowData?.[dataKey], DATE_FNS_CASUAL_DATE_TIME_FORMAT);
    }

    if (appendKey && rowData?.[appendKey]) {
        value += rowData?.[appendKey];
    }

    return <Cell {...props}>{value}</Cell>;
};

interface ButtonCellActionProps {
    href?: string | ((rowData: Record<string, unknown>, dataKey: string) => string);
    onClick?: (rowData: Record<string, unknown>, dataKey: string) => void;
    toolTipTitle?: string;
    icon?: IconButtonProps['icon'];
}

export interface BaseButtonCellProps extends Omit<TableCellProps, 'onClick'>, Omit<ButtonCellActionProps, 'icon'> {
    rowData?: Record<string, unknown>;
    dataKey?: string;
    valueFormat?: (value: string | unknown) => string;
}

export const EditButtonCell: React.FC<BaseButtonCellProps> = ({ rowData, dataKey, href, toolTipTitle, ...props }) => {
    let hrefString = '';
    if (typeof href === 'function') {
        hrefString = (rowData && dataKey && href && href(rowData, dataKey)) || '';
    } else if (typeof href === 'string') {
        hrefString = href;
    }

    const icon = (
        <Link to={hrefString}>
            <Button variant="text" endSlot={<PencilIcon />} as="a" />
        </Link>
    );

    return (
        <Cell className="button-cell" {...props}>
            {toolTipTitle ? (
                <Tooltip placement="top" title={toolTipTitle}>
                    {icon}
                </Tooltip>
            ) : (
                icon
            )}
        </Cell>
    );
};

export const CopyButtonCell: React.FC<BaseButtonCellProps> = ({ rowData, dataKey, href, toolTipTitle, ...props }) => {
    let hrefString = '';
    if (typeof href === 'function') {
        hrefString = (rowData && dataKey && href && href(rowData, dataKey)) || '';
    } else if (typeof href === 'string') {
        hrefString = href;
    }

    const icon = (
        <Link to={hrefString}>
            <Button variant="text" endSlot={<CopyIcon />} as="a" />
        </Link>
    );

    return (
        <Cell className="button-cell" {...props}>
            {toolTipTitle ? (
                <Tooltip placement="top" title={toolTipTitle}>
                    {icon}
                </Tooltip>
            ) : (
                icon
            )}
        </Cell>
    );
};

interface TooltipCellProps extends TableCellProps {
    rowData?: Record<string, Element>;
    dataKey?: string;
    shortKey?: string;
    placement?: TooltipProps['placement'];
}

export const TooltipCell: React.FC<TooltipCellProps> = ({
    rowData,
    dataKey,
    shortKey,
    placement = 'top-start',
    ...props
}) => (
    <Cell {...props}>
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Tooltip title={((rowData && dataKey && rowData[dataKey]) as React.ReactNode) || ''} placement={placement}>
                <span>{((rowData && dataKey && rowData[shortKey || dataKey]) as React.ReactNode) || ''}</span>
            </Tooltip>
        </div>
    </Cell>
);

export const CustomCell: React.FC<Pick<BaseButtonCellProps, 'rowData' | 'dataKey' | 'valueFormat'>> = ({
    rowData,
    dataKey,
    valueFormat,
    ...props
}) => {
    const formattedValue = valueFormat && rowData && dataKey && valueFormat(get(rowData, dataKey));
    return <Cell {...props}>{formattedValue}</Cell>;
};

export const BooleanCell: React.FC<Pick<BaseButtonCellProps, 'rowData' | 'dataKey'>> = ({
    rowData,
    dataKey,
    ...props
}) => {
    let icon = <span>&ndash;</span>;
    const style: React.CSSProperties = { textAlign: 'center' };

    if (rowData && dataKey) {
        if (rowData[dataKey] === true) {
            icon = <IconCheckSmall color="var(--main-ltsfc-success-green)" />;
        } else if (rowData[dataKey] === false) {
            icon = <IconCancelSmall color="var(--main-ltsfc-alert-red)" />;
        }
    }

    return (
        <Cell {...props} style={style}>
            {icon}
        </Cell>
    );
};

export const DeleteButtonCell: React.FC<BaseButtonCellProps> = ({
    rowData,
    dataKey,
    onClick,
    href,
    toolTipTitle,
    ...props
}) => {
    const handleClick = () => rowData && dataKey && onClick && onClick(rowData, dataKey);
    let hrefString;
    if (typeof href === 'function') {
        hrefString = rowData && dataKey && href && href(rowData, dataKey);
    } else if (typeof href === 'string') {
        hrefString = href;
    }

    const icon = <IconButton appearance="link" onClick={handleClick} href={hrefString} icon={<DeleteIcon />} />;

    return (
        <Cell className="button-cell" {...props}>
            {toolTipTitle ? (
                <Tooltip placement="top" title={toolTipTitle}>
                    {icon}
                </Tooltip>
            ) : (
                icon
            )}
        </Cell>
    );
};

interface ActionsButtonCellProps extends Pick<BaseButtonCellProps, 'rowData' | 'dataKey'> {
    actions: ButtonCellActionProps[];
}

export const ActionsButtonCell: React.FC<ActionsButtonCellProps> = ({ rowData, dataKey, actions, ...props }) => {
    return (
        <Cell className="button-cell" {...props}>
            {actions.map(({ href, icon, toolTipTitle, onClick }) => {
                const handleClick = () => rowData && dataKey && onClick && onClick(rowData, dataKey);
                let hrefString;
                if (typeof href === 'function') {
                    hrefString = rowData && dataKey && href && href(rowData, dataKey);
                } else if (typeof href === 'string') {
                    hrefString = href;
                }

                const actionIcon = <IconButton appearance="link" onClick={handleClick} href={hrefString} icon={icon} />;

                return toolTipTitle ? (
                    <Tooltip placement="top" title={toolTipTitle}>
                        {actionIcon}
                    </Tooltip>
                ) : (
                    actionIcon
                );
            })}
        </Cell>
    );
};

interface SelectOptionCellProps extends Pick<SelectOptionProps, 'menuItems'>, Pick<BaseButtonCellProps, 'rowData'> {
    onActionSelected: (action: SelectOption['id'], rowData: BaseButtonCellProps['rowData']) => void;
}

export const SelectOptionCell: React.FC<SelectOptionCellProps> = ({
    menuItems,
    rowData,
    onActionSelected,
    ...props
}) => {
    const dropdownRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
    const { getPopperProps, getReferenceProps } = usePositioner({
        placement: 'bottom-start',
    });
    const [isVisible, setIsVisible] = React.useState(false);

    const handleToggleVisible = () => setIsVisible(!isVisible);

    const handleActionSelect = (selectedAction: SelectOption) => {
        // Hide menu
        setIsVisible(false);
        onActionSelected(selectedAction.id, rowData);
    };

    return (
        <Cell className="button-cell" {...props}>
            <div ref={dropdownRef}>
                <UnstyledButton {...getReferenceProps()} onClick={handleToggleVisible}>
                    <IconMore />
                </UnstyledButton>
            </div>
            <SelectOptions
                popperProps={getPopperProps}
                parentRef={dropdownRef as React.MutableRefObject<HTMLElement>}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                menuItems={menuItems}
                onSelectItem={(selected) => handleActionSelect(selected as SelectOption)}
            />
        </Cell>
    );
};
