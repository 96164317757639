import { LinearProgress } from '@material-ui/core';
import axios from 'axios';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useFiltersStore } from 'shared/state/reporting/filters/filtersStore';

const logout = (): Promise<void> =>
    axios
        .get(`${process.env.REACT_APP_AUTH_HOST}/logout`)
        // Keep calling logout until we get a 401, then we are really logged out
        .then(logout)
        .catch(() => {
            // already logged out
            window.location.href = '/login';
        });

export const Logout: React.FC = () => {
    const { clearAll } = useFiltersStore();
    const called = React.useRef(false);
    React.useEffect(() => {
        if (!called.current) {
            called.current = true;
            clearAll();
            logout();
        }
    }, [clearAll]);

    return (
        <>
            <Helmet>
                <style>{'body { background-color: var(--r-catapult-black); }'}</style>
            </Helmet>
            <LinearProgress />
        </>
    );
};
