import { ParameterGroup, ParametersApi, ParameterType } from 'oas';
import { UseQueryResult } from '@tanstack/react-query';
import { useApiQuery } from '../use-api-query';
import { TfReportingParameterGroup, transformParameterGroups, transformParameterIntoGroups } from './transform';
import { useDependentApiQuery } from '../use-dependant-api-query';

export const useGetParameterGroups = (): UseQueryResult<ParameterGroup[]> => {
    return useApiQuery(['parameter_groups'], (apiConfig, axiosInstance) =>
        new ParametersApi(apiConfig, '', axiosInstance).getParameterGroups(),
    );
};

export const useGetReportingParameters = (): UseQueryResult<TfReportingParameterGroup[]> => {
    const types = useGetParameterTypes();
    return useDependentApiQuery(
        ['reporting_parameter_groups'],
        (apiConfig, axiosInstance) =>
            new ParametersApi(apiConfig, '', axiosInstance)
                .getParameterGroups()
                .then((response) => ({ ...response, data: transformParameterGroups(response.data, types.data ?? []) })),
        !!types.data,
    );
};

export const useGetParameterTypes = (): UseQueryResult<ParameterType[]> => {
    return useApiQuery(['parameter_types'], (apiConfig, axiosInstance) =>
        new ParametersApi(apiConfig, '', axiosInstance).getAllParameterTypes(),
    );
};

export const useGetGroupedParameters = (
    transformKey?: string,
    postTransform?: (params: TfReportingParameterGroup[]) => TfReportingParameterGroup[],
): UseQueryResult<TfReportingParameterGroup[]> => {
    const types = useGetParameterTypes();

    return useDependentApiQuery(
        ['all_parameters_grouped', transformKey],
        (apiConfig, axiosInstance) =>
            new ParametersApi(apiConfig, '', axiosInstance).getParameters().then((response) => {
                if (postTransform) {
                    const initial = transformParameterIntoGroups(response.data, types.data ?? []);
                    return {
                        ...response,
                        data: postTransform(initial),
                    };
                } else {
                    return { ...response, data: transformParameterIntoGroups(response.data, types.data ?? []) };
                }
            }),
        !!types.data,
    );
};

export const useGetVelocitySetOneAndTwoParameters = (): UseQueryResult<TfReportingParameterGroup[]> => {
    return useGetGroupedParameters('velocity_set_one_and_two', (params) => {
        return (
            params
                ?.map((group) => ({
                    ...group,
                    parameters: group.parameters.filter(
                        (parameter) =>
                            parameter.slug.startsWith('gen2_velocity') && parameter.slug.endsWith('effort_count'),
                    ),
                }))
                .filter((group) => group.parameters.length > 0) ?? []
        );
    });
};
