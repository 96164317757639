import { debounce } from 'lodash';
import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebouncedCallback = (callback: (...args: any) => void, dependencies: any[] = [], delay = 200) => {
    const internalDebounced = debounce(callback, delay);

    const debouncedCallback = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (...args: any) => {
            internalDebounced(...args);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        dependencies,
    );

    React.useEffect(() => {
        return () => internalDebounced?.cancel();
    }, [internalDebounced]);

    return debouncedCallback;
};
