import { useApiQuery } from 'api/use-api-query';
import { Benchmark, BenchmarksApi } from 'oas';
import { UseQueryResult } from '@tanstack/react-query';

export const useGetFallbackBenchmarks: (
    ...parameters: Parameters<BenchmarksApi['getFallbackBenchmarks']>
) => UseQueryResult<Benchmark[]> = (sport_id, options = {}) =>
    useApiQuery(
        ['fallback_benchmarks', sport_id],
        (apiConfig, axiosInstance) => {
            return new BenchmarksApi(apiConfig, '', axiosInstance).getFallbackBenchmarks(sport_id, options);
        },
        options,
    );
