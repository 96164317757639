import * as React from 'react';

export interface StripeProps {
    id: string;
    stripeWidth: number;
}

export const Stripe: React.FC<StripeProps> = ({ id, stripeWidth }) => (
    <svg
        viewBox="0 0 0 0"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        style={{ height: 0, width: 0 }}
    >
        <defs>
            <pattern
                id={id}
                width={stripeWidth * 2}
                height={stripeWidth}
                patternTransform="rotate(45)"
                patternUnits="userSpaceOnUse"
            >
                <line
                    x1={stripeWidth * 0.5}
                    x2={stripeWidth * 0.5}
                    y2={stripeWidth}
                    style={{ stroke: 'var(--r-grey-5)', strokeWidth: `${stripeWidth}` }}
                />
                <line
                    x1={stripeWidth * 1.5}
                    x2={stripeWidth * 1.5}
                    y2={stripeWidth}
                    style={{ stroke: 'var(--r-grey-6)', strokeWidth: `${stripeWidth}` }}
                />
            </pattern>
        </defs>
    </svg>
);
