import { useHasFeature } from 'api';
import { FrontendConfigFeaturesEnum } from 'oas';
import { REDIRECT_REPORT_TYPE } from 'reporting/constants';
import { useReportTypeStore } from 'shared/state/reporting/reportTypeStore';
import { useReportingRedirectStore } from 'shared/state/reporting/reportingRedirectStore';

export const useSetRedirect = () => {
    const { setReportingRedirect } = useReportingRedirectStore();
    const { setReportType } = useReportTypeStore();

    const showReporting = useHasFeature(FrontendConfigFeaturesEnum.ShowReporting);

    const setRedirect = () => {
        if (showReporting) {
            setReportType(REDIRECT_REPORT_TYPE);
            setReportingRedirect(true);
        } else {
            setReportingRedirect(false);
        }
    };

    return setRedirect;
};
