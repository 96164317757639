import { TfNotification, useActionNotifications, useDeleteNotifications, useUpdateNotifications } from 'api';
import { ReactComponent as MoreIcon } from 'assets/icons/16px/more.svg';
import { usePermission } from 'auth';
import { useTranslations } from 'i18n';
import mixpanel from 'mixpanel-browser';
import { NotificationTypeEnum, UserPermissionsEnum } from 'oas';
import * as React from 'react';
import { IconButton, NestedMenu } from 'shared';

interface ActivityMenuProps {
    groupKey: TfNotification['groupKey'];
    notifications: TfNotification[];
}

export const ActivityMenu: React.FC<ActivityMenuProps> = ({ groupKey, notifications }) => {
    const { __ } = useTranslations();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { mutate: deleteNotifications } = useDeleteNotifications();
    const { mutate: updateNotifications } = useUpdateNotifications();
    const { mutate: runActions } = useActionNotifications();
    const [groupNotifications, setGroupNotifications] = React.useState<TfNotification[]>([]);
    const [groupNotificationIds, setGroupNotificationIds] = React.useState<string[]>([]);
    const [hasUnread, setHasUnread] = React.useState(false);
    const [hasActionable, setHasActionable] = React.useState(false);
    const canAction = usePermission(UserPermissionsEnum.SettingsBands);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        const myGroupNotifications = notifications.filter((notification) => notification.groupKey === groupKey);
        setGroupNotifications(myGroupNotifications);
        setGroupNotificationIds(myGroupNotifications.map((notification) => notification.id));
        setHasUnread(myGroupNotifications.filter((notification) => !notification.read).length > 0);
        setHasActionable(
            myGroupNotifications.filter((notification) => notification.type !== NotificationTypeEnum.Info).length > 0,
        );
    }, [groupKey, notifications]);

    const dismissAll = () => {
        deleteNotifications(groupNotificationIds);
        mixpanel.track('BellNotificationActivityDismissAllClicked');
    };

    const updateAll = () => {
        runActions(groupNotificationIds);
        const props: Record<string, number> = {};
        for (let i = 0; i < groupNotifications.length; i++) {
            const notification = groupNotifications[i];
            const prop = `athlete_count_${notification.type}`;
            props[prop] = props[prop] ?? 0;
            props[prop]++;
        }
        mixpanel.track('BellNotificationActivityActionAllClicked', props);
    };

    const markAllAsRead = () => {
        updateNotifications({ ids: groupNotificationIds, read: true });
        mixpanel.track('BellNotificationActivityMarkAllAsReadClicked');
    };

    const menuConfig = [
        { label: __('misc.update_all'), onClick: updateAll, show: canAction && hasActionable },
        { label: __('misc.dismiss_all'), onClick: dismissAll, show: canAction },
        { label: __('notifications.mark_all_as_read'), onClick: markAllAsRead, show: hasUnread },
    ];

    const show = menuConfig.filter((item) => item.show).length > 0;

    return show ? (
        <>
            <IconButton onClick={handleOpen} id={`activity-menu-button-${groupKey}`}>
                <MoreIcon />
            </IconButton>
            <NestedMenu
                menuConfig={menuConfig}
                onClose={handleClose}
                anchorEl={anchorEl}
                id={`activity-menu-${groupKey}`}
            />
        </>
    ) : (
        <></>
    );
};
