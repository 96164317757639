import * as React from 'react';
import { useAuth } from 'auth';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { MiniLayout } from 'layout';
import { Helmet } from 'react-helmet-async';
import { appTitle } from 'shared';
import { Error404 } from 'pages';
import { Spinner } from '@SBGSports/referee-react';

const Auth: React.FC = () => {
    const auth = useAuth();
    const calledOnce = React.useRef(false);
    const [invalidToken, setInvalidToken] = React.useState(false);
    const { page, token: pathToken } = useParams<{ page?: string; token?: string }>();
    let redirTo = '/';
    let validPath = true;

    switch (page) {
        case 'registration':
            redirTo = '/registration';
            break;
        case 'resethandler':
            redirTo = '/login/reset-password';
            break;
        case 'sso':
            break;
        default:
            validPath = false;
    }

    const location = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(location.search);
    const token = pathToken || params.get('t') || params.get('m') || '';

    React.useEffect(() => {
        if (calledOnce.current || !auth.csrfAuthd) {
            return;
        }
        calledOnce.current = true;

        (async () => {
            try {
                if (!auth.user) {
                    await auth.loginWithToken(token);
                } else {
                    auth.checkAuthentication();
                }
            } catch (e) {
                setInvalidToken(true);
            }
        })();
    }, [auth, history, token]);

    return validPath ? (
        <>
            <Helmet>
                <title>{appTitle('Authenticating')}</title>
            </Helmet>
            <MiniLayout narrow center>
                {invalidToken ? <p>Expired link</p> : <Spinner />}
            </MiniLayout>
            {!auth.indeterminate && auth.user && <Redirect to={redirTo} />}
        </>
    ) : (
        <Error404 />
    );
};

export default Auth;
