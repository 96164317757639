import * as React from 'react';

export function useRunOnce(callback: () => void, condition: boolean) {
    const hasRun = React.useRef(false);

    /**
     * Once the condition is true run once only
     */
    React.useEffect(() => {
        if (condition && !hasRun.current) {
            hasRun.current = true;
            callback();
        }
    }, [condition, hasRun, callback]);
}
