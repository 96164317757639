import { DialogProps, ThemeProvider } from '@material-ui/core';
import axios from 'axios';
import * as React from 'react';
import { Dialog, DialogContent, DialogTitle } from 'shared';
import { darkTheme } from 'theme';
import { ForgotPasswordForm, ForgotPasswordFormProps } from './forgot-password-form';

interface ForgotPasswordDialogProps extends DialogProps {
    onClose: () => void;
}

export const ForgotPasswordDialog: React.FC<ForgotPasswordDialogProps> = (props) => {
    const { onClose } = props;
    const handleSubmit: ForgotPasswordFormProps['onSubmit'] = async ({ email, username }) => {
        await axios.post(`${process.env.REACT_APP_AUTH_HOST}/auth/sendResetToken`, {
            username,
            email,
        });
        onClose();
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <Dialog {...props}>
                <DialogTitle onClose={onClose}>Forgot Password</DialogTitle>
                <DialogContent>
                    <ForgotPasswordForm onSubmit={handleSubmit} />
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    );
};
