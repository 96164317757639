import * as React from 'react';
import { useAuth, useAuthUserGuard, usePermission, UserPermissionsEnum } from 'auth';
import { Redirect } from 'react-router-dom';
import { useReportingRedirectStore } from 'shared/state/reporting/reportingRedirectStore';
import { useHasFeature } from 'api';
import { FrontendConfigFeaturesEnum } from 'oas';

const Home: React.FC = () => {
    // Hooks
    const redirectUri = useAuthUserGuard();
    const { indeterminate } = useAuth();

    const showReporting = useHasFeature(FrontendConfigFeaturesEnum.ShowReporting);
    const showTimeline = useHasFeature(FrontendConfigFeaturesEnum.ShowTimeline);
    const showVectorAppWelcome = usePermission(UserPermissionsEnum.VectorAppWelcome);

    const { isReportingRedirect } = useReportingRedirectStore();

    // Don't render anything if the user is not logged in or the auth state is indeterminate
    if (indeterminate || redirectUri === undefined) {
        return <></>;
    }

    // Redirect to the appropriate page if the user is not authorized
    if (redirectUri) {
        return <Redirect to={redirectUri} />;
    }

    if (!showTimeline) {
        if (showVectorAppWelcome) {
            return <Redirect to="/welcome/vector-app" />;
        }

        if (showReporting) {
            return <Redirect to="/reporting" />;
        }
    }

    // When a customer has both reporting and timeline enabled, redirect to reporting on initial load
    if (showReporting || isReportingRedirect || (!showTimeline && showReporting)) {
        return <Redirect to="/reporting" />;
    }

    return <Redirect to="/timeline" />;
};

export default Home;
