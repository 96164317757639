type LanguageIcons = Record<string, string>;

export const getLanguageIcons = (): LanguageIcons => {
    const languageIconDir = require.context('../assets/lang', false, /\.svg$/);
    const languageIconList = languageIconDir.keys().filter((path: string) => path.indexOf('./') === 0);
    const languageIcons: LanguageIcons = {};
    for (let i = 0; i < languageIconList.length; i++) {
        const filename = languageIconList[i];
        const lang = filename.replace(/^\.\//, '').replace(/\.svg$/, '');
        languageIcons[lang] = languageIconDir(filename).default;
    }

    return languageIcons;
};
