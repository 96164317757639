import { Pill, SideMenu, Spinner, Typography } from '@SBGSports/referee-react';
import { TfActivity, useGetActivities } from 'api';
import { subDays } from 'date-fns';
import { useTranslations } from 'i18n';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { ActivitiesSortEnum, Activity } from 'oas';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ACTIONS, EVENTS } from '../../analytics/analytics-events';
import { DATE_FNS_CASUAL_DATE_FORMAT, DATE_FNS_SHORT_DATE_TIME_FORMAT } from '../constants';
import { getEndOfDay, getSecondsDurationBetweenDates, getStartOfDay, useDateFormatter } from '../utils';
import { useActivityListHeightObserver, useLoadMoreObserver } from './activity-side-menu-custom-hook';
import { ActivitySideMenuFilters } from './activity-side-menu-filters';
import styles from './activity-side-menu.module.css';
import { useDebouncedCallback } from 'reporting/hooks/useDebouncedCallback';

import { useSideMenuFilterState } from './use-side-menu-filter-state';
import { REPORT_TYPE } from 'reporting/constants';

interface MultiActivitySideMenuProps {
    selectedActivities: TfActivity[];
    setSelectedActivities: (activities: TfActivity[]) => void;
    activitiesList: TfActivity[];
    setActivitiesList: (activities: TfActivity[]) => void;
    pageSize: number;
    customContent?: React.ReactElement;
    defaultExpanded?: boolean;
    setIsSideMenuOpen: (isSideMenuOpen: boolean) => void;
    customFooter?: JSX.Element;
    reportType?: typeof REPORT_TYPE[keyof typeof REPORT_TYPE];
}

const DEFAULT_MAX_SELECTION = 100;
const DEFAULT_MATCH_DAY_SELECTION = 7;

const getDateForQuery = (date: Date | undefined, shouldNullDate: boolean) => {
    return shouldNullDate || !date ? undefined : date.getTime() / 1000;
};

export const MultiActivitySideMenu: React.FC<React.PropsWithChildren<MultiActivitySideMenuProps>> = (props) => {
    const {
        customContent,
        pageSize,
        defaultExpanded = true,
        selectedActivities,
        setSelectedActivities,
        setIsSideMenuOpen,
        customFooter,
        activitiesList,
        setActivitiesList,
        reportType,
    } = props;

    const { textFilter, setTextFilter, dateFilters, setDateFilters, tagFilters, setTagFilters } =
        useSideMenuFilterState();

    // Local state
    const [dateRangePlaceholder, setDateRangePlaceholder] = useState('');
    const [shouldDefaultToSevenDays, setShouldDefaultToSevenDays] = useState(true);
    const [selectedItems, setSelectedItems] = useState<TfActivity[]>(selectedActivities ?? []);

    //Ref
    const shouldAutomaticallyPreselect = useRef(true);

    const infiniteQuery = useGetActivities(
        pageSize,
        ActivitiesSortEnum.StartTimeDesc,
        0,
        ['activity_athletes'],
        getDateForQuery(getStartOfDay(dateFilters.from), false),
        getDateForQuery(getEndOfDay(dateFilters.to), false),
        textFilter,
        {
            getNextPageParam: (lastPage: Activity[], pages: Activity[][]) => {
                return lastPage.length >= pageSize ? pages.length + 1 : undefined;
            },
        },
        tagFilters,
    );
    const { observerElem } = useLoadMoreObserver(infiniteQuery);
    const formatDate = useDateFormatter();
    const { __ } = useTranslations();

    // Variables
    const flattenedResults = infiniteQuery.data?.pages.flatMap((page) => page);

    useEffect(() => {
        if (activitiesList.length !== flattenedResults?.length) {
            setActivitiesList(flattenedResults ?? []);
        }
    }, [activitiesList.length, flattenedResults, setActivitiesList]);

    const updateSelectedActivitiesDebounced = useDebouncedCallback(
        (sortedSelectedActivities: TfActivity[]) => {
            setSelectedActivities?.(sortedSelectedActivities);
        },
        [setSelectedActivities],
        1500,
    );

    const updateSelectedActivities = useCallback(
        (selectedActivitiesUnsorted: TfActivity[]) => {
            const activitiesSorted = selectedActivitiesUnsorted?.sort((first, second) =>
                getSecondsDurationBetweenDates(first.start_time, second.start_time),
            );
            setSelectedItems(activitiesSorted);

            // Call the debounced callback function with the updated selection
            updateSelectedActivitiesDebounced?.(activitiesSorted);
        },
        [updateSelectedActivitiesDebounced],
    );

    // Handle default multiple selection
    useEffect(() => {
        const hasResults: boolean = (infiniteQuery.data?.pages?.[0]?.length ?? 0) > 0;
        if (hasResults && shouldAutomaticallyPreselect.current) {
            if (reportType === REPORT_TYPE.longitudinal) {
                const mostRecentActivityDate = infiniteQuery.data?.pages[0][0]?.start_time ?? new Date();
                const lastPage = infiniteQuery.data?.pages?.[infiniteQuery.data?.pages?.length - 1];
                const daysBeforeMostRecentActivity = subDays(mostRecentActivityDate, 6);
                const earliestActivityDate =
                    lastPage?.[lastPage?.length - 1]?.start_time ?? daysBeforeMostRecentActivity;

                const startDate = shouldDefaultToSevenDays ? daysBeforeMostRecentActivity : earliestActivityDate;
                const activitiesToSet = shouldDefaultToSevenDays
                    ? flattenedResults
                          ?.filter((activity: TfActivity) => activity.start_time > daysBeforeMostRecentActivity)
                          ?.slice(0, DEFAULT_MAX_SELECTION) ?? []
                    : flattenedResults?.slice(0, DEFAULT_MAX_SELECTION) ?? [];

                if (!isEqual(selectedActivities, activitiesToSet)) {
                    updateSelectedActivities(activitiesToSet);
                    setDateRangePlaceholder(
                        `${formatDate(startDate, DATE_FNS_CASUAL_DATE_FORMAT)} - ${formatDate(
                            mostRecentActivityDate,
                            DATE_FNS_CASUAL_DATE_FORMAT,
                        )}`,
                    );
                    shouldAutomaticallyPreselect.current = false;
                }
            } else {
                updateSelectedActivities(cloneDeep(flattenedResults)?.slice(0, DEFAULT_MATCH_DAY_SELECTION) ?? []);
                shouldAutomaticallyPreselect.current = false;
            }
        }
    }, [
        flattenedResults,
        formatDate,
        infiniteQuery.data?.pages,
        reportType,
        selectedActivities,
        shouldDefaultToSevenDays,
        updateSelectedActivities,
    ]);

    // Make it automatically reselect once filters change
    useEffect(() => {
        shouldAutomaticallyPreselect.current = true;
        const hasNoFilterValues: boolean =
            isEmpty(textFilter) && !dateFilters?.from && !dateFilters?.to && isEmpty(tagFilters);
        setShouldDefaultToSevenDays(hasNoFilterValues);
    }, [textFilter, dateFilters, tagFilters]);

    const { activityListRef, sideMenuFooterRef } = useActivityListHeightObserver();

    const handleMultiActivitySelection = (activity: TfActivity) => {
        const selectionType = 'multiple';
        const shouldDeselect = isIncludedInCurrentlySelected(activity);

        mixpanel.track(EVENTS.sideMenu.activitySelection, {
            activityId: activity.id,
            selectionType,
            action: shouldDeselect ? ACTIONS.deselected : ACTIONS.selected,
        });

        if (shouldDeselect) {
            selectedItems.length !== 1 && // Do not allow deselecting the last activity
                updateSelectedActivities(
                    selectedItems?.filter((tfActivity: TfActivity) => tfActivity.id !== activity.id) ?? [],
                );
        } else {
            updateSelectedActivities([...selectedItems, activity]);
        }
    };

    const isIncludedInCurrentlySelected = (activity: TfActivity): boolean => {
        return !!selectedItems?.find((tfActivity) => tfActivity.id === activity?.id);
    };

    return (
        <div className={styles['side-bar-container']}>
            <SideMenu
                testId={'side-bar-menu'}
                defaultExpanded={defaultExpanded}
                affixBottom={<div ref={sideMenuFooterRef}>{customFooter}</div>}
                onExpandCollapse={(sideMenuOpen) => {
                    setIsSideMenuOpen(sideMenuOpen);
                }}
            >
                <div className={styles['filter-column']}>
                    {customContent}
                    <ActivitySideMenuFilters
                        textFilter={textFilter}
                        setTextFilter={setTextFilter}
                        selectedDateRange={dateFilters}
                        setDateRange={setDateFilters}
                        tagFilters={tagFilters}
                        setTagFilters={setTagFilters}
                        dataLoading={infiniteQuery.isLoading || infiniteQuery.isFetchingNextPage}
                        dateRangePlaceholder={dateRangePlaceholder}
                    />
                </div>
                <div data-testid={'activity-menu-title'} className={styles['activity-menu-title']}>
                    <Typography variant="label-1">
                        {`${__('misc.activities')} (${flattenedResults?.length ?? 0})`}
                    </Typography>
                </div>
                <div id={styles['activity-list']} ref={activityListRef}>
                    {infiniteQuery.isLoading ? (
                        <span className={styles['centered-span']}>
                            <Spinner testId={'activity-search-spinner'} />
                        </span>
                    ) : (
                        flattenedResults?.map((activity: TfActivity) => {
                            const selectedClass = isIncludedInCurrentlySelected(activity) ? 'selected' : '';
                            const tag = activity.tags?.find((t) => t.tag_type_name === 'DayCode');
                            return (
                                <div
                                    key={activity.id}
                                    onClick={() => handleMultiActivitySelection(activity)}
                                    style={{ cursor: 'pointer' }}
                                    className={[styles['menu-activity'], styles[selectedClass]].join(' ')}
                                >
                                    <div className={styles['menu-activity-text']}>
                                        <Typography variant="body-1" numberOfLines={1} title={activity.name}>
                                            {activity.name}
                                        </Typography>
                                        <Typography
                                            numberOfLines={1}
                                            title={activity.name}
                                            className={styles['activity-menu-date']}
                                        >
                                            {formatDate(activity.start_time, DATE_FNS_SHORT_DATE_TIME_FORMAT)}
                                        </Typography>
                                    </div>
                                    <div className={styles['menu-activity-tag']}>
                                        {tag?.tag_name && (
                                            <Pill variant="neutral" value={tag?.tag_name} shape="square" />
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    )}
                    {(infiniteQuery.isFetchingNextPage || infiniteQuery.hasNextPage) && (
                        <span className={styles['centered-span']}>
                            <Spinner ref={observerElem} />
                        </span>
                    )}
                </div>
            </SideMenu>
            {props.children}
        </div>
    );
};
