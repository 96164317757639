import * as React from 'react';
import { Nav, NavProps } from 'rsuite';

/**
 * This is a TabView which can be used by passing an array of tabs, each tab
 * item would have a an eventKey in the lowercase without spaces, for instance
 * a tab named 'Activity Tab' will have an event key as 'activitytab'
 *
 * @param active
 * @param onSelect
 * @param tabs
 * @param customStyle
 * @param props
 * @returns {*}
 * @constructor
 */
interface TabViewProps extends NavProps {
    active: string;
    tabs: string[];
}

export const TabView: React.FC<TabViewProps> = ({ active, tabs, ...props }) => (
    <Nav {...props} appearance="tabs" activeKey={active}>
        {tabs.map((item) => (
            <Nav.Item key={item.replace(/\s/g, '').toLowerCase()} eventKey={item.replace(/\s/g, '').toLowerCase()}>
                {item}
            </Nav.Item>
        ))}
    </Nav>
);
