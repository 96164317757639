import { useApiConfig, useAuth } from 'auth';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Configuration } from 'oas';
import { QueryKey, UseQueryResult, useQuery } from '@tanstack/react-query';

interface ExtendedPromise<T> extends Promise<T> {
    cancel: () => void;
}

export function useDependentApiQuery<T>(
    key: QueryKey,
    fetcher: (apiConfig: Configuration, axiosInstance: AxiosInstance) => Promise<AxiosResponse<T>>,
    condition: boolean,
    options = {},
    apiVersion = 'v6',
): UseQueryResult<T> {
    const { apiConfig, axiosInstance } = useApiConfig(apiVersion);
    const auth = useAuth();
    return useQuery({
        queryKey: key,

        queryFn: () => {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            apiConfig.baseOptions = { cancelToken: source.token };
            const promise = fetcher(apiConfig, axiosInstance).then((response) => response?.data) as ExtendedPromise<T>;
            promise.cancel = () => {
                source.cancel('Query was cancelled by React Query');
            };
            return promise;
        },
        ...{
            enabled: auth.isAuthenticated && condition,
            staleTime: 600000,
            ...options,
        },
    });
}
