import { mockResponse, useApiQuery } from '../use-api-query';
import { UseQueryResult } from '@tanstack/react-query';
import { AverageSet, AverageSetSummary, AverageSetsApi, StatRow } from 'oas';
import { isEmpty } from 'lodash';
import { TfFilterTagOptions, transformAverageSummary } from './transform';
import { SelectOption } from '@SBGSports/referee-react';

export const useGetAverageSets = (
    ...parameters: Parameters<AverageSetsApi['getAverageSets']>
): UseQueryResult<AverageSet[]> => {
    return useApiQuery(['average_sets', parameters], (apiConfig, axiosInstance) =>
        new AverageSetsApi(apiConfig, '', axiosInstance).getAverageSets(...parameters),
    );
};

export const useGetGroupedStats: (
    ...parameters: Parameters<AverageSetsApi['getGroupedStats']>
) => UseQueryResult<StatRow[]> = (id, params, options = {}) =>
    useApiQuery(
        ['average_sets', id],
        (apiConfig, axiosInstance) => {
            return id !== ''
                ? new AverageSetsApi(apiConfig, '', axiosInstance).getGroupedStats(id, params, options)
                : mockResponse([]);
        },
        options,
    );

export const useGetAverageSummary: (
    ...parameters: Parameters<AverageSetsApi['getSummary']>
) => UseQueryResult<AverageSetSummary[]> = (type, params, options = {}) =>
    useApiQuery(['average_set_summary', type, params], (apiConfig, axiosInstance) => {
        return !isEmpty(params)
            ? new AverageSetsApi(apiConfig, '', axiosInstance).getSummary(type, params, options)
            : mockResponse([]);
    });

export const useGetSideMenuFilterTags = (
    type: string,
    params: string[],
    filteredTags: SelectOption[],
    options = {},
): UseQueryResult<TfFilterTagOptions[]> =>
    useApiQuery(['average_set_summary', type, params], (apiConfig, axiosInstance) => {
        return !isEmpty(params)
            ? new AverageSetsApi(apiConfig, '', axiosInstance)
                  .getSummary(type, params, options)
                  .then((response) => ({ ...response, data: transformAverageSummary(response.data, filteredTags) }))
            : mockResponse([]);
    });
