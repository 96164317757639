import { LinearProgress } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { Checkbox, Table, TableProps } from 'rsuite';
import styles from './admin-table.module.css';

export type SortFunc = (sortColumn: string, sortType: TableProps['SortType']) => void;
interface AdminTableProps extends TableProps {
    isPageLoading?: boolean;
    checkboxes?: boolean;
    checkedKeys?: string[];
    onChecked?: (checkedKeys: string[]) => void;
    isCheckboxEnabled?: (rowData: Record<string, unknown>) => boolean;
    headerLeft?: React.ReactNode;
    headerRight?: React.ReactNode;
    virtualized?: boolean;
    sortDir?: TableProps['sortType'];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tableRef?: React.LegacyRef<any>;
    width?: number | undefined;
    locale?: TableProps['locale'];
}

export const AdminTable: React.FC<React.PropsWithChildren<AdminTableProps>> = (props) => {
    const {
        data,
        height,
        autoHeight,
        affixHeader,
        loading,
        isPageLoading,
        sortColumn,
        sortDir: sortType,
        onSortColumn,
        checkboxes,
        checkedKeys,
        onChecked,
        isCheckboxEnabled = () => true,
        headerLeft,
        headerRight,
        className,
        virtualized,
        tableRef,
        onScroll,
        shouldUpdateScroll,
        width,
        locale,
    } = props;

    const handleCheckAll = (_value: string, checked: boolean) => {
        if (!checkboxes || !onChecked || !data) {
            return;
        }

        const nextCheckedKeys = checked ? data?.filter((item) => isCheckboxEnabled(item)).map((item) => item.id) : [];

        onChecked(nextCheckedKeys);
    };

    const handleCheck = (value: string, checked: boolean) => {
        if (!checkboxes || !onChecked || !checkedKeys) {
            return;
        }

        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter((item: string) => item !== value);
        onChecked(nextCheckedKeys);
    };

    const isIndeterminate = () => checkedKeys && checkedKeys.length > 0 && checkedKeys.length !== data?.length;
    interface CheckCellProps {
        /** Data binding key, but also a sort of key */
        dataKey?: string;

        /** Row Number */
        rowIndex?: number;

        /** Row Data */
        rowData?: Record<string, unknown>;

        onChange: (value: string, isChecked: boolean) => void;

        keysChecked: string[];
    }
    const CheckCell: React.FC<CheckCellProps> = ({ rowData, onChange, keysChecked, dataKey, ...cellProps }) => {
        if (!dataKey || !rowData) {
            return <Cell />;
        }

        return (
            <Cell {...cellProps} className={styles['check-cell']}>
                <div style={{ lineHeight: '46px' }}>
                    <Checkbox
                        value={rowData[dataKey]}
                        inline
                        onChange={(value, isChecked) => onChange(value, isChecked)}
                        checked={keysChecked.some((item: string) => item === rowData?.[dataKey])}
                        disabled={isCheckboxEnabled && !isCheckboxEnabled(rowData)}
                    />
                </div>
            </Cell>
        );
    };

    const isChecked = () => {
        if (!data || !checkedKeys) {
            return false;
        }

        if (checkedKeys.length === 0) {
            return false;
        }
        if (checkedKeys.length === data.length && data.length !== 0) {
            return true;
        }
        if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
            return true;
        }
        return false;
    };

    const { Column, HeaderCell, Cell } = Table;

    const checkColumn = checkboxes ? (
        <Column width={30} align="center">
            <HeaderCell className={styles['check-cell']}>
                <Checkbox
                    inline
                    checked={isChecked()}
                    indeterminate={isIndeterminate()}
                    onChange={(value, ck) => handleCheckAll(value, ck)}
                />
            </HeaderCell>
            <CheckCell dataKey="id" keysChecked={checkedKeys || []} onChange={handleCheck} />
        </Column>
    ) : null;

    return (
        <div className={clsx('admin-table', styles['admin-table'], className)}>
            {headerLeft || headerRight ? (
                <div className={styles['header']}>
                    {headerLeft ? <div className={styles['headerLeft']}>{headerLeft}</div> : null}
                    {headerRight ? <div className={styles['headerRight']}>{headerRight}</div> : null}
                </div>
            ) : null}
            <Table
                virtualized={virtualized}
                shouldUpdateScroll={shouldUpdateScroll}
                height={height}
                autoHeight={autoHeight}
                affixHeader={affixHeader}
                data={data}
                loading={loading}
                onSortColumn={onSortColumn}
                sortColumn={sortColumn}
                sortType={sortType}
                ref={tableRef}
                onScroll={onScroll}
                width={width}
                locale={locale}
            >
                {checkColumn}
                {props.children}
            </Table>
            <div
                className={clsx({
                    [styles['page-load-indicator']]: true,
                    [styles['page-load-indicator--loading']]: isPageLoading,
                })}
            >
                <LinearProgress />
            </div>
            {props.actions ? <div className={styles['actions']}>{props.actions}</div> : null}
        </div>
    );
};
