import { AxiosResponse, AxiosInstance } from 'axios';
import { useInfiniteQuery, UseInfiniteQueryResult, QueryKey, InfiniteData } from '@tanstack/react-query';
import { Configuration } from 'oas';
import { useApiConfig, useAuth } from 'auth';

export function useInfiniteApiQuery<T>(
    key: QueryKey,
    fetcher: (pageParam: number, apiConfig: Configuration, axios: AxiosInstance) => Promise<AxiosResponse<T>>,
    options = {},
    apiVersion = 'v6',
): UseInfiniteQueryResult<InfiniteData<T>> {
    const { apiConfig, axiosInstance } = useApiConfig(apiVersion);
    const auth = useAuth();

    return useInfiniteQuery({
        queryKey: key,
        queryFn: async ({ pageParam }) => (await fetcher(pageParam, apiConfig, axiosInstance))?.data,
        initialPageParam: 1,
        getNextPageParam: () => undefined,
        ...{
            enabled: auth.user !== undefined,
            staleTime: 600000,
            ...options,
        },
    });
}
