import { useUpdateNotifications } from 'api';
import { parseJSON } from 'date-fns';
import { useTranslations } from 'i18n';
import { ActionButton } from 'notifications/buttons';
import { SnackbarKey } from 'notistack';
import * as React from 'react';
import { useSnackbar } from 'shared';
import { NotificationView } from '../notification-view';
import { NotificationTypeProps } from './notification-type-props';

const snackbarKey: Record<string, SnackbarKey> = {};

export const NewDownload: React.FC<NotificationTypeProps> = (props) => {
    const { notification, handleToggleRead } = props;

    const { __ } = useTranslations();
    const createdAt = parseJSON(notification.created_at);
    const download_installer_name = notification.payload?.download_installer_name;
    const download_installer_url = `${process.env.REACT_APP_AUTH_HOST}${notification.payload?.download_installer_url}`;
    const download_installer_release_notes_url = notification.payload?.download_installer_release_notes_url;

    const { mutate: updateNotifications } = useUpdateNotifications();

    const handleDownloadAction = React.useCallback(() => {
        updateNotifications({ ids: [notification.id], read: true, notified: true });
        window.open(download_installer_url);
        return false;
    }, [updateNotifications, notification.id, download_installer_url]);

    const handleInfoAction = React.useCallback(() => {
        updateNotifications({ ids: [notification.id], read: true });
        window.open(download_installer_release_notes_url);
        return false;
    }, [updateNotifications, notification.id, download_installer_release_notes_url]);

    const handleAlertCloseAction = React.useCallback(() => {
        updateNotifications({ ids: [notification.id], notified: true });
        return false;
    }, [updateNotifications, notification.id]);

    const { notificationSnackbar, closeSnackbar } = useSnackbar();

    React.useEffect(() => {
        if (!notification.notified) {
            if (!snackbarKey[notification.id]) {
                closeSnackbar();
                snackbarKey[notification.id] = notificationSnackbar({
                    title: __('notifications.new_version_available', {
                        version: `${download_installer_name}`,
                    }),
                    secondaryButtonText: download_installer_release_notes_url ? __('misc.more_info') : '',
                    primaryButtonText: __('misc.download'),
                    primaryButtonAction: handleDownloadAction,
                    secondaryButtonAction: handleInfoAction,
                    closeButtonAction: handleAlertCloseAction,
                    installerReleaseNotesUrl: download_installer_release_notes_url,
                });
            }
        } else if (snackbarKey[notification.id]) {
            closeSnackbar(snackbarKey[notification.id]);
            delete snackbarKey[notification.id];
        }
    }, [
        __,
        closeSnackbar,
        download_installer_name,
        download_installer_release_notes_url,
        handleAlertCloseAction,
        handleDownloadAction,
        handleInfoAction,
        notification.id,
        notification.notified,
        notificationSnackbar,
    ]);

    return (
        <>
            {notification.notified ? (
                <NotificationView
                    title={__('notifications.new_version_available', {
                        version: `${download_installer_name}`,
                    })}
                    createdAt={createdAt}
                    isRead={notification.read === 1}
                    onToggleRead={handleToggleRead}
                    actions={[
                        ...(download_installer_release_notes_url
                            ? [
                                  <ActionButton
                                      key="action"
                                      notification={notification}
                                      onClick={handleInfoAction}
                                      variant="text"
                                  >
                                      {__('misc.more_info')}
                                  </ActionButton>,
                              ]
                            : []),
                        [
                            <ActionButton key="action" notification={notification} onClick={handleDownloadAction}>
                                {__('misc.download')}
                            </ActionButton>,
                        ],
                    ]}
                    contents={undefined}
                />
            ) : null}
        </>
    );
};
