import { DemoDataApi, DemoDataStatusResponse } from '../../oas';
import { UseQueryResult } from '@tanstack/react-query';
import { useApiQuery } from '../use-api-query';

export const useGetDemoDataStatus: (
    ...parameters: Parameters<DemoDataApi['demoDataStatus']>
) => UseQueryResult<DemoDataStatusResponse> = () => {
    return useApiQuery(['demoDataStatus'], (apiConfig, axiosInstance) =>
        new DemoDataApi(apiConfig, '', axiosInstance).demoDataStatus(),
    );
};
