import { SettingsApi, Setting } from 'oas';
import { useApiQuery } from '../use-api-query';
import { UseQueryResult } from '@tanstack/react-query';

export const useGetSettings = (
    ...parameters: Parameters<typeof SettingsApi.prototype.getAllSettings>
): UseQueryResult<Setting[]> => {
    return useApiQuery(['settings', parameters], (apiConfig, axiosInstance) => {
        return new SettingsApi(apiConfig, '', axiosInstance).getAllSettings(...parameters);
    });
};

/**
 * Return value is:
 * - if not yet loaded: undefined
 * - loaded but setting does not exist: null
 * - loaded and setting existsSetting object
 */
export const useGetSetting = (key: string): Setting | undefined | null => {
    const { data: settings } = useGetSettings();

    if (!settings) {
        return undefined;
    }

    return settings.find((setting) => setting?.key === key) || null;
};
