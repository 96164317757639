import { Route } from 'react-router-dom';
import { useHasFeature } from 'api';
import Error404 from './pages/error-404';
import { Box } from '@material-ui/core';
import { Spinner } from '@SBGSports/referee-react';
import { RouteProps } from 'react-router-dom';
import { FrontendConfigFeaturesEnum } from 'oas';

type FeatureEnabledRouteProps = RouteProps & {
    feature: FrontendConfigFeaturesEnum;
};

const FeatureEnabledRoute: React.FC<FeatureEnabledRouteProps> = ({ feature, ...rest }) => {
    const hasFeature = useHasFeature(feature);

    if (hasFeature === null) {
        return (
            <Box m={30} justifyContent="center" display="flex">
                <Spinner testId="spinner" />
            </Box>
        );
    }

    if (!hasFeature) {
        return <Error404 />;
    }

    return <Route {...rest} />;
};

export default FeatureEnabledRoute;
