import { UseQueryResult } from '@tanstack/react-query';
import { useApiQuery } from 'api/use-api-query';
import { AnnotationsApi, AnnotationCategory, ImportersApi, Annotation, AnnotationLayer } from 'oas/internal';

export const useGetAnnotationLayers = (activityId: string, options = {}): UseQueryResult<AnnotationLayer[]> => {
    return useApiQuery(
        ['annotation', 'layer', activityId],
        (apiConfig, axiosInstance) =>
            new ImportersApi(apiConfig, '', axiosInstance).getAnnotationLayersForActivity(activityId),
        options,
        'internal',
    );
};

export const useGetAnnotationCategories = (options = {}): UseQueryResult<AnnotationCategory[]> => {
    return useApiQuery(
        ['annotation', 'categories'],
        (apiConfig, axiosInstance) => new ImportersApi(apiConfig, '', axiosInstance).getAnnotationCategories(),
        options,
        'internal',
    );
};

export const useGetAnnotations = (
    activityId: Parameters<AnnotationsApi['getAnnotationsForActivity']>[0],
    options = {},
): UseQueryResult<Annotation[]> => {
    return useApiQuery(
        ['annotations', activityId],
        (apiConfig, axiosInstance) =>
            new AnnotationsApi(apiConfig, '', axiosInstance).getAnnotationsForActivity(activityId),
        options,
        'internal',
    );
};
