import { Box, Collapse, Divider, Grow, Typography } from '@material-ui/core';
import { TfNotification } from 'api';
import * as React from 'react';
import { ActivityMenu } from './activity-menu';
import { NotificationListItem } from './notification-list-item';

interface NotificationsListProps {
    notifications?: TfNotification[];
}

export const NotificationsList: React.FC<NotificationsListProps> = ({ notifications }) => {
    let lastGroupKey: TfNotification['groupKey'];

    return (
        <>
            {notifications?.map((notification) => {
                let divider;
                const activity = notification.activities?.[0];
                if (activity && notification.groupKey !== undefined && notification.groupKey !== lastGroupKey) {
                    divider = (
                        <Collapse in={!notification.isDeleting}>
                            <Grow in={!notification.isDeleting}>
                                <Box
                                    mx={3}
                                    mb={1}
                                    mt={-1}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Box display="flex" alignItems="center" height={40}>
                                        <Typography variant="h5">{activity.name}</Typography>
                                    </Box>
                                    <Box>
                                        <ActivityMenu groupKey={notification.groupKey} notifications={notifications} />
                                    </Box>
                                </Box>
                            </Grow>
                        </Collapse>
                    );
                } else if (notification.groupKey === undefined && lastGroupKey !== undefined) {
                    divider = (
                        <Box mb={2}>
                            <Divider />
                        </Box>
                    );
                }

                if (!notification.isDeleting) {
                    lastGroupKey = notification.groupKey;
                }

                return (
                    <div key={notification.id} className="notification-list-item-container">
                        {divider}
                        <NotificationListItem notification={notification} />
                    </div>
                );
            })}
        </>
    );
};
