export const createDownload = (filename: string, contents: string, mimetype = 'text/csv', charset = 'utf-8'): void => {
    const header = `data:${mimetype};charset=${charset},\ufeff`;
    const encodedUri = encodeURI(`${header}${contents}`).replace(/#/g, '%23');
    const link = document.createElement('a');

    link.setAttribute('href', encodedUri);
    link.setAttribute('download', filename);
    document.body.appendChild(link); // Required for FF
    link.click();
    link.remove();
};
