import * as React from 'react';
import { useGetNotifications } from 'api/notifications';

export function useUnreadNotificationsCount(): number {
    const [unreadUpdateCount, setUnreadUpdateCount] = React.useState(0);
    const notifications = useGetNotifications();
    React.useEffect(() => {
        if (!notifications.data) {
            return;
        }

        setUnreadUpdateCount(notifications.data.filter((item) => !item.read).length);
    }, [notifications]);

    return unreadUpdateCount;
}
