import { useApiQueries } from 'api/use-api-queries';
import { Sensor, SensorData10HzApi } from 'oas';
import { QueryKey, UseQueryResult } from '@tanstack/react-query';
import { useApiQuery } from '../use-api-query';
import { transformSensorData } from './transform';

export const useGetSensorData = (
    ...parameters: Parameters<SensorData10HzApi['get10HzDualStreamSensorDataForAthleteInActivity']>
): UseQueryResult<Sensor> => {
    return useApiQuery(['sensor-data', parameters], (apiConfig, axiosInstance) => {
        return new SensorData10HzApi(apiConfig, '', axiosInstance)
            .get10HzDualStreamSensorDataForAthleteInActivity(...parameters)
            .then((response) => ({ ...response, data: transformSensorData(response.data) }));
    });
};
export const useGetMultipleSensorData = (
    parameters: Parameters<SensorData10HzApi['get10HzDualStreamSensorDataForDeviceInActivity']>[],
): UseQueryResult<Sensor, unknown>[] => {
    const queryKeys = parameters.map((p) => ['sensor-data', p] as QueryKey);
    return useApiQueries(queryKeys, parameters, async (apiConfig, axiosInstance, queryParams) => {
        const response = await new SensorData10HzApi(
            apiConfig,
            '',
            axiosInstance,
        ).get10HzDualStreamSensorDataForDeviceInActivity(...queryParams);
        return { ...response, data: transformSensorData(response.data) };
    });
};

export const useGetMultiplePeriodSensorData = (
    parameters: Parameters<SensorData10HzApi['get10HzDualStreamSensorDataForAthleteInPeriod']>[],
): UseQueryResult<Sensor, unknown>[] => {
    const queryKeys = parameters.map((p) => ['sensor-data', p] as QueryKey);
    return useApiQueries(queryKeys, parameters, async (apiConfig, axiosInstance, queryParams) => {
        const response = await new SensorData10HzApi(
            apiConfig,
            '',
            axiosInstance,
        ).get10HzDualStreamSensorDataForAthleteInPeriod(...queryParams);
        return { ...response, data: transformSensorData(response.data) };
    });
};
