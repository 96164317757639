import { Avatar, createStyles, makeStyles, Theme, withStyles } from '@material-ui/core';
import { NotificationAthlete } from 'oas';
import clsx from 'clsx';

interface AthleteAvatarProps {
    athlete: NotificationAthlete;
    size?: 'small';
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        small: {
            width: theme.spacing(3.5),
            height: theme.spacing(3.5),
            fontSize: '1rem',
        },
    }),
);

const styles = () =>
    createStyles({
        colorDefault: {
            backgroundColor: 'var(--r-catapult-black)',
        },
    });

export const AthleteAvatar = withStyles(styles)((props: AthleteAvatarProps) => {
    const { athlete, size, ...rest } = props;
    const customClasses = useStyles();
    const athleteName = `${athlete?.first_name} ${athlete?.last_name}`;
    const athleteInitials = `${athlete?.first_name[0]}${athlete?.last_name[0]}`;

    return (
        <Avatar
            alt={athleteName}
            src={athlete.image_url}
            className={clsx(size ? customClasses[size] : undefined)}
            {...rest}
        >
            {athleteInitials}
        </Avatar>
    );
});
