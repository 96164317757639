import { UseMutationResult } from '@tanstack/react-query';
import { Notice, NoticesApi, NoticeUpdateRequest } from 'oas';
import { useApiMutation, useOptimisticOptions } from '../use-api-mutation';

export const useNoticeMutation = (): UseMutationResult<void, unknown, NoticeUpdateRequest & { id: string }> => {
    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            await new NoticesApi(apiConfig, '', axiosInstance).putNotice(attr.id, attr);
        },
        useOptimisticOptions<Notice[] | undefined, NoticeUpdateRequest & { id: string }>(
            ['notices'],
            (newAttr) => (oldItems) => {
                return oldItems
                    ? oldItems.map((oldItem: Notice) => {
                          if (oldItem.id !== newAttr.id) {
                              return oldItem;
                          }

                          return mergeItems(newAttr, oldItem);
                      })
                    : [];
            },
        ),
    );
};

export const useNoticesMutation = (): UseMutationResult<void, unknown, NoticeUpdateRequest> => {
    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            await new NoticesApi(apiConfig, '', axiosInstance).putNotices(attr);
        },
        useOptimisticOptions<Notice[], NoticeUpdateRequest>(['notices'], (newAttr) => (oldItems) => {
            return oldItems ? oldItems.map((oldItem: Notice) => mergeItems(newAttr, oldItem)) : [];
        }),
    );
};

const mergeItems = (newAttr: NoticeUpdateRequest, oldItem: Notice): Notice => {
    const newItem = { ...oldItem };
    if (newAttr.read !== undefined) {
        newItem.read = newAttr.read ? 1 : 0;
    }

    if (newAttr.notified !== undefined) {
        newItem.notified = newAttr.notified ? 1 : 0;
    }

    return newItem;
};
