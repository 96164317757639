import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ForcePasswordUpdate, Mfa, UsernamePassword } from 'login';
import { ResetPassword } from 'login/reset-password';

const LoginPage: React.FC = () => {
    const { path } = useRouteMatch();

    return (
        <>
            <Switch>
                <Route path={path} exact>
                    <UsernamePassword />
                </Route>
                <Route path={`${path}/mfa`}>
                    <Mfa />
                </Route>
                <Route path={`${path}/password-update`} exact>
                    <ForcePasswordUpdate />
                </Route>
                <Route path={`${path}/reset-password`} exact>
                    <ResetPassword />
                </Route>
            </Switch>
        </>
    );
};

export default LoginPage;
