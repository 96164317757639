import { LeagueApi, LeagueMigrationParameters, LeagueRefreshActiveMigrationParameters } from 'oas';
import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useApiMutation } from '../use-api-mutation';

export const useTriggerMigrations = (): UseMutationResult<
    void,
    unknown,
    LeagueMigrationParameters & { childId: number }
> => {
    const queryClient = useQueryClient();

    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            await new LeagueApi(apiConfig, '', axiosInstance).postLeagueTeam(attr.childId, {
                activity_ids: attr.activity_ids,
            });
        },
        {
            onSettled: (_data, _error, attr) => {
                queryClient.invalidateQueries({
                    queryKey: ['league-migrations', attr.childId],
                });
            },
        },
    );
};

export const useRefreshActiveMigrations = (): UseMutationResult<
    void,
    unknown,
    LeagueRefreshActiveMigrationParameters
> => {
    const queryClient = useQueryClient();

    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            await new LeagueApi(apiConfig, '', axiosInstance).postMigrations(attr);
        },
        {
            onSettled: (_data, _error, attr) => {
                queryClient.invalidateQueries({
                    queryKey: ['league-migrations', attr.childId],
                });
            },
        },
    );
};
