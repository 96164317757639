import * as React from 'react';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/16px/chevron-right.svg';
import { PopoverOrigin, Menu, MenuItem, MenuProps, MenuItemProps } from '@material-ui/core';
import styles from './nested-menu-item.module.css';
import { menuItemStyles } from './mui-styles';

// Adapted from https://github.com/azmenak/material-ui-nested-menu-item
export interface NestedMenuItemProps extends Omit<MenuItemProps, 'button'> {
    /**
     * Open state of parent `<Menu />`, used to close decendent menus when the
     * root menu is closed.
     */
    parentMenuOpen: boolean;
    /**
     * Component for the container element.
     * @default 'div'
     */
    component?: React.ElementType;
    /**
     * Effectively becomes the `children` prop passed to the `<MenuItem/>`
     * element.
     */
    label?: React.ReactNode;
    /**
     * @default <ArrowRight />
     */
    anchorOrigin?: PopoverOrigin;
    /**
     * @default { vertical: 'top', horizontal: 'right' }
     */
    transformOrigin?: PopoverOrigin;
    /**
     * @default { vertical: 'top', horizontal: 'left' }
     */
    rightIcon?: React.ReactNode;
    /**
     * @default '#101010'
     */
    iconFill?: string;
    /**
     * Props passed to container element.
     */
    ContainerProps?: React.HTMLAttributes<HTMLElement> & React.RefAttributes<HTMLElement | null>;
    /**
     * Props passed to sub `<Menu/>` element
     */
    MenuProps?: Omit<MenuProps, 'children'>;
    /**
     * @see https://material-ui.com/api/list-item/
     */
    button?: true | undefined;
    /**
     * Used for nightwatch testing
     */
    testId?: string;
}

/**
 * Use as a drop-in replacement for `<MenuItem>` when you need to add cascading
 * menu elements as children to this component.
 */
export const NestedMenuItem = React.forwardRef<HTMLLIElement | null, NestedMenuItemProps>(function NestedMenuItem(
    props,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _ref,
) {
    const {
        parentMenuOpen,
        label,
        anchorOrigin = {
            vertical: 'top',
            horizontal: 'right',
        },
        transformOrigin = {
            vertical: 'top',
            horizontal: 'left',
        },
        iconFill = '#101010',
        rightIcon = <ChevronRightIcon fill={iconFill} />,
        children,
        className,
        tabIndex: tabIndexProp,
        MenuProps: MyMenuProps = {
            getContentAnchorEl: null,
        },
        ContainerProps: ContainerPropsProp = {},
        id,
        testId,
        ...MyMenuItemProps
    } = props;

    const menuItemRef = React.useRef<HTMLLIElement>(null);

    const containerRef = React.useRef<HTMLDivElement>(null);

    const menuContainerRef = React.useRef<HTMLDivElement>(null);

    const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false);

    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        setIsSubMenuOpen(true);

        if (ContainerPropsProp?.onMouseEnter) {
            ContainerPropsProp.onMouseEnter(event);
        }
    };
    const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
        setIsSubMenuOpen(false);

        if (ContainerPropsProp?.onMouseLeave) {
            ContainerPropsProp.onMouseLeave(event);
        }
    };

    // Check if any immediate children are active
    const isSubmenuFocused = () => {
        const active = containerRef.current?.ownerDocument?.activeElement;
        for (let i = 0; i < (menuContainerRef.current?.children?.length ?? 0); i++) {
            const child = menuContainerRef.current?.children[i];
            if (child === active) {
                return true;
            }
        }
        return false;
    };

    const handleFocus = (event: React.FocusEvent<HTMLElement>) => {
        if (parentMenuOpen && event.target === containerRef.current) {
            setIsSubMenuOpen(true);
        }

        if (ContainerPropsProp?.onFocus) {
            ContainerPropsProp.onFocus(event);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Escape') {
            return;
        }

        if (event.key === 'Enter') {
            setIsSubMenuOpen(false);
            event.stopPropagation();
        }

        if (isSubmenuFocused()) {
            event.stopPropagation();
        }

        const active = containerRef.current?.ownerDocument?.activeElement;

        if (event.key === 'ArrowLeft' && isSubmenuFocused()) {
            containerRef.current?.focus();
        }

        if (event.key === 'ArrowRight' && event.target === containerRef.current && event.target === active) {
            const firstChild = menuContainerRef.current?.children[0] as HTMLElement | undefined;
            firstChild?.focus();
        }
    };

    const open = isSubMenuOpen && parentMenuOpen;

    // Root element must have a `tabIndex` attribute for keyboard navigation
    let tabIndex;
    if (!props.disabled) {
        tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
    }

    return (
        <div
            {...ContainerPropsProp}
            ref={containerRef}
            onFocus={handleFocus}
            tabIndex={tabIndex}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onKeyDown={handleKeyDown}
            test-data={testId}
        >
            <MenuItem {...MyMenuItemProps} ref={menuItemRef} style={menuItemStyles}>
                {label}
                <span className={styles['nested-menu-item__right-icon']}>{rightIcon}</span>
            </MenuItem>
            <Menu
                {...MyMenuProps}
                id={id}
                // Set pointer events to 'none' to prevent the invisible Popover div
                // from capturing events for clicks and hovers
                style={{ pointerEvents: 'none' }}
                anchorEl={menuItemRef.current}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                open={open}
                autoFocus={false}
                disableAutoFocus
                disableEnforceFocus
                onClick={() => {
                    setIsSubMenuOpen(false);
                }}
                onClose={() => {
                    setIsSubMenuOpen(false);
                }}
                className={className}
            >
                <div ref={menuContainerRef} style={{ pointerEvents: 'auto' }}>
                    {children}
                </div>
            </Menu>
        </div>
    );
});
