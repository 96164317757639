import {
    QueryKey,
    Updater,
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQueryClient,
} from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import { Configuration } from 'oas';
import { useApiConfig } from 'auth';

export function useApiMutation<TData, TError, TVariables, TContext>(
    updater: (attr: TVariables, apiConfig: Configuration, axios: AxiosInstance) => Promise<TData>,
    options: UseMutationOptions<TData, TError, TVariables, TContext> = {},
    keys?: QueryKey,
    apiVersion = 'v6',
): UseMutationResult<TData, TError, TVariables, TContext> {
    const { apiConfig, axiosInstance } = useApiConfig(apiVersion);

    return useMutation({
        mutationKey: keys || [],
        mutationFn: async (attr: TVariables) => await updater(attr, apiConfig, axiosInstance),
        ...options,
    });
}

interface ContextShape<TCacheData> {
    oldItems: TCacheData | undefined;
}

export function useOptimisticOptions<TCacheData, TVariables>(
    queryKeyToInvalidate: QueryKey,
    getUpdater: (newAttr: TVariables, id?: string) => Updater<TCacheData | undefined, TCacheData>,
    options: UseMutationOptions<void, unknown, TVariables, ContextShape<TCacheData>> = {},
): UseMutationOptions<void, unknown, TVariables, ContextShape<TCacheData>> {
    const queryClient = useQueryClient();

    return {
        onMutate: async (newAttr: TVariables) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: queryKeyToInvalidate });

            // Snapshot the previous value
            const oldItems = queryClient.getQueryData<TCacheData>(queryKeyToInvalidate);

            if (oldItems !== undefined) {
                // Optimistically update to the new value
                queryClient.setQueryData(queryKeyToInvalidate, getUpdater(newAttr));
            }

            // Return a context object with the snapshotted value
            return { oldItems };
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (_err, _newAttr, context: ContextShape<TCacheData> | undefined) => {
            if (context?.oldItems) {
                queryClient.setQueryData(queryKeyToInvalidate, context.oldItems);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: queryKeyToInvalidate });
        },
        ...options,
    };
}
