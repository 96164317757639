import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface ReportTypeState {
    reportType: string;
    setReportType: (reportType: string) => void;
}

export const useReportTypeStore = create<ReportTypeState>()(
    persist(
        immer((set) => ({
            reportType: '',

            setReportType: (reportType: string) =>
                set((state: { reportType: string }) => {
                    state.reportType = reportType;
                }),
        })),
        {
            name: 'report-type',
            storage: createJSONStorage(() => sessionStorage),
        },
    ),
);
