import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { createJSONStorage, persist } from 'zustand/middleware';

export interface FilterOption {
    label: string;
    value: string;
}
export interface FiltersState {
    teams: FilterOption[];
    setTeams: (teams: FilterOption[]) => void;
    athletes: FilterOption[];
    setAthletes: (athletes: FilterOption[]) => void;
    participation: FilterOption[];
    setParticipation: (participation: FilterOption[]) => void;
    periods: FilterOption[];
    setPeriods: (periods: FilterOption[]) => void;
    positions: FilterOption[];
    setPositions: (positions: FilterOption[]) => void;
    clearAll: () => void;
}

export const useFiltersStore = create<FiltersState>()(
    persist(
        immer((set) => ({
            athletes: [],
            participation: [],
            teams: [],
            periods: [],
            positions: [],
            setAthletes: (athletes: FilterOption[]) =>
                set((state: { athletes: FilterOption[] }) => {
                    state.athletes = athletes;
                }),
            setParticipation: (participation: FilterOption[]) =>
                set((state: { participation: FilterOption[] }) => {
                    state.participation = participation;
                }),
            setTeams: (teams: FilterOption[]) =>
                set((state: { teams: FilterOption[] }) => {
                    state.teams = teams;
                }),
            setPeriods: (periods: FilterOption[]) =>
                set((state: { periods: FilterOption[] }) => {
                    state.periods = periods;
                }),
            setPositions: (positions: FilterOption[]) =>
                set((state: { positions: FilterOption[] }) => {
                    state.positions = positions;
                }),
            clearAll: () =>
                set((state) => {
                    state.athletes = [];
                    state.periods = [];
                    state.participation = [];
                    state.positions = [];
                    state.teams = [];
                }),
        })),
        {
            name: 'banner-filters',
            storage: createJSONStorage(() => localStorage),
        },
    ),
);
