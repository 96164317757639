import { useAuth } from './use-auth';
import { UserPermissionsEnum } from 'oas';

export { UserPermissionsEnum } from 'oas';

export const usePermission = (permission: UserPermissionsEnum): boolean => {
    const auth = useAuth();

    return !!auth.user?.permissions?.includes(permission);
};

export const usePermissions = (): UserPermissionsEnum[] => {
    const auth = useAuth();

    return auth.user?.permissions || [];
};
