import { isValid, parse } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DATE_FNS_GLOBAL_FORMAT } from './constants';

export const SEARCH_PARAM = 'activity-name-like';
export const DATE_FROM_PARAM = 'date-from';
export const DATE_TO_PARAM = 'date-to';
export const TAGS_PARAM = 'tags';

export const useQueryParams = () => {
    const location = useLocation();
    const history = useHistory();

    const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const getQueryParam = useCallback(
        (paramName: string) => {
            return searchParams.get(paramName);
        },
        [searchParams],
    );

    const getQueryParamArray = useCallback(
        (paramName: string) => {
            const paramValue = getQueryParam(paramName);
            if (paramValue) {
                return paramValue.split(',');
            }
            return [];
        },
        [getQueryParam],
    );

    const removeQueryParam = useCallback(
        (paramName: string) => {
            if (searchParams.has(paramName)) {
                searchParams.delete(paramName);
                const newSearch = searchParams.toString();
                history.replace(`${location.pathname}${newSearch ? '?' + newSearch : ''}`);
            }
        },
        [history, location.pathname, searchParams],
    );

    const updateQueryParam = useCallback(
        (paramName: string, newValue: string) => {
            searchParams.set(paramName, newValue);
            const newSearch = searchParams.toString();
            history.replace(`${location.pathname}${newSearch ? '?' + newSearch : ''}`);
        },
        [history, location.pathname, searchParams],
    );

    return {
        getQueryParam,
        getQueryParamArray,
        removeQueryParam,
        updateQueryParam,
    };
};

export const useLinkParams = () => {
    const { getQueryParam, getQueryParamArray } = useQueryParams();

    const search: string | null = useMemo(() => {
        return getQueryParam(SEARCH_PARAM);
    }, [getQueryParam]);

    const dateFrom: Date | null = useMemo(() => {
        const from = getQueryParam(DATE_FROM_PARAM);
        const parsedFromDate = parse(from ?? '', DATE_FNS_GLOBAL_FORMAT, new Date());
        return isValid(parsedFromDate) ? parsedFromDate : null;
    }, [getQueryParam]);

    const dateTo: Date | null = useMemo(() => {
        const to = getQueryParam(DATE_TO_PARAM);
        const parsedToDate = parse(to ?? '', DATE_FNS_GLOBAL_FORMAT, new Date());
        return isValid(parsedToDate) ? parsedToDate : null;
    }, [getQueryParam]);

    const tags: string[] = useMemo(() => {
        return getQueryParamArray(TAGS_PARAM);
    }, [getQueryParamArray]);

    return { search, dateFrom, dateTo, tags };
};
