import { Button } from '@material-ui/core';
import { ReactComponent as CalendarIcon } from 'assets/icons/16px/calendar.svg';
import { subDays } from 'date-fns';
import { Translator, useTranslations } from 'i18n';
import * as React from 'react';
import { Dropdown } from 'rsuite';
import { NestedMenu, getUnixTimestamp } from 'shared';

interface DateFilterDropdownProps {
    title: string;
    isDisabled?: boolean;
    onSelect: (value: string | number) => void;
}

export const isCustomRange = (dateRange: DateRange | string | undefined): boolean =>
    !!dateRange && (dateRange === 'custom' || dateRange.toString().includes(','));

export const isValidDates = (dates: DateRange | string | null | string[]): boolean => !!dates && dates.length === 2;

export const getRangeFilterTitle = (__: Translator, dateRange: DateRange | string | null): string => {
    if (!dateRange) {
        return '';
    }
    if (dateRange === 'all') {
        return __('settings_migration.show_all');
    }
    if (isCustomRange(dateRange)) {
        return __('misc.custom');
    }
    return __('misc.last_range_days', { range: `${dateRange}` });
};

export type DateRange = [Date?, Date?];

export const getStartAndEndTime = (dateRangeValue: DateRange | string | undefined): [number?, number?] => {
    if (isCustomRange(dateRangeValue)) {
        const savedDateRange = `${dateRangeValue}`.split(',');
        if (isValidDates(savedDateRange)) {
            const [newStartTime, newEndTime] = savedDateRange;

            return [parseInt(newStartTime, 10), parseInt(newEndTime, 10)];
        }
    } else {
        const subtractDays = typeof dateRangeValue === 'string' ? parseInt(dateRangeValue, 10) : 90;
        const newStartTime = subDays(new Date(), subtractDays).setUTCHours(0, 0, 0, 0).valueOf() / 1000;

        return [newStartTime, undefined];
    }

    return [undefined, undefined];
};

export const getDateRangeString = (dates: DateRange): string => {
    if (isValidDates(dates)) {
        const [startDate, endDate] = dates;
        const unixStart = getUnixTimestamp(`${startDate}`);
        const unixEnd = getUnixTimestamp(`${endDate}`);
        return `${unixStart},${unixEnd}`;
    }

    return '';
};

export const DateFilterDropdown: React.FC<DateFilterDropdownProps> = ({ title, isDisabled, onSelect }) => {
    const { __ } = useTranslations();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleOpen = (event: React.SyntheticEvent) => {
        setAnchorEl(event.currentTarget as HTMLElement);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (value: string | number) => () => onSelect(value);

    return (
        <>
            <Button onClick={handleOpen} startIcon={<CalendarIcon />} variant="contained" disableElevation>
                {title}
            </Button>
            <NestedMenu
                id="league-date-filter-dropdown"
                anchorEl={anchorEl}
                onClose={handleClose}
                menuConfig={[
                    {
                        onClick: handleSelect(7),
                        label: __('misc.last_range_days', { range: `${7}` }),
                    },
                    {
                        onClick: handleSelect(14),
                        label: __('misc.last_range_days', { range: `${14}` }),
                    },
                    {
                        onClick: handleSelect(30),
                        label: __('misc.last_range_days', { range: `${30}` }),
                    },
                    {
                        onClick: handleSelect(90),
                        label: __('misc.last_range_days', { range: `${90}` }),
                    },
                    {
                        onClick: handleSelect('custom'),
                        label: __('misc.custom'),
                    },
                ]}
            />
        </>
    );

    return (
        <Dropdown variant="secondary" onSelect={onSelect} title={title} key={title} disabled={isDisabled}></Dropdown>
    );
};
