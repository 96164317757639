import * as React from 'react';
import { useAuth } from './use-auth';
import { useLocation } from 'react-router-dom';

export const useAuthUserGuard = () => {
    const { user, indeterminate } = useAuth();
    const location = useLocation();
    const [redirectTo, setRedirectTo] = React.useState<string | undefined | false>();

    React.useEffect(() => {
        if (!indeterminate) {
            let redirectUri = location.pathname;

            if (!user) {
                // If user is not present that means we're not logged in and should redirect
                const destination = location.pathname + location.hash;
                const query = destination === '/' ? '' : `?intended=${encodeURIComponent(destination)}`;
                redirectUri = `/login${query}`;
            } else if (user.need_to_set_password) {
                redirectUri = '/registration/finish';
            } else if (!user.email) {
                redirectUri = '/registration/email';
            } else if (user.need_to_accept_eula) {
                redirectUri = '/registration/eula';
            } else if (user.need_to_setup_mfa) {
                redirectUri = '/registration/mfa';
            } else if (user.require_mfa) {
                redirectUri = '/login/mfa';
            } else if (user.needs_password_update) {
                redirectUri = '/login/password-update';
            } else if (user.need_to_setup) {
                redirectUri = '/registration/setup';
            }

            if (!location.pathname.startsWith('/login') && redirectUri !== location.pathname) {
                setRedirectTo(redirectUri);
            } else {
                setRedirectTo(false);
            }
        }
    }, [user, indeterminate, location, setRedirectTo]);

    return redirectTo;
};
