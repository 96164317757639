import { Tags, TagsApi } from '../../oas';
import { UseQueryResult } from '@tanstack/react-query';
import { mockResponse, useApiQuery } from '../use-api-query';

export const useGetAllTagsOfTagType: (
    ...parameters: Parameters<TagsApi['getAllTagsOfTagType']>
) => UseQueryResult<Tags[]> = (tagType, options = {}) => {
    return useApiQuery(
        ['tags', 'tag_type', tagType],
        (apiConfig, axiosInstance) => {
            return tagType
                ? new TagsApi(apiConfig, '', axiosInstance).getAllTagsOfTagType(tagType, options)
                : mockResponse([]);
        },
        {
            staleTime: 3600000,
        },
    );
};
