import { ThemeProvider, Tooltip } from '@material-ui/core';
import { useHasFeature } from 'api';
import { useGetCustomer } from 'api/customer';
import { KEY_DEFAULT_LANGUAGE_WEB, useGetSetting, useSettingMutation } from 'api/settings';
import { useDefaultTeam } from 'api/team';
import { useGetTranslations } from 'api/translations';
import { ReactComponent as LanguageIcon } from 'assets/icons/16px/language.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/16px/logout.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/16px/settings.svg';
import { useAuth, usePermission, UserPermissionsEnum } from 'auth';
import clsx from 'clsx';
import { useTranslations } from 'i18n';
import * as React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { getLanguageIcons, MenuConfig, NestedMenu } from 'shared';
import { darkTheme } from 'theme';
import styles from './account-menu.module.css';
import { FrontendConfigFeaturesEnum } from 'oas';

const ICON_FILL = '#ffffff';

export const AccountMenu: React.FC = () => {
    // Hooks
    const { __ } = useTranslations();
    const history = useHistory();
    const languageSetting = useGetSetting(KEY_DEFAULT_LANGUAGE_WEB);
    const { user, logout } = useAuth();
    const team = useDefaultTeam();
    const { data: translations } = useGetTranslations();
    const { data: customer } = useGetCustomer();
    const languageIcons = getLanguageIcons();
    const settingMutation = useSettingMutation();
    const queryClient = useQueryClient();
    const showSettings = usePermission(UserPermissionsEnum.Settings);
    const hasMyAccount = useHasFeature(FrontendConfigFeaturesEnum.MyAccountReactJs);

    // Variables
    const accountName = user?.name || customer?.name || '';

    const handleMyAccountClick = () => {
        history.push(hasMyAccount ? 'myaccount' : '/settings/myaccount');
    };

    const handleLogoutClick = () => {
        sessionStorage.removeItem('demo-data-alert-dismiss');
        logout();
    };

    const buildLanguageMenu = React.useCallback(
        (language: string): MenuConfig[] => {
            const config: MenuConfig[] = [];
            if (translations) {
                const translationKeys = Object.keys(translations);
                const prefix = 'languages.local.';
                for (let i = 0; i < translationKeys.length; i++) {
                    const translationKey = translationKeys[i];

                    if (translationKey.indexOf(prefix) === 0) {
                        const lang = translationKey.replace(prefix, '');

                        const flagSrc = languageIcons?.[lang];

                        config.push({
                            testId: `${lang} menu-item`,
                            label: translations[translationKey],
                            icon: flagSrc ? <img src={flagSrc} /> : null,
                            isSelected: language === lang,
                            onClick: () => {
                                settingMutation.mutate(
                                    { key: KEY_DEFAULT_LANGUAGE_WEB, value: lang },
                                    {
                                        onSuccess: () => {
                                            queryClient.invalidateQueries({
                                                queryKey: ['translations'],
                                            });
                                        },
                                    },
                                );
                            },
                        });
                    }
                }
            }

            return config;
        },
        [languageIcons, settingMutation, translations, queryClient],
    );

    const menuConfig: MenuConfig[] = [
        { label: accountName },
        {
            testId: 'settings-menu-item',
            label: __('settings.account'),
            icon: <SettingsIcon fill={ICON_FILL} />,
            onClick: handleMyAccountClick,
            show: showSettings,
        },
        {
            testId: 'language-menu-item',
            label: __('header.language'),
            icon: <LanguageIcon fill={ICON_FILL} />,
            subMenu: buildLanguageMenu(languageSetting?.value || 'en'),
        },
        {
            testId: 'logout-menu-item',
            label: __('misc.log_out'),
            icon: <LogoutIcon fill={ICON_FILL} />,
            onClick: handleLogoutClick,
        },
    ];

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {user && (
                <>
                    <Tooltip title={accountName} placement="bottom">
                        <button
                            aria-controls="account-menu"
                            aria-haspopup="true"
                            onClick={handleOpen}
                            disabled={!user}
                            className={clsx(styles['button'], {
                                [styles['active']]: !!anchorEl,
                            })}
                        >
                            <div
                                className={styles['logo']}
                                style={{ backgroundImage: team?.logo ? `url(${team.logo_url})` : 'none' }}
                            />
                            <span className={styles['initial']}>
                                {accountName?.substring(0, 1).toLocaleUpperCase()}
                            </span>
                        </button>
                    </Tooltip>
                    <ThemeProvider theme={darkTheme}>
                        <NestedMenu
                            menuConfig={menuConfig}
                            onClose={handleClose}
                            anchorEl={anchorEl}
                            id="account-menu"
                            iconFill={ICON_FILL}
                        />
                    </ThemeProvider>
                </>
            )}
        </>
    );
};
