import axios from 'axios';
import {
    DataInjectionApi,
    GetInjectionTemplateRequest,
    InjectedActivityCreateRequest,
    InjectedActivityUpdateRequest,
} from 'oas';
import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useApiMutation } from '../use-api-mutation';

export type { InjectedActivityCreateRequest, InjectedActivityUpdateRequest } from 'oas';

export const useCreateInjectedActivity = (): UseMutationResult<
    { id: string },
    unknown,
    InjectedActivityCreateRequest
> => {
    const queryClient = useQueryClient();
    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            const response = await new DataInjectionApi(apiConfig, '', axiosInstance).createInjectedActivity(attr);

            if (axios.isAxiosError(response)) {
                throw response;
            }

            return { id: response.data?.id || '' };
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ['activities'],
                });
            },
        },
    );
};

export const useUpdateInjectedActivity = (): UseMutationResult<
    void,
    unknown,
    InjectedActivityUpdateRequest & { id: string }
> => {
    const queryClient = useQueryClient();
    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            const response = await new DataInjectionApi(apiConfig, '', axiosInstance).updateInjectedActivity(
                attr.id,
                attr,
            );

            if (axios.isAxiosError(response)) {
                throw response;
            }
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ['activities'],
                });
            },
        },
    );
};

export const useDeleteInjectedData = (): UseMutationResult<void, unknown, string> => {
    return useApiMutation(async (id, apiConfig, axiosInstance) => {
        await new DataInjectionApi(apiConfig, '', axiosInstance).deleteInjectedData({ activity_id: id });
    });
};

export const useUpdateInjectedData = (): UseMutationResult<void, unknown, { id: string; file: Blob }> => {
    return useApiMutation(async (attr, apiConfig, axiosInstance) => {
        const response = await new DataInjectionApi(apiConfig, '', axiosInstance).updateInjectedData(
            attr.id,
            attr.file,
        );

        if (axios.isAxiosError(response)) {
            throw response;
        }
    });
};

export const useGetTemplate = (): UseMutationResult<{ contents: string }, unknown, GetInjectionTemplateRequest> => {
    return useApiMutation(async (attr, apiConfig, axiosInstance) => {
        const response = await new DataInjectionApi(apiConfig, '', axiosInstance).getInjectionTemplate(attr);
        return {
            contents: response.data,
        };
    });
};
