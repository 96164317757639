import { Button } from '@SBGSports/referee-react';
import { TfNotification, useActionNotifications, useDeleteNotifications } from 'api';
import { useTranslations } from 'i18n';
import mixpanel from 'mixpanel-browser';
import * as React from 'react';

interface DismissButtonProps {
    notification: TfNotification;
}
export const DismissButton: React.FC<React.PropsWithChildren<DismissButtonProps>> = ({ children, notification }) => {
    const { __ } = useTranslations();

    const { mutate: deleteNotifications } = useDeleteNotifications();

    const handleClick = React.useCallback(() => {
        deleteNotifications([notification.id]);
        mixpanel.track('BellNotificationDismissClicked', { type: notification.type });
    }, [notification, deleteNotifications]);

    return (
        <Button
            size="small"
            variant="text"
            onClick={handleClick}
            disabled={notification.isActioning}
            className="notification-dismiss-button"
        >
            {children || __('misc.dismiss')}
        </Button>
    );
};

interface ActionButtonProps {
    notification: TfNotification;
    onClick?: () => boolean;
    variant?: 'primary' | 'text' | 'outline' | 'destruct' | undefined;
}

export const ActionButton: React.FC<React.PropsWithChildren<ActionButtonProps>> = ({
    children,
    notification,
    onClick,
    variant = 'primary',
}) => {
    const { __ } = useTranslations();

    const { mutate: runActions } = useActionNotifications();

    const [isClicked, setIsClicked] = React.useState(false);

    const handleAction = React.useCallback(() => {
        let runDefaultAction = true;
        if (onClick) {
            runDefaultAction = onClick();
        }

        if (runDefaultAction) {
            setIsClicked(true);
            runActions([notification.id]);
        }

        mixpanel.track('BellNotificationActionClicked', { type: notification.type, payload: notification.payload });
    }, [notification, runActions, onClick]);

    const isLoading = isClicked || notification.isActioning;

    return (
        <Button
            size="small"
            variant={variant}
            color="primary"
            onClick={handleAction}
            disabled={isLoading}
            loading={isLoading}
            className="notification-action-button"
        >
            {children || __('misc.update')}
        </Button>
    );
};
