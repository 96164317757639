import axios from 'axios';
import { CreateOrUpdateDeviceRequest, DevicesApi, DeleteDeviceAthletes } from 'oas';
import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useApiMutation } from '../use-api-mutation';

export const useCreateOrUpdateDevice = (): UseMutationResult<
    void,
    unknown,
    { id: number } & CreateOrUpdateDeviceRequest
> => {
    return useApiMutation(async (attr, apiConfig, axiosInstance) => {
        const { id, ...rest } = attr;
        const response = await new DevicesApi(apiConfig, '', axiosInstance).createOrUpdateDevice(id, rest);
        if (axios.isAxiosError(response)) {
            throw response;
        }
    });
};

export const useDeleteDeviceAthletes = (): UseMutationResult<void, unknown, DeleteDeviceAthletes> => {
    const queryClient = useQueryClient();
    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            const response = await new DevicesApi(apiConfig, '', axiosInstance).deleteDeviceAthletes(attr);

            if (axios.isAxiosError(response)) {
                throw response;
            }
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ['activities', 'unmapped_devices'],
                });
                queryClient.invalidateQueries({
                    queryKey: ['activities', 'athletes'],
                });
            },
        },
    );
};
