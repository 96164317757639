import * as React from 'react';
import { Box, createStyles, TextField, withStyles, Chip } from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { ReactComponent as CloseIconSmall } from 'assets/icons/12px/cancel.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/16px/check.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/16px/chevron-down.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/16px/remove.svg';

export interface Option {
    id: string;
    name: string;
    readonly?: boolean;
}

export interface MultiSelectProps {
    options: Option[];
    value: Option[];
    limitTags?: number;
    onChange: AutocompleteProps<Option, true, true, true>['onChange'];
}

export const MultiSelect = withStyles(() =>
    createStyles({
        root: {
            backgroundColor: 'var(--r-catapult-white)',
        },
    }),
)((props: MultiSelectProps) => {
    const { options, value, limitTags, onChange, ...rest } = props;

    return (
        <Autocomplete
            multiple
            disableCloseOnSelect
            id="notification-settings-select-teams"
            options={options}
            getOptionLabel={(option) => option.name}
            value={value}
            onChange={onChange}
            limitTags={limitTags}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
            closeIcon={<RemoveIcon />}
            popupIcon={<ChevronDownIcon />}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Box display="flex" justifyContent="space-between" width="100%">
                        <Box>{option.name}</Box>
                        <Box>{selected && <CheckIcon style={{ color: 'var(--main-ltsfc-success-green)' }} />}</Box>
                    </Box>
                </React.Fragment>
            )}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        {...getTagProps({ index })}
                        color="primary"
                        key={option.id}
                        label={option.name}
                        disabled={option.readonly || false}
                    />
                ))
            }
            ChipProps={{ size: 'small', deleteIcon: <CloseIconSmall />, color: 'primary' }}
            {...rest}
        />
    );
});
