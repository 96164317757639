export const round = (number: number, decimalPlaces: number): number => {
    const factor = Math.pow(10, decimalPlaces);

    return Math.round((number + Number.EPSILON) * factor) / factor;
};

export const percentage = (valueA: number, valueB: number): number => {
    return (100 * valueA) / valueB;
};

export const average = (valueArray: Array<number> | undefined | null): number => {
    if (Array.isArray(valueArray) && valueArray.length > 0) {
        return valueArray.reduce((a, b) => a + b) / valueArray.length;
    }

    return 0;
};

export const getCountOf = (data: Array<unknown> | undefined | null): number => {
    if (Array.isArray(data)) {
        return data.length;
    }

    return 0;
};

export const calculateOverall = (volume: number, intensity: number) => {
    return round((volume * intensity) / 100, 0);
};
