import { useApiConfig, useAuth } from 'auth';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Configuration } from 'oas';
import { QueryKey, useQueries, UseQueryResult } from '@tanstack/react-query';

interface ExtendedPromise<T> extends Promise<T> {
    cancel: () => void;
}
export function useApiQueries<T, P>(
    keys: QueryKey[],
    queryParams: P[],
    fetcher: (apiConfig: Configuration, axiosInstance: AxiosInstance, queryParam: P) => Promise<AxiosResponse<T>>,
    options = {},
    apiVersion = 'v6',
): UseQueryResult<T, unknown>[] {
    const { apiConfig, axiosInstance } = useApiConfig(apiVersion);
    const auth = useAuth();

    return useQueries({
        queries: keys.map((key, i) => {
            return {
                queryKey: key,

                queryFn: () => {
                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();
                    apiConfig.baseOptions = {
                        cancelToken: source.token,
                    };

                    const promise = fetcher(apiConfig, axiosInstance, queryParams[i]).then((response) => {
                        return {
                            ...response.data,
                        };
                    }) as ExtendedPromise<T>;

                    promise.cancel = () => {
                        source.cancel('Query was cancelled by React Query');
                    };
                    return promise;
                },

                staleTime: 600000,
                enabled: auth.isAuthenticated,
                ...options,
            };
        }),
    });
}
