import { Parameter, ParameterGroup, ParameterType } from 'oas';
import { REPORTING_PARAMETER_GROUP_NAME, REPORTING_PARAMETER_GROUP_NEW_NAME } from 'shared';

export interface TfReportingParameterGroup {
    name: string;
    parameters: Parameter[];
}

const DEFAULT_SLUGS = [
    'accel&decel_efforts',
    'accel&decel_efforts_per_minute',
    'hr_exertion',
    'red_zone',
    'energy',
    'high_metabolic_load_distance',
    'sprint_efforts',
    'sprint_distance_per_minute',
    'high_speed_distance',
    'high_speed_efforts',
    'high_speed_distance_per_minute',
];

const getNewGroup = (parameterSlug: string): string => {
    switch (parameterSlug) {
        case 'accel&decel_efforts':
        case 'accel&decel_efforts_per_minute':
            return 'Acceleration (Gen 2)';
        case 'hr_exertion':
        case 'red_zone':
            return 'Heart Rate';
        case 'high_metabolic_load_distance':
        case 'energy':
            return 'Metabolic Power';
        case 'sprint_efforts':
        case 'sprint_distance_per_minute':
        case 'high_speed_distance':
        case 'high_speed_efforts':
        case 'high_speed_distance_per_minute':
            return 'Velocity';
        default:
            return 'Custom';
    }
};

export const transformParameterGroups = (
    groups: ParameterGroup[],
    types: ParameterType[],
): TfReportingParameterGroup[] => {
    const parameterTypeMap: { [key: string]: ParameterType } = {};
    for (const type of types) {
        parameterTypeMap[type.id || ''] = type;
    }

    const groupMap: { [key: string]: TfReportingParameterGroup } = {};

    for (const slug of DEFAULT_SLUGS) {
        const groupName = getNewGroup(slug);
        if (!groupMap[groupName]) {
            groupMap[groupName] = { name: groupName, parameters: [] };
        }
    }

    const reportingGroup = groups.find(
        (group) => group.name === REPORTING_PARAMETER_GROUP_NAME || group.name === REPORTING_PARAMETER_GROUP_NEW_NAME,
    );

    if (reportingGroup) {
        for (const param of reportingGroup.parameters) {
            const groupName: string = DEFAULT_SLUGS.includes(param.slug)
                ? getNewGroup(param.slug)
                : parameterTypeMap[param.parameter_type_id]?.name ?? 'Custom';

            if (!groupMap[groupName]) {
                groupMap[groupName] = { name: groupName, parameters: [] };
            }

            const existingGroup = groupMap[groupName];
            if (!existingGroup.parameters.some((existingParam) => existingParam.id === param.id)) {
                existingGroup.parameters.push(param);
            }
        }
    }

    const result: TfReportingParameterGroup[] = Object.values(groupMap).filter((group) => group.parameters.length > 0);

    result.push({
        name: 'Load Scores',
        parameters: [
            {
                calculation: '',
                created_at: '',
                ctr_order: -1,
                id: '-1',
                modified_at: '',
                name: 'Volume',
                original_name: 'Volume',
                parameter_type_id: '-1',
                slug: 'volume',
                unit_type: 'percentage',
            },
            {
                calculation: '',
                created_at: '',
                ctr_order: -2,
                id: '-2',
                modified_at: '',
                name: 'Intensity',
                original_name: 'Intensity',
                parameter_type_id: '-2',
                slug: 'intensity',
                unit_type: 'percentage',
            },
            {
                calculation: '',
                created_at: '',
                ctr_order: -3,
                id: '-3',
                modified_at: '',
                name: 'Overall',
                original_name: 'Overall',
                parameter_type_id: '-3',
                slug: 'overall',
                unit_type: 'percentage',
            },
        ],
    });

    return result;
};

export const transformParameterIntoGroups = (
    parameters: Parameter[],
    types: ParameterType[],
): TfReportingParameterGroup[] => {
    const parameterGroups: TfReportingParameterGroup[] = types.map((type) => ({
        name: type.name ?? '',
        parameters: parameters.filter((param) => param.parameter_type_id === type.id),
    }));

    return parameterGroups.filter((group) => group.parameters.length > 0);
};
