import * as React from 'react';
import { DialogProps } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'assets/icons/16px/cancel.svg';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Dialog, IconButton } from 'shared';
import styles from './oauth-dialog.module.css';
import { Authorize } from 'oauth';
import { useLocation } from 'react-router-dom';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface OAuthDialogProps extends DialogProps {
    onClose: () => void;
}

export const OAuthDialog: React.FC<OAuthDialogProps> = (props) => {
    const { path } = useRouteMatch();

    // We use this query to access the query parameters.
    const query = useQuery();

    return (
        <Dialog {...props} maxWidth={false}>
            <div className={styles['oauth-dialog__container']}>
                <Switch>
                    <Route key={'authorize'} path={`${path}/authorize`}>
                        <Authorize
                            clientId={query.get('client_id') || ''}
                            redirectUrl={query.get('redirect_uri') || ''}
                            responseType={query.get('response_type') || ''}
                            scope={query.get('scope') || ''}
                            state={query.get('state') || ''}
                        />
                    </Route>
                </Switch>

                <div className={styles['oauth-dialog__close-button']}>
                    <IconButton aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
        </Dialog>
    );
};
