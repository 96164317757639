import { usePermission } from 'auth';
import { parseJSON } from 'date-fns';
import { useTranslations } from 'i18n';
import { ActionButton, DismissButton } from 'notifications/buttons';
import { UserPermissionsEnum } from 'oas';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { round } from 'shared';
import { NotificationView } from '../notification-view';
import { NotificationTypeProps } from './notification-type-props';
import { getProfileVars } from './profile-helpers';

export const NewMaxVel: React.FC<NotificationTypeProps> = (props) => {
    const { notification, handleToggleRead } = props;

    const { __ } = useTranslations();
    const [title, setTitle] = React.useState<React.ReactNode>(<></>);
    const [icon, setIcon] = React.useState<React.ReactNode>(<></>);
    const [contents, setContents] = React.useState<React.ReactNode>(<></>);
    const createdAt = parseJSON(notification.created_at);
    const canAction = usePermission(UserPermissionsEnum.SettingsBands);

    React.useEffect(() => {
        if (!notification.activities?.length || !notification.athletes?.length || !notification.period) {
            return;
        }

        const { athleteName, athleteIcon, oldMax, newMax, changeDetails, periodDetails } = getProfileVars(
            __,
            notification,
        );
        setIcon(athleteIcon);
        setTitle(
            __('notifications.new_max_vel_title', {
                athleteName,
            }),
        );
        const unit = notification.payload?.unit || __('units.ms_abbrev');

        setContents(
            <>
                <ReactMarkdown>
                    {__('notifications.new_max_vel_prev', {
                        prevVel: `${round(oldMax, 2)}`,
                        speedUnit: unit,
                    })}
                </ReactMarkdown>{' '}
                |{' '}
                <ReactMarkdown>
                    {__('notifications.new_max_vel_new', {
                        newVel: `${round(newMax, 2)}`,
                        speedUnit: unit,
                    })}
                </ReactMarkdown>{' '}
                {changeDetails}
                <br />
                {periodDetails}
            </>,
        );
    }, [notification, __]);

    return notification.activities?.length ? (
        <NotificationView
            icon={icon}
            title={title}
            contents={contents}
            createdAt={createdAt}
            isRead={notification.read === 1}
            onToggleRead={handleToggleRead}
            actions={
                canAction
                    ? [
                          <DismissButton key="dismiss" notification={notification} />,
                          <ActionButton key="action" notification={notification}>
                              {__('misc.update')}
                          </ActionButton>,
                      ]
                    : []
            }
        />
    ) : (
        <></>
    );
};
