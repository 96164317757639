import { Athlete, AthletesApi } from 'oas';
import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useApiQuery } from '../use-api-query';

export const useGetAthletes = (
    options?: UseQueryOptions,
    ...parameters: Parameters<AthletesApi['getAthletes']>
): UseQueryResult<Athlete[]> => {
    return useApiQuery(
        ['athletes', parameters],
        (apiConfig, axiosInstance) => new AthletesApi(apiConfig, '', axiosInstance).getAthletes(...parameters),
        options || {},
    );
};

export const useGetAthlete = (...parameters: Parameters<AthletesApi['getAthlete']>): UseQueryResult<Athlete> => {
    return useApiQuery(['athlete', parameters], (apiConfig, axiosInstance) => {
        return new AthletesApi(apiConfig, '', axiosInstance).getAthlete(...parameters);
    });
};
