import { KEY_DEFAULT_LANGUAGE_WEB, useGetSetting } from 'api';

//OF cloud supported language: en, es, fr, ja, ptbr, zh
const supportedLanguagesMapping: { [key: string]: string } = {
    en: 'en',
    es: 'es',
    fr: 'fr',
    ja: 'ja',
    ptbr: 'pt-br',
    zh: 'zh-cn',
};

export const useGetMappedUserLanguage = (): string => {
    const languageSetting = useGetSetting(KEY_DEFAULT_LANGUAGE_WEB);

    return languageSetting?.value && languageSetting?.value !== undefined
        ? supportedLanguagesMapping[languageSetting?.value]
        : 'en';
};
