import * as React from 'react';
import { ScreenSizeShape } from './use-window-size';

/**
 * Provides information about the screen size
 */
export const useScreenSize = (): ScreenSizeShape => {
    const [screenSize, setScreenSize] = React.useState<ScreenSizeShape>({
        width: window.innerWidth,
        height: innerHeight,
    });

    React.useEffect(() => {
        function onResize() {
            setScreenSize({
                width: screen.width,
                height: screen.height,
            });
        }

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return screenSize;
};
