import { useApiMutation } from 'api/use-api-mutation';
import { useAuth } from 'auth';
import axios from 'axios';
import { User, UserApi, UpdateUserRequest, UpdateUserPreferencesRequest } from 'oas';
import { UseMutationResult, useQueryClient } from '@tanstack/react-query';

export const useUpdateUser = (): UseMutationResult<User | unknown, unknown, UpdateUserRequest> => {
    const { reloadUser } = useAuth();

    return useApiMutation(async (attr, apiConfig, axiosInstance) => {
        const response = await new UserApi(apiConfig, '', axiosInstance).update(attr);

        if (axios.isAxiosError(response)) {
            throw response;
        }

        reloadUser();

        return response?.data;
    });
};

export const useUpdateUserPreferences = (): UseMutationResult<void, unknown, UpdateUserPreferencesRequest> => {
    const queryClient = useQueryClient();

    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            const response = await new UserApi(apiConfig, '', axiosInstance).updatePreferences(attr);

            if (axios.isAxiosError(response)) {
                throw response;
            }
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ['teams'],
                });
            },
        },
    );
};
