export const KEY_ATHLETE_IMPORT_VITAL_STATISTICS = 'AthleteImportVitalStatistics';
export const KEY_ATHLETE_MAPPING_TAG = 'AthleteMappingTag';
export const KEY_MIGRATE_ANNOTATIONS = 'MigrateAnnotations';
export const KEY_ATHLETE_PROFILE_GENDER = 'AthleteProfileGender';
export const KEY_ATHLETE_PROFILE_HEIGHT = 'AthleteProfileHeight';
export const KEY_ATHLETE_PROFILE_MAX_HEART_RATE = 'AthleteProfileMaxHeartRate';
export const KEY_ATHLETE_PROFILE_MAX_VELOCITY = 'AthleteProfileMaxVelocity';
export const KEY_ATHLETE_PROFILE_WEIGHT = 'AthleteProfileWeight';
export const KEY_DATE_LOCALE = 'AthleteImportDateFormat';
export const KEY_DEFAULT_LANGUAGE_WEB = 'DefaultLanguageWeb';
export const KEY_LEAGUE_ACTIVITIES_DEFAULT_RANGE_FILTER = 'league_activities_default_range_filter';
export const KEY_LEAGUE_TEAMS_DEFAULT_RANGE_FILTER = 'league_teams_default_range_filter';
export const KEY_NOTIFICATIONS_NOTIFY_MAX_HR = 'NotificationsNotifyMaxHr';
export const KEY_NOTIFICATIONS_NOTIFY_MAX_VEL = 'NotificationsNotifyMaxVel';
export const KEY_NOTIFICATIONS_NOTIFY_TEAMS = 'NotificationsNotifyTeams';
export const KEY_NOTIFICATIONS_SHOW_UNREAD_ONLY = 'NotificationsShowUnreadOnly';
export const KEY_TEAM_SLUG_MAPPING_OVERRIDE = 'TeamSlugMappingOverride';

// The key is the scope, the value is the translation key
export const SCOPE_NAMES: Record<string, string> = {
    connect: 'settings_api_token.scope_type_summary',
    'sensor-read-only': 'settings_api_token.scope_type_sensor',
    catapultr: 'settings_api_token.scope_type_catapultr',
    'activities-update': 'settings_api_token.scope_type_activities_create_and_update',
    'annotations-update': 'settings_api_token.scope_type_annotations_create_and_update',
    'athletes-update': 'settings_api_token.scope_type_athletes_create_and_update',
    'parameters-update': 'settings_api_token.scope_type_custom_parameters_create_and_update',
    'tags-update': 'settings_api_token.scope_type_tags_create_and_update',
};
