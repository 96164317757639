import * as React from 'react';
import clsx from 'clsx';

export interface BadgeProps {
    status: 'dark' | 'success' | 'error' | 'warning' | 'info';
    style?: React.CSSProperties;
}

export const Badge: React.FC<React.PropsWithChildren<BadgeProps>> = ({ status, style = {}, children }) => {
    return (
        <div style={{ display: 'inline-block', ...style }}>
            <span className={clsx('badge', `badge-${status}`)}>{children}</span>
        </div>
    );
};
