import { Typography } from '@material-ui/core';
import { TfNotice, useGetNotices, useNoticeMutation, useNoticesMutation } from 'api/notices';
import clsx from 'clsx';
import { useTranslations } from 'i18n';
import mixpanel from 'mixpanel-browser';
import { NoticeUpdateRequest } from 'oas';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { Placeholder } from 'rsuite';
import { countMax } from 'shared';
import { useUnreadUpdatesCount } from '.';
import styles from './updates.module.css';
import { Spinner } from '@SBGSports/referee-react';

export const UpdatesList: React.FC = () => {
    const { __ } = useTranslations();
    const { data: newNotices } = useGetNotices();
    const unreadCount = useUnreadUpdatesCount();
    const [notices, setNotices] = React.useState<TfNotice[]>([]);
    const isLoading = newNotices === undefined;

    React.useEffect(() => {
        if (newNotices) {
            setNotices(newNotices);
        }
    }, [newNotices]);

    const noticeMutation = useNoticeMutation();
    const noticesMutation = useNoticesMutation();

    React.useEffect(() => {
        noticesMutation.mutate({ notified: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateOne = (notice: TfNotice, attr: NoticeUpdateRequest) => {
        const eventName = attr.read ? 'NotificationRead' : 'NotificationUnread';
        mixpanel.track(eventName, {
            notification_title: notice.title,
            notification_id: notice.id,
        });

        noticeMutation.mutate({ ...attr, id: notice.id });
    };

    const updateAll = (attr: NoticeUpdateRequest) => {
        if (unreadCount > 0) {
            if (attr.read) {
                mixpanel.track('NotificationAllRead', {
                    num_unread: unreadCount,
                });
            }

            noticesMutation.mutate(attr);
        }
    };

    // Make links open in new window
    const linkTrackingAdded = React.useRef(false);
    React.useEffect(() => {
        const anchorElements = document.querySelectorAll(`.${styles['updates-list-item-copy']} a`);

        if (anchorElements.length && !linkTrackingAdded.current) {
            linkTrackingAdded.current = true;
            for (let i = 0; i < anchorElements.length; i++) {
                anchorElements[i].setAttribute('target', '_blank');

                anchorElements[i].addEventListener('click', function (event: Event) {
                    if (event.target === null) {
                        return;
                    }

                    const anchorElement = event.target as HTMLAnchorElement;

                    if (!anchorElement.closest) {
                        return null;
                    }

                    const noticeElement = anchorElement.closest(`.${styles['updates-list-item']}`);

                    if (noticeElement === null) {
                        return;
                    }

                    mixpanel.track('NotificationLinkClicked', {
                        link_url: anchorElement.href,
                        link_text: anchorElement.innerText,
                        notification_title:
                            (
                                noticeElement.querySelector(
                                    `.${styles['updates-list-item-header']} h4`,
                                ) as HTMLHeadingElement
                            )?.innerText || '',
                        notification_id: noticeElement.getAttribute('data-id'),
                    });
                });
            }
        }
    }, [notices]);

    return (
        <>
            <div className={styles['updates']}>
                <div className={styles['updates-header']} data-testid="updates-header">
                    <span
                        className={clsx({
                            [styles['unread-count']]: true,
                            [styles['has-unread']]: unreadCount !== 0,
                        })}
                        onClick={() => updateAll({ read: true })}
                    >
                        {isLoading ? <Spinner /> : countMax(unreadCount, 99)}
                    </span>
                    <Typography variant="h3">
                        {__('misc.updates')}
                        {unreadCount === 0 ? null : (
                            <button
                                className={styles['updates-header-mark-as-read']}
                                onClick={() => updateAll({ read: true })}
                            >
                                Mark all as read
                            </button>
                        )}
                    </Typography>
                </div>
                <div className={styles['updates-list']} data-testid="updates-list">
                    {isLoading ? (
                        <div className={styles['loading-placeholder']}>
                            <Placeholder.Paragraph rows={5} active />
                            <Placeholder.Graph style={{ marginTop: 30 }} active />
                        </div>
                    ) : (
                        notices.map((notice) => (
                            <div key={notice.id}>
                                <div className={styles['updates-list-item']} data-id={notice.id}>
                                    <div className={styles['updates-list-item-header']}>
                                        <div
                                            title={notice.read ? 'Mark as unread' : 'Mark as read'}
                                            className={clsx({
                                                [styles['updates-list-item-read-status']]: true,
                                                [styles['unread']]: !notice.read,
                                            })}
                                            onClick={() => updateOne(notice, { read: !notice.read })}
                                        ></div>
                                        <Typography variant="h4">{notice.title}</Typography>
                                        <div className={styles['updates-list-item-date']}>
                                            {notice.published_at_formatted}
                                        </div>
                                    </div>
                                    <div className={styles['updates-list-item-copy']}>
                                        <ReactMarkdown>{notice.copy}</ReactMarkdown>
                                    </div>
                                    <div
                                        className={styles['updates-list-item-image']}
                                        style={notice.image ? { backgroundImage: `url("${notice.image}")` } : {}}
                                    ></div>
                                </div>
                                <div className={styles['updates-list-item-divider']}></div>
                            </div>
                        ))
                    )}
                    {!isLoading && notices.length === 0 ? (
                        <div className={styles['updates-list-item']}>You have no updates.</div>
                    ) : null}
                </div>
            </div>
        </>
    );
};
