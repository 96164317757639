import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useModifyUrl = () => {
    const history = useHistory();
    const { search } = useLocation();

    const queryParams = useMemo(() => {
        const searchParams = new URLSearchParams(search);
        const stringParams = searchParams.toString();

        return stringParams ? `?${stringParams}` : '';
    }, [search]);

    const modifyUrl = useCallback(
        (param: string | undefined, parameterIndex: number) => {
            const currentPath = history.location.pathname;
            const pathSegments = currentPath.split('/');

            if (param) {
                if (pathSegments.length > parameterIndex) {
                    pathSegments[parameterIndex] = param;
                } else {
                    pathSegments.push(param);
                }
            } else {
                if (pathSegments.length > parameterIndex) {
                    pathSegments.splice(parameterIndex, 1);
                }
            }
            const newPath = pathSegments.join('/');
            history.push(`${newPath}${queryParams}`);
        },
        [history, queryParams],
    );

    return modifyUrl;
};

export const useModifySearchParameters = () => {
    const history = useHistory();
    const { search } = useLocation();

    const searchParams = useMemo(() => new URLSearchParams(search), [search]);

    const modifySearchParams = useCallback(
        (paramName: string, value: string | undefined) => {
            if (value) {
                searchParams.set(paramName, value);
                history.replace({ search: searchParams.toString() });
            } else {
                searchParams.delete(paramName);
                history.replace({ search: searchParams.toString() });
            }
        },
        [history, searchParams],
    );

    return modifySearchParams;
};
