// Add the keys for date fields that needs sorting
export const dateFieldsArray = [
    'activity_date',
    'created_at',
    'last_full_sync',
    'last_sync',
    'modified_at',
    'startDateTime',
    'endDateTime',
];

function compareValues<T extends Record<string, string | number | Date>, K extends keyof T>(
    key: K,
    order = 'asc',
    isDateType = false,
): (a: T, b: T) => number {
    return function innerSort(a: T, b: T) {
        let valA: string | number | Date = a[key];
        let valB: string | number | Date = b[key];

        if (valA === undefined || valB === undefined) {
            return 0;
        }

        valA = typeof valA === 'string' ? valA.toUpperCase() : valA;
        valB = typeof valB === 'string' ? valB.toUpperCase() : valB;

        if (isDateType) {
            if (typeof valA === 'string') {
                valA = Date.parse(valA);
            }
            if (typeof valB === 'string') {
                valB = Date.parse(valB);
            }
        }

        let comparison = 0;
        if (valA > valB) {
            comparison = 1;
        } else if (valA < valB) {
            comparison = -1;
        }
        return order === 'desc' ? comparison * -1 : comparison;
    };
}

/**
 * For sorting the data in tables
 *
 * @param data
 * @param sortColumn
 * @param sortType
 * @returns {*}
 */
export function sortTableData<T extends Record<string, string | number | Date>, K extends keyof T>(
    data: T[],
    sortColumn: K,
    sortType = 'asc',
): T[] {
    if (typeof sortColumn === 'string' && data && sortColumn) {
        let isDateField = false;
        if (dateFieldsArray.includes(sortColumn)) {
            isDateField = true;
        }
        return [...data].sort(compareValues(sortColumn, sortType, isDateField));
    }
    return data;
}

export function getFirstSorted<T extends Record<string, string | number | Date>>(
    data: T[],
    field: string,
    order = 'asc',
    filterColumn: string,
    filterValue: unknown,
    isDateType = false,
): T | null {
    if (data && field) {
        const sortedData = data.sort(compareValues(field, order, isDateType));
        if (filterColumn && filterValue) {
            // Ignore the type check here
            // eslint-disable-next-line eqeqeq
            return sortedData.find((item) => item[filterColumn] === filterValue) || null;
        }
        return sortedData[0];
    }
    return null;
}
