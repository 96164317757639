import { SelectOption, SelectOptions, Tooltip, usePositioner } from '@SBGSports/referee-react';
import { IconExternalSmall, IconHelp, IconChatSmall } from '@SBGSports/referee-react/icons';
import { useHasFeature } from 'api';
import { useTranslations } from 'i18n';
import { FrontendConfigFeaturesEnum } from 'oas';
import * as React from 'react';
import { IconButton } from 'shared/icon-button';
import styles from './support-button.module.css';
import { ZendeskAPI } from 'react-zendesk';

export const SupportButton: React.FC = () => {
    //Hooks
    const { __ } = useTranslations();
    const showCoreHelpCenter = useHasFeature(FrontendConfigFeaturesEnum.ShowCoreHelpCenter);

    const { getPopperProps, getReferenceProps } = usePositioner({
        placement: 'top-start',
    });

    //Local state
    const dropdownRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
    const [showHelpMenu, setShowHelpMenu] = React.useState(false);
    const menuItems: SelectOption[] = React.useMemo(
        () => [
            {
                value: __('header.manuals'),
                id: 0,
                isSelected: false,
                startIcon: <IconExternalSmall className={styles['icon-external']} />,
            },
            {
                value: __('misc.menu_item_support'),
                id: 1,
                isSelected: false,
                startIcon: <IconChatSmall className={styles['icon']} />,
            },
        ],
        [__],
    );

    const handleHelpClick = () => {
        setShowHelpMenu((s) => !s);
    };

    const handleOnSelectedItem = (selectedOption: SelectOption) => {
        if (selectedOption.id === 0) {
            const helpCenter = showCoreHelpCenter
                ? 'https://core.catapultsports.com/hc/en-us'
                : 'https://support.catapultsports.com/hc/en-us';
            window.open(helpCenter);
        }
        if (selectedOption.id === 1) {
            ZendeskAPI('messenger', 'open');
        }
        setShowHelpMenu(false);
    };

    return (
        <Tooltip text={__('misc.help')} position="right">
            <div>
                <div ref={dropdownRef}>
                    <IconButton
                        onClick={() => handleHelpClick}
                        {...getReferenceProps({
                            onClick: () => setShowHelpMenu((s) => !s),
                        })}
                    >
                        <IconHelp className={styles['icon-help']} />
                    </IconButton>
                </div>
                <SelectOptions
                    popperProps={getPopperProps}
                    parentRef={dropdownRef as React.MutableRefObject<HTMLElement>}
                    setIsVisible={setShowHelpMenu}
                    isVisible={showHelpMenu}
                    menuItems={menuItems}
                    onSelectItem={(selected) => handleOnSelectedItem(selected as SelectOption)}
                />
            </div>
        </Tooltip>
    );
};
