import { Position, PositionsApi } from 'oas';
import { useApiQuery } from '../use-api-query';
import { UseQueryResult } from '@tanstack/react-query';

export const useGetPositions = (
    ...parameters: Parameters<PositionsApi['getPositions']>
): UseQueryResult<Position[]> => {
    return useApiQuery(['positions', parameters], (apiConfig, axiosInstance) =>
        new PositionsApi(apiConfig, '', axiosInstance).getPositions(...parameters),
    );
};
