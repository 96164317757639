import axios from 'axios';

const stripTags = (string: string): string => {
    return string.replace(/(<([^>]+)>)/gi, '');
};

export const getDisplayableErrors = (newErrors: { [field: string]: string[] }): { [field: string]: string } => {
    const messages: { [field: string]: string } = {};

    for (const field in newErrors) {
        if (typeof newErrors[field][0] === 'string') {
            messages[field] = stripTags(newErrors[field][0]);
        }
    }

    return messages;
};

export function getFormErrors<T extends Record<string, unknown>>(
    error: Error,
    emptyValues: T,
    defaultErrorField: string,
): T {
    if (!axios.isAxiosError(error)) {
        throw error;
    }

    const responseData = error.response?.data;

    let newErrors;
    if (typeof responseData?.errors === 'object') {
        newErrors = getDisplayableErrors(responseData.errors);
    } else {
        if (typeof responseData?.message === 'string') {
            newErrors = { [defaultErrorField]: stripTags(responseData?.message) };
        }
    }

    return { ...emptyValues, ...newErrors };
}
