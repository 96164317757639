import { Alert, AlertItem, AlertActions, Button } from '@SBGSports/referee-react';
import * as React from 'react';
import styles from './notification-alert.module.css';

export interface NotificationAlertProps {
    title: string;
    installerReleaseNotesUrl: string | undefined;
    primaryButtonText: string;
    secondaryButtonText: string;
    primaryButtonAction?: () => void;
    secondaryButtonAction?: () => void;
    closeButtonAction?: () => void;
}

export const NotificationAlert: React.FC<NotificationAlertProps> = React.forwardRef<
    HTMLDivElement | null,
    NotificationAlertProps
>(function NotificationAlert(props, ref) {
    const {
        title,
        installerReleaseNotesUrl,
        primaryButtonText,
        secondaryButtonText,
        primaryButtonAction,
        secondaryButtonAction,
        closeButtonAction,
    } = props;

    return (
        <div ref={ref}>
            <Alert inline dangerouslySetClassName={styles['alert']}>
                <AlertItem title={title} open={true} onClose={closeButtonAction}>
                    <AlertActions variant="button">
                        {installerReleaseNotesUrl ? (
                            <Button
                                className={styles['secondary-button']}
                                size="small"
                                variant="text"
                                onClick={secondaryButtonAction}
                            >
                                {secondaryButtonText}
                            </Button>
                        ) : null}

                        <Button
                            size="small"
                            variant="primary"
                            onClick={primaryButtonAction}
                            className={styles['primary-button']}
                        >
                            {primaryButtonText}
                        </Button>
                    </AlertActions>
                </AlertItem>
            </Alert>
        </div>
    );
});
