import { AxiosResponse } from 'axios';
import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useApiMutation } from 'api/use-api-mutation';
import {
    AnnotationCategory,
    AnnotationCategoryRequest,
    AnnotationLayer,
    AnnotationLayerRequestWithAnnotations,
    AnnotationsApi,
    BatchDeleteRequest,
    ImportersApi,
} from 'oas/internal';

export const useCreateAnnotationCategoriesBatch = (): UseMutationResult<
    AxiosResponse<AnnotationCategory[]>,
    unknown,
    AnnotationCategoryRequest[]
> => {
    return useApiMutation(
        async (categoryRequest, apiConfig, axiosInstance) => {
            axiosInstance.interceptors.request.use((config) => {
                config.headers['Content-Type'] = 'application/json';
                return config;
            });
            return await new ImportersApi(apiConfig, '', axiosInstance).createAnnotationCategories(categoryRequest);
        },
        {},
        ['annotations-category-batch'],
        'internal',
    );
};

export const useCreateAnnotationLayerForActivity = (
    activityId: string,
): UseMutationResult<AxiosResponse<AnnotationLayer>, unknown, AnnotationLayerRequestWithAnnotations, unknown> => {
    return useApiMutation(
        async (categoryRequest, apiConfig, axiosInstance) => {
            axiosInstance.interceptors.request.use((config) => {
                config.headers['Content-Type'] = 'application/json';
                return config;
            });
            return await new ImportersApi(apiConfig, '', axiosInstance).createAnnotationLayerForActivity(
                activityId,
                categoryRequest,
            );
        },
        {},
        ['annotation', 'layer', activityId],
        'internal',
    );
};

export const useDeleteAnnotationLayer = (): UseMutationResult<AxiosResponse<void>, unknown, string> => {
    const queryClient = useQueryClient();
    return useApiMutation(
        async (annotationLayerId, apiConfig, axiosInstance) => {
            return await new ImportersApi(apiConfig, '', axiosInstance).deleteAnnotationLayer(annotationLayerId);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ['annotation', 'layer'],
                });
            },
        },
        ['annotation', 'layer'],
        'internal',
    );
};

export const useDeleteAnnotation = (): UseMutationResult<void, unknown, string, unknown> => {
    const queryClient = useQueryClient();
    return useApiMutation(
        async (id, apiConfig, axiosInstance) => {
            await new AnnotationsApi(apiConfig, '', axiosInstance).deleteAnnotation(id);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: ['annotation'],
                });
            },
        },
    );
};

export const useDeleteAnnotations = (activityId: string): UseMutationResult<void, unknown, string[], unknown> => {
    const queryClient = useQueryClient();
    return useApiMutation(
        async (ids, apiConfig, axiosInstance) => {
            const annotationBatchRequest: BatchDeleteRequest = { ids };
            await new AnnotationsApi(apiConfig, '', axiosInstance).deleteAnnotations(annotationBatchRequest);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: ['annotations', activityId],
                });
            },
        },
        [],
        'internal',
    );
};
