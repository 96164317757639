import {
    KEY_LEAGUE_ACTIVITIES_DEFAULT_RANGE_FILTER,
    KEY_LEAGUE_TEAMS_DEFAULT_RANGE_FILTER,
    TfLeagueMigration,
    TfLeagueTeamActivity,
    transformLeagueTeamActivity,
    transformMigration,
    useGetSetting,
} from 'api';
import { getStartAndEndTime } from 'league/date-filter-dropdown';
import { Customer, LeagueActivityDetail, LeagueApi } from 'oas';
import { UseQueryResult } from '@tanstack/react-query';
import { INTERVAL_MIGRATION_PULL } from 'shared';
import { useApiQuery } from '../use-api-query';
import { TfLeagueTeamChild, transformLeagueTeamChild } from './transform';

export const useGetLeagueDateRange = (settingKey: string): [number?, number?, string?] => {
    const dateRangeSetting = useGetSetting(settingKey);
    if (!dateRangeSetting) {
        return [];
    }
    const { value: dateRangeValue } = dateRangeSetting;

    return [...getStartAndEndTime(dateRangeValue), dateRangeValue];
};

export const useGetLeagueTeams = (options = {}): UseQueryResult<TfLeagueTeamChild[]> => {
    const [startTime, endTime] = useGetLeagueDateRange(KEY_LEAGUE_TEAMS_DEFAULT_RANGE_FILTER);

    return useApiQuery(
        ['league-teams', startTime, endTime, options],
        (apiConfig, axiosInstance) =>
            new LeagueApi(apiConfig, '', axiosInstance).getLeagueTeams(startTime, endTime).then((response) => ({
                ...response,
                data: response.data.children.map(transformLeagueTeamChild),
            })),
        { enabled: !!startTime, ...options },
    );
};

export const useGetLeagueMigrations = (
    limit: number,
    customerId?: number,
    options = {},
): UseQueryResult<TfLeagueMigration[]> => {
    const realLimit = Math.max(limit, 20);
    return useApiQuery(
        ['league-migrations', customerId, limit],
        (apiConfig, axiosInstance) =>
            new LeagueApi(apiConfig, '', axiosInstance).getMigrations(realLimit, customerId).then((response) => ({
                ...response,
                data: response.data.running_migrations.filter((row) => !!row.activity).map(transformMigration),
            })),
        { enabled: limit > 0, refetchInterval: INTERVAL_MIGRATION_PULL, ...options },
    );
};

export const useGetLeagueTeamActivities = (
    customerId: number,
): UseQueryResult<{ activities: TfLeagueTeamActivity[]; customer: Customer }> => {
    const [startTime, endTime] = useGetLeagueDateRange(KEY_LEAGUE_ACTIVITIES_DEFAULT_RANGE_FILTER);

    return useApiQuery(
        ['league-team-activities', customerId, startTime, endTime],
        (apiConfig, axiosInstance) =>
            new LeagueApi(apiConfig, '', axiosInstance)
                .getLeagueTeam(customerId, startTime, endTime)
                .then((response) => ({
                    ...response,
                    data: {
                        ...response.data,
                        activities: response.data.activities.map(transformLeagueTeamActivity),
                    },
                })),
        { enabled: !!startTime },
    );
};

export const useGetLeagueTeamActivity = (
    customerId: number,
    activityId: string,
    isParent: boolean,
): UseQueryResult<LeagueActivityDetail> =>
    useApiQuery(
        ['league-team-activity-detail', customerId, activityId, isParent],
        async (apiConfig, axiosInstance) =>
            await new LeagueApi(apiConfig, '', axiosInstance).getLeagueActivityDetail(
                customerId,
                activityId,
                isParent ? 1 : 0,
            ),
    );
