import { DialogProps } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'assets/icons/16px/cancel.svg';
import { useGetNotices } from 'api/notices';
import mixpanel from 'mixpanel-browser';
import * as React from 'react';
import { Dialog, IconButton, usePrevious } from 'shared';
import { UpdatesList } from './updates-list';
import styles from './updates.module.css';

interface UpdatesDialogProps extends DialogProps {
    onClose: () => void;
}

export const UpdatesDialog: React.FC<UpdatesDialogProps> = (props) => {
    const { onClose, open } = props;
    const { data: notices } = useGetNotices();

    const myOnClose = () => {
        mixpanel.track('NotificationModal', {
            modal: 'close',
            num_unread: notices?.filter((item) => !item.read).length,
            num_updates: notices?.length || 0,
        });
        onClose && onClose();
    };

    const wasOpen = usePrevious(open);
    React.useEffect(() => {
        if (!wasOpen && open && notices?.length) {
            mixpanel.track('NotificationModal', {
                modal: 'open',
                num_unread: notices.filter((item) => !item.read).length,
                num_updates: notices.length || 0,
            });
        }
    }, [wasOpen, open, notices]);

    return (
        <Dialog {...props} onClose={myOnClose} maxWidth={false}>
            <div className={styles['updates-dialog__container']}>
                <UpdatesList />
                <div className={styles['updates-dialog__close-button']}>
                    <IconButton aria-label="close" onClick={myOnClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
        </Dialog>
    );
};
