import { Notification, NotificationTypeEnum } from 'oas';

export enum NotificationFilterCategory {
    Activity = 'activity',
    Account = 'account',
}

export interface TfNotification extends Notification {
    groupKey: string | undefined;
    typeFilter?: NotificationFilterCategory;
    isDeleting?: boolean;
    isActioning?: boolean;
}

export const transformNotification = (notification: Notification): TfNotification => {
    let typeFilter;
    let groupKey;
    switch (notification.type) {
        case NotificationTypeEnum.NewMaxHr:
        case NotificationTypeEnum.Info:
        case NotificationTypeEnum.NewMaxVel:
            const activity = notification.activities?.[0];
            typeFilter = NotificationFilterCategory.Activity;
            if (activity) {
                groupKey = `${NotificationFilterCategory.Activity}-${activity.id}`;
            }
            break;
        case NotificationTypeEnum.ActivityOwnership:
        case NotificationTypeEnum.PasswordExpiring:
        case NotificationTypeEnum.NewDownload:
            typeFilter = NotificationFilterCategory.Account;
            break;
    }

    return {
        ...notification,
        typeFilter,
        groupKey,
    };
};
