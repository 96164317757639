import * as React from 'react';
import { useAuth } from 'auth';
import { Grid, List, ListItem } from '@material-ui/core';
import { Spinner, Button, Typography } from '@SBGSports/referee-react';
import { useTranslations } from 'i18n';
import { Helmet } from 'react-helmet-async';
import { appTitle, confirm } from 'shared';
import styles from './authorize.module.css';
import {
    useGetAuthorizeDataForCodeGrant,
    useApproveAuthorizeTokenForCodeGrant,
    useDeclineAuthorizeTokenForCodeGrant,
} from 'api/oauth/authorize';
import { OAuthAuthorizeTokenResponse } from 'oas';

interface AuthorizeProps {
    clientId: string;
    redirectUrl: string;
    responseType: string;
    scope: string;
    state: string;
}

export const Authorize: React.FC<AuthorizeProps> = (props) => {
    const { clientId, redirectUrl, responseType, scope, state } = props;

    // Language support
    const { __ } = useTranslations();

    // Use this to ensure we have an authed user with CSRF.
    const auth = useAuth();
    const isAuthenticated = auth.csrfAuthd && auth.user;

    const [isErroneous, setIsErroneous] = React.useState<boolean>(false);
    const [isApproving, setIsApproving] = React.useState<boolean>(false);
    const [isDeclining, setIsDeclining] = React.useState<boolean>(false);

    const { mutateAsync: approveAuthorizeTokenForCodeGrant } = useApproveAuthorizeTokenForCodeGrant();
    const { mutateAsync: declineAuthorizeTokenForCodeGrant } = useDeclineAuthorizeTokenForCodeGrant();

    // Call the authorization to get back authorization scope details etc...
    const { data: fetchedAuthorization, isLoading } = useGetAuthorizeDataForCodeGrant(
        clientId,
        redirectUrl,
        responseType,
        scope,
        state,
    );

    React.useEffect(() => {
        setIsErroneous('data' !== fetchedAuthorization?.result);
    }, [fetchedAuthorization, isLoading]);

    const handleRedirect = (response: OAuthAuthorizeTokenResponse) => {
        if (response.data?.url) {
            window.location.href = response.data?.url;
        }
    };

    const handleApprove = async () => {
        setIsApproving(true);
        if (await confirm(__, __('auth.oauth_authorize_approve_confirm'))) {
            handleRedirect(
                await approveAuthorizeTokenForCodeGrant({
                    auth_token: fetchedAuthorization?.data?.authToken || '',
                }),
            );
        }
        setIsApproving(false);
    };

    const handleDecline = async () => {
        setIsDeclining(true);
        if (await confirm(__, __('auth.oauth_authorize_decline_confirm'))) {
            handleRedirect(
                await declineAuthorizeTokenForCodeGrant({
                    auth_token: fetchedAuthorization?.data?.authToken || '',
                }),
            );
        }
        setIsDeclining(false);
    };

    return (
        <>
            <Helmet>
                <title>{appTitle(__('auth.oauth_authorize_request'))}</title>
            </Helmet>
            {isLoading && (
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Spinner size="medium" />
                    </Grid>
                </Grid>
            )}
            {!isLoading && isAuthenticated && (
                <>
                    <Typography variant="heading-3" align="center" id="modal-title">
                        {__('auth.oauth_authorize_request')}
                    </Typography>
                    {isErroneous && <div>{__('auth.oauth_authorize_client_error')}</div>}
                    {!isErroneous && (
                        <>
                            <Typography
                                variant="body-1"
                                className={styles['client_permission']}
                                style={{
                                    marginTop: '45px',
                                }}
                                id="authorize-client"
                            >
                                {__('auth.oauth_authorize_client_permission', {
                                    client_name: fetchedAuthorization?.data?.client?.name || 'Unknown',
                                })}
                            </Typography>
                            <Typography
                                variant="body-1"
                                style={{
                                    marginTop: '45px',
                                }}
                                id="authorize-domain"
                            >
                                {__('auth.oauth_authorize_client_domain', {
                                    client_domain: fetchedAuthorization?.data?.client?.domain || 'Unknown',
                                })}
                            </Typography>
                            <Typography
                                variant="body-1"
                                style={{
                                    marginTop: '45px',
                                }}
                                id="authorize-scopes"
                            >
                                {__('auth.oauth_authorize_requested_scopes')}

                                <List className={styles['requested_scopes_list']}>
                                    {fetchedAuthorization?.data?.scopes?.map((item) => (
                                        <ListItem key={item?.id} className={styles['requested_scopes_list_item']}>
                                            {item?.description}
                                        </ListItem>
                                    ))}
                                </List>
                            </Typography>
                            <Grid container spacing={2} style={{ marginTop: '25px' }}>
                                <Grid item xs={6} style={{ textAlign: 'center' }}>
                                    <Button
                                        variant="primary"
                                        disabled={isApproving || isDeclining}
                                        onClick={handleApprove}
                                        id="approve-auth"
                                    >
                                        {__('auth.oauth_authorize_approve')}
                                    </Button>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'center' }}>
                                    <Button
                                        variant="outline"
                                        disabled={isApproving || isDeclining}
                                        onClick={handleDecline}
                                        id="decline-auth"
                                    >
                                        {__('auth.oauth_authorize_decline')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </>
            )}
        </>
    );
};
