import { useUpdateNotifications } from 'api';
import { parseJSON } from 'date-fns';
import { useTranslations } from 'i18n';
import { ActionButton, DismissButton } from 'notifications/buttons';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { NotificationView } from '../notification-view';
import { NotificationTypeProps } from './notification-type-props';

export const PasswordExpiring: React.FC<NotificationTypeProps> = (props) => {
    const { notification, handleToggleRead } = props;

    const { __ } = useTranslations();
    const createdAt = parseJSON(notification.created_at);
    const daysLeft = notification.payload?.days_left;

    const { mutate: updateNotifications } = useUpdateNotifications();

    const handleAction = React.useCallback(() => {
        updateNotifications({ ids: [notification.id], read: true });
        window.location.href = '/settings/myaccount';
        return false;
    }, [updateNotifications, notification]);

    const title =
        daysLeft === 0
            ? __('notifications.password_expiring_title_less_than_1')
            : daysLeft === 1
            ? __('notifications.password_expiring_title_1')
            : __('notifications.password_expiring_title', { numberOfDays: `${daysLeft}` });

    return daysLeft !== undefined ? (
        <NotificationView
            title={title}
            contents={<ReactMarkdown>{__('notifications.password_expiring_body')}</ReactMarkdown>}
            createdAt={createdAt}
            isRead={notification.read === 1}
            onToggleRead={handleToggleRead}
            actions={[
                <DismissButton key="dismiss" notification={notification} />,
                <ActionButton key="action" notification={notification} onClick={handleAction}>
                    {__('settings.go_to_settings')}
                </ActionButton>,
            ]}
        />
    ) : (
        <></>
    );
};
