import * as React from 'react';
import { useAuthUserGuard } from 'auth';
import { ErrorLayout } from 'layout';
import { Redirect } from 'react-router-dom';

const Error404: React.FC = () => {
    // Hooks
    const redirectUri = useAuthUserGuard();

    if (redirectUri) {
        return <Redirect to={redirectUri} />;
    }

    return (
        <ErrorLayout
            code="404"
            title="Page not found"
            message={
                <>
                    Sorry, but the page you were trying to view does not exist.
                    <br />
                    <br />
                    Please click back or click below to go to the home page.
                </>
            }
        />
    );
};

export { Error404 as default, Error404 };
