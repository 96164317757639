import * as React from 'react';
import { useApiQuery } from '../use-api-query';
import { FrontendApi, FrontendConfig, FrontendConfigFeaturesEnum } from 'oas';
import { UseQueryResult } from '@tanstack/react-query';
import { useAuth } from 'auth';

export const useGetFrontendConfig = (options = {}): UseQueryResult<FrontendConfig> => {
    const { user } = useAuth();
    return useApiQuery(
        ['frontend-config', user?.id],
        async (apiConfig, axiosInstance) => await new FrontendApi(apiConfig, '', axiosInstance).getConfig(options),
        {
            staleTime: 3600 * 8 * 1000,
            enabled: true,
            ...options,
        },
    );
};

export const useHasFeature = (featureName: FrontendConfigFeaturesEnum): boolean | null => {
    const { user } = useAuth();
    const { data: frontendConfig, isLoading } = useGetFrontendConfig();

    // Memorize the list of features
    const listFeatures = React.useMemo(
        () => frontendConfig?.features?.map((feature) => feature.toLocaleLowerCase()) || [],
        [frontendConfig?.features],
    );

    // Return false when user not logged
    if (!user) {
        return false;
    }

    return isLoading ? null : listFeatures?.includes(featureName.toLowerCase()) || false;
};
