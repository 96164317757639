import * as React from 'react';
import { Enable, Resizable as ReResizable, ResizableProps } from 're-resizable';
import styles from './resizable.module.css';

export const Resizable: React.FC<React.PropsWithChildren<ResizableProps & { handle: keyof Enable }>> = ({
    children,
    handle,
    ...props
}) => {
    return (
        <ReResizable {...props} enable={{ [handle]: true }} handleClasses={{ [handle]: styles['resizable-handle'] }}>
            {children}
        </ReResizable>
    );
};
