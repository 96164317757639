import { Box, ButtonBase, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'i18n';
import * as React from 'react';
import { capitalise, DATE_FNS_CASUAL_DATE_TIME_FORMAT, useDateFormatter, useIntervalFormatter } from 'shared';
import styles from './notification-view.module.css';
import { ReactComponent as OFLogo } from 'assets/of_logo.svg';

interface NotificationViewProps {
    icon?: React.ReactNode;
    title: React.ReactNode;
    contents: React.ReactNode;
    createdAt: Date;
    isRead: boolean;
    onToggleRead?: () => void;
    actions?: React.ReactNode[];
}

export const NotificationView: React.FC<NotificationViewProps> = (props) => {
    const { __ } = useTranslations();
    const formatDate = useDateFormatter();
    const formatInterval = useIntervalFormatter();
    const { icon, title, contents, createdAt, isRead, onToggleRead, actions } = props;
    const rightWidth = 80;

    return (
        <Box className={styles['container']} p={2}>
            <Box display="flex" alignItems="center">
                <div className={styles['icon']}>{icon || <OFLogo />}</div>
                <Box mx={1.5}>
                    <Typography variant="body1" className="notification-title">
                        {title}
                    </Typography>
                </Box>
                <Box width={rightWidth} textAlign="right" flexShrink={0} marginLeft="auto">
                    <Tooltip placement="bottom-end" title={formatDate(createdAt, DATE_FNS_CASUAL_DATE_TIME_FORMAT)}>
                        <Typography variant="caption" style={{ cursor: 'default' }} className="notification-timestamp">
                            {capitalise(formatInterval(createdAt))}
                        </Typography>
                    </Tooltip>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" mt={2}>
                <Box>
                    <Typography variant="caption" className="notification-content">
                        {contents}
                    </Typography>
                </Box>
                {onToggleRead && (
                    <Box width={rightWidth} minWidth={rightWidth} textAlign="right" flexShrink={0} ml="auto">
                        <Tooltip
                            placement="bottom-end"
                            title={isRead ? __('notifications.mark_as_unread') : __('notifications.mark_as_read')}
                        >
                            <ButtonBase
                                className={clsx(
                                    styles['read-icon'],
                                    { [styles['is-read']]: isRead },
                                    'notification-read-icon',
                                )}
                                onClick={onToggleRead}
                            />
                        </Tooltip>
                    </Box>
                )}
            </Box>
            {actions && (
                <Box display="flex" justifyContent="flex-end" mt={2}>
                    {actions.map((action, index) => (
                        <Box key={index} ml={2} className="notification-action">
                            {action}
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
};
