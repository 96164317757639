import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

export interface IconButtonProps extends MuiIconButtonProps {
    dark?: boolean;
}

export const IconButton = withStyles((theme: Theme) => {
    const color = theme.palette.type === 'dark' ? 'var(--r-catapult-white)' : 'var(--r-catapult-black)';
    const bgHover = theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(16, 16, 16, 0.04)';
    const bgActive = theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.32)' : 'rgba(16, 16, 16, 0.12)';

    return createStyles({
        root: {
            color,
            fontSize: '1rem',
            '&:hover': { backgroundColor: bgHover },
            '&.Mui-focusVisible, &:active': { backgroundColor: bgActive, outline: 'none' },
        },
        label: {
            color,
            '& > svg path': { fill: color },
        },
    });
})(MuiIconButton);
