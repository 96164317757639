import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { CtrExportSettings, CtrReportParameters, ReportsApi, CtrActivityIds, CtrReportResponse } from 'oas';
import { useApiQuery } from '../use-api-query';
import { useApiMutation } from '../use-api-mutation';
import axios from 'axios';

export const useGetCtrExportSettings = (ctrActivityIds: CtrActivityIds): UseQueryResult<CtrExportSettings> => {
    return useApiQuery(
        ['ctr-settings', ctrActivityIds],
        (apiConfig, axiosInstance) => {
            return new ReportsApi(apiConfig, '', axiosInstance).getCtrExportSettings(ctrActivityIds);
        },
        { enabled: ctrActivityIds.ids.length > 0 },
    );
};

interface CtrSingleAttr {
    id: string;
    postCtrSetting?: CtrReportParameters;
}

export const useGenerateCtrReport = (): UseMutationResult<CtrReportResponse, unknown, CtrSingleAttr> => {
    return useApiMutation(async (attr, apiConfig, axiosInstance) => {
        const response = await new ReportsApi(apiConfig, '', axiosInstance).generateCtrReport(
            attr.id,
            attr.postCtrSetting,
        );

        if (axios.isAxiosError(response)) {
            throw response;
        }

        return response.data;
    });
};

export const useGenerateCtrReports = (): UseMutationResult<void, unknown, CtrReportParameters> => {
    return useApiMutation(async (attr, apiConfig, axiosInstance) => {
        const response = await new ReportsApi(apiConfig, '', axiosInstance).generateCtrReports(attr);

        if (axios.isAxiosError(response)) {
            throw response;
        }

        return response.data;
    });
};
