import { LoginButton, LoginTextField } from 'login';
import * as React from 'react';
import { getFormErrors, useSnackbar } from 'shared';

interface ErrorState {
    username?: string;
    email?: string;
}

interface FormValues {
    username: string;
    email: string;
}

export interface ForgotPasswordFormProps {
    onSubmit: (submitValues: FormValues) => Promise<void>;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (props) => {
    const { onSubmit } = props;
    const { infoSnackbar } = useSnackbar();

    const emptyValues = {
        username: '',
        email: '',
    };

    const [errors, setErrors] = React.useState<ErrorState>({});
    const [submitValues, setSubmitValues] = React.useState<FormValues>(emptyValues);
    const [loading, setLoading] = React.useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const newErrors: ErrorState = {};

        if (submitValues.username.trim() === '') {
            newErrors.username = 'Username is required';
        }

        if (submitValues.email.trim() === '') {
            newErrors.email = 'Email is required';
        }

        if (Object.keys(newErrors).length !== 0) {
            setErrors(newErrors);
            return;
        }

        setLoading(true);
        try {
            await onSubmit(submitValues);
            infoSnackbar(
                'We have sent an email to you containing password reset instruction. Please check your inbox.',
            );
        } catch (error) {
            setErrors(getFormErrors(error as Error, emptyValues, 'username'));
        } finally {
            setLoading(false);
        }
    };

    const handleFormChange: React.ChangeEventHandler<HTMLFormElement> = (event) => {
        const target = event.target;

        setSubmitValues({ ...submitValues, [target.name]: target.value });
        setErrors({});
    };

    return (
        <>
            <form
                onSubmit={handleSubmit}
                method="post"
                noValidate
                onChange={handleFormChange}
                style={{ width: '360px', maxWidth: '100%' }}
                data-testid="forgot-password-form"
            >
                <div>
                    <LoginTextField
                        label="Username"
                        name="username"
                        type="text"
                        required
                        fullWidth
                        helperText={errors.username}
                        error={errors.username !== undefined}
                        disabled={loading}
                        inputProps={{ 'data-testid': 'forgot-username-fld' }}
                    />
                </div>
                <div>
                    <LoginTextField
                        label="Email"
                        name="email"
                        type="email"
                        required
                        fullWidth
                        helperText={errors.email}
                        error={errors.email !== undefined}
                        disabled={loading}
                        inputProps={{ 'data-testid': 'forgot-email-fld' }}
                    />
                </div>
                <LoginButton type="submit" disabled={loading} data-testid="forgot-form-submit">
                    Confirm
                </LoginButton>
            </form>
        </>
    );
};
