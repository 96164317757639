import { Button, ButtonProps, createStyles, makeStyles, TextField, TextFieldProps, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import * as React from 'react';
import styles from './form-elements.module.css';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            marginBottom: theme.spacing(2),
        },
        button: {
            marginTop: theme.spacing(2),
        },
    }),
);

export const LoginTextField: React.FC<TextFieldProps> = (props) => {
    const classes = useStyles();
    const { className, label, ...rest } = props;
    const id = React.useMemo(() => uniqueId('ltf'), []);

    return (
        <>
            <label htmlFor={id} className={styles['login-text-field-label']}>
                {label}
            </label>
            <TextField
                id={id}
                className={clsx(classes.textField, className)}
                {...rest}
                inputProps={{
                    className: styles['login-text-field-input'],
                    ...rest.inputProps,
                }}
                InputProps={{ disableUnderline: true, ...rest.InputProps }}
                variant="standard"
            />
        </>
    );
};

export const LoginButton: React.FC<React.PropsWithChildren<React.PropsWithChildren<ButtonProps>>> = ({
    children,
    ...props
}) => {
    const classes = useStyles();
    const { className, ...rest } = props;

    return (
        <Button className={clsx(classes.button, className)} variant="contained" color="primary" fullWidth {...rest}>
            {children}
        </Button>
    );
};

export const PasswordField: React.FC<TextFieldProps> = (props) => {
    const classes = useStyles();
    const { className, ...rest } = props;

    return <TextField type="password" className={clsx(classes.textField, className)} {...rest} />;
};
