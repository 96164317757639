import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { Setting, SettingsApi, SettingUpdateRequest, AthleteProfileSettings } from 'oas';
import { useApiMutation, useOptimisticOptions } from '../use-api-mutation';

export const useSettingMutation = (): UseMutationResult<void, unknown, SettingUpdateRequest & { key: string }> => {
    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            await new SettingsApi(apiConfig, '', axiosInstance).updateSetting(attr.key, attr);
        },
        useOptimisticOptions<Setting[] | undefined, SettingUpdateRequest & { key: string }>(
            ['settings', []],
            (newAttr) => (oldItems) => {
                let found = false;
                const newItems = oldItems
                    ? oldItems.map((oldItem: Setting) => {
                          if (oldItem.key !== newAttr.key) {
                              return oldItem;
                          }
                          found = true;
                          return mergeItems(newAttr, oldItem);
                      })
                    : [];

                if (!found) {
                    newItems.push(newAttr as Setting);
                }
                return newItems;
            },
        ),
    );
};

const mergeItems = (newAttr: SettingUpdateRequest, oldItem: Setting): Setting => ({ ...oldItem, ...newAttr });

export const useCreateDefaultSettings = (): UseMutationResult<void, unknown, AthleteProfileSettings> => {
    const queryClient = useQueryClient();

    return useApiMutation(
        async (attr, apiConfig, axiosInstance) =>
            (await new SettingsApi(apiConfig, '', axiosInstance).postDefaultAthleteProfileSettings(attr))?.data,
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ['settings'],
                });
            },
        },
    );
};
