import { ThemeProvider } from '@material-ui/core';
import { ReactComponent as Logo } from 'assets/catapult-openfield-logo.svg';
import clsx from 'clsx';
import * as React from 'react';
import { darkTheme, lightTheme } from 'theme';
import { BaseLayout } from '.';
import styles from './mini-layout.module.css';

type MiniLayoutProps = {
    narrow?: boolean;
    center?: boolean;
    logo?: boolean;
    dark?: boolean;
};

export const MiniLayout: React.FC<React.PropsWithChildren<MiniLayoutProps>> = ({
    narrow = false,
    center = false,
    logo = true,
    dark = true,
    children,
}) => {
    return (
        <BaseLayout dark={dark}>
            <ThemeProvider theme={dark ? darkTheme : lightTheme}>
                <main className={clsx(styles.main, { [styles['dark']]: dark })}>
                    <div
                        className={clsx({
                            [styles['container']]: true,
                            [styles['narrow']]: narrow,
                            [styles['center']]: center,
                        })}
                    >
                        {logo && <Logo className={styles['logo']} />}
                        <div className={styles['content']}> {children}</div>
                    </div>
                </main>
            </ThemeProvider>
        </BaseLayout>
    );
};
