import { AuthValues } from 'auth';
import * as React from 'react';
import { getFormErrors } from 'shared';
import { LoginButton, LoginTextField } from './form-elements';

interface ErrorState {
    name?: string;
    password?: string;
}

export interface UsernamePasswordFormProps {
    onSubmit: AuthValues['login'];
}

export const UsernamePasswordForm: React.FC<UsernamePasswordFormProps> = ({ onSubmit }) => {
    const [errors, setErrors] = React.useState<ErrorState>({});
    const [loading, setLoading] = React.useState(false);
    const [name, setUsername] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const newErrors: ErrorState = {};

        if (name.trim() === '') {
            newErrors.name = 'Username is required';
        }

        if (password.trim() === '') {
            newErrors.password = 'Password is required';
        }

        if (Object.keys(newErrors).length !== 0) {
            setErrors(newErrors);
            return;
        }

        setLoading(true);
        try {
            await onSubmit(name, password);
        } catch (error) {
            setErrors(getFormErrors(error as Error, {}, 'password'));
        } finally {
            setLoading(false);
        }
    };

    const clearError = (field: string) => {
        setErrors({ ...errors, [field]: '' });
    };

    return (
        <>
            <form data-testid="login-form" onSubmit={handleSubmit} method="post" noValidate>
                <div>
                    <LoginTextField
                        inputProps={{ 'data-testid': 'login-form-username' }}
                        label="Username"
                        name="name"
                        type="text"
                        value={name}
                        autoComplete="username"
                        onChange={(event) => {
                            clearError('name');
                            setUsername(event.target.value);
                        }}
                        fullWidth
                        helperText={errors.name}
                        error={errors.name !== undefined}
                        disabled={loading}
                    />
                </div>
                <div>
                    <LoginTextField
                        inputProps={{ 'data-testid': 'login-form-password' }}
                        label="Password"
                        name="password"
                        type="password"
                        value={password}
                        autoComplete="current-password"
                        onChange={(event) => {
                            clearError('password');
                            setPassword(event.target.value);
                        }}
                        fullWidth
                        helperText={errors.password}
                        error={errors.password !== undefined}
                        disabled={loading}
                    />
                </div>
                <LoginButton type="submit" disabled={loading} data-testid="login-form-submit">
                    Log In
                </LoginButton>
            </form>
        </>
    );
};
