import * as React from 'react';
import { useAuth } from 'auth';
import { useGetTranslations } from 'api/translations';
import { BaseLayout } from 'layout';

export type Translator = (key: string, tokens?: { [key: string]: string }) => string;

export interface I18nContextShape {
    __: Translator;
}

export const I18nContext = React.createContext<I18nContextShape>({
    __: (): string => process.env.REACT_APP_TRANSLATIONS_NOT_LOADED_STR || '',
});

export const I18nProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { data: translations } = useGetTranslations();
    const auth = useAuth();

    const __: Translator = (key, tokens) => {
        let translation = translations?.[key] || key;

        if (tokens) {
            Object.keys(tokens).forEach((token) => {
                translation = translation.replace(new RegExp(`:${token}`, 'ig'), tokens[token]);
            });
        }

        return translation;
    };

    return (
        <I18nContext.Provider value={{ __ }}>
            {!auth.user || translations ? children : <BaseLayout isLoading />}
        </I18nContext.Provider>
    );
};
