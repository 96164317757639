import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useAuth } from 'auth';
import { useTranslations } from 'i18n';
import { MiniLayout } from 'layout';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { CentredSpinner, appTitle } from 'shared';

const PasswordUpdateForm = React.lazy(() => import('./password-update-form'));

export const ForcePasswordUpdate: React.FC = () => {
    const { user, logout, updateEmailPassword, checkAuthentication } = useAuth();
    const history = useHistory();
    const { __ } = useTranslations();

    React.useEffect(() => {
        if (!user?.needs_password_update) {
            history.push('/');
        }
    }, [history, user]);

    const handleSubmit = async (...args: Parameters<typeof updateEmailPassword>) => {
        await updateEmailPassword(...args);
        checkAuthentication();
    };

    return (
        <>
            <MiniLayout narrow>
                <Helmet>
                    <title>{appTitle('Update password')}</title>
                </Helmet>
                {user && (
                    <React.Suspense fallback={<CentredSpinner variant="primary" />}>
                        <Box marginBottom={2}>
                            <Typography>
                                {__('password.force_update_intro1', {
                                    numberOfDays: `${user.needs_password_update}`,
                                })}
                            </Typography>
                        </Box>
                        <Box marginBottom={4}>
                            <Typography>{__('password.force_update_intro2')}</Typography>
                        </Box>
                        <PasswordUpdateForm onSubmit={handleSubmit} onCancel={logout} />
                    </React.Suspense>
                )}
            </MiniLayout>
        </>
    );
};
export default ForcePasswordUpdate;
