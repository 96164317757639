import { Box, Collapse, Grow } from '@material-ui/core';
import { TfNotification, useUpdateNotifications } from 'api';
import mixpanel from 'mixpanel-browser';
import { NotificationTypeEnum } from 'oas';
import * as React from 'react';
import {
    ActivityOwnership,
    Info,
    NewDownload,
    NewMaxHr,
    NewMaxVel,
    NotificationTypeProps,
    PasswordExpiring,
    Welcome,
} from './types';

interface NotificationListItemProps {
    notification: TfNotification;
}

export const NotificationListItem: React.FC<NotificationListItemProps> = (props) => {
    const { notification } = props;

    const updateNotification = useUpdateNotifications();

    let NotificationComponent: React.ComponentType<NotificationTypeProps> | undefined;

    const handleToggleRead = React.useCallback(() => {
        const isRead = !notification.read;
        updateNotification.mutate({ ids: [notification.id], read: isRead });
        mixpanel.track(isRead ? 'BellNotificationMarkRead' : 'BellNotificationMarkUnread', { type: notification.type });
    }, [updateNotification, notification]);

    switch (notification.type) {
        case NotificationTypeEnum.ActivityOwnership:
            NotificationComponent = ActivityOwnership;
            break;
        case NotificationTypeEnum.PasswordExpiring:
            NotificationComponent = PasswordExpiring;
            break;
        case NotificationTypeEnum.NewMaxVel:
            NotificationComponent = NewMaxVel;
            break;
        case NotificationTypeEnum.NewMaxHr:
            NotificationComponent = NewMaxHr;
            break;
        case NotificationTypeEnum.Info:
            NotificationComponent = Info;
            break;
        case NotificationTypeEnum.Welcome:
            NotificationComponent = Welcome;
            break;
        case NotificationTypeEnum.NewDownload:
            NotificationComponent = NewDownload;
            break;
    }

    return NotificationComponent ? (
        <Collapse in={!notification.isDeleting} timeout={500}>
            <Grow in={!notification.isDeleting}>
                <Box mx={3} mb={2}>
                    <NotificationComponent notification={notification} handleToggleRead={handleToggleRead} />
                </Box>
            </Grow>
        </Collapse>
    ) : (
        <></>
    );
};
