import { ListItem, ListItemIcon, Menu, MenuItem, PopoverOrigin } from '@material-ui/core';
import * as React from 'react';
import { NestedMenuItem } from '..';
import { ReactComponent as CheckIcon } from 'assets/icons/16px/check.svg';
import { menuItemStyles } from './mui-styles';
import styles from './nested-menu.module.css';

interface NestedMenuProps {
    id: string;
    menuConfig: MenuConfig[];
    // anchorOrigin: This is the point on the anchor where the popover's anchorEl will attach to.
    // Options: vertical: [top, center, bottom]; horizontal: [left, center, right].
    anchorOrigin?: PopoverOrigin;
    // transformOrigin:  This is the point on the popover which will attach to the anchor's origin.
    // Options: vertical: [top, center, bottom, x(px)]; horizontal: [left, center, right, x(px)].
    transformOrigin?: PopoverOrigin;
    nestedAnchorOrigin?: PopoverOrigin;
    nestedTransformOrigin?: PopoverOrigin;
    onClose: () => void;
    anchorEl: HTMLElement | null;
    iconFill?: string;
}

export interface MenuConfig {
    testId?: string;
    label: React.ReactNode;
    icon?: React.ReactNode;
    onClick?: (event: React.MouseEvent) => void;
    isSelected?: boolean;
    subMenu?: MenuConfig[];
    show?: boolean;
}

export const NestedMenu: React.FC<NestedMenuProps> = (props) => {
    const {
        id,
        menuConfig,
        anchorOrigin = {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin = {
            vertical: 'top',
            horizontal: 'center',
        },
        nestedAnchorOrigin = {
            vertical: 'bottom',
            horizontal: 'left',
        },
        nestedTransformOrigin = {
            vertical: 'center',
            horizontal: 'right',
        },
        onClose,
        anchorEl,
        iconFill,
    } = props;

    const withClose = (action?: MenuConfig['onClick']) => {
        if (action) {
            return (event: React.MouseEvent) => {
                onClose();
                action(event);
            };
        }
    };

    const createMenu = (myMenuConfig: MenuConfig, index: number) => {
        const { testId, isSelected, subMenu, label, icon, onClick, show = true } = myMenuConfig;

        if (!show) {
            return null;
        }

        const labelWithIcon = (
            <>
                {icon ? <ListItemIcon className={styles['nested-menu-item-icon']}>{icon}</ListItemIcon> : null}
                {label}
            </>
        );

        const selectedIcon = (
            <ListItemIcon className={styles['nested-menu-item-selected-icon']}>
                {isSelected ? <CheckIcon fill="#ffffff" /> : null}
            </ListItemIcon>
        );

        const handleClick = withClose(onClick);

        if (subMenu) {
            return (
                <NestedMenuItem
                    data-test={testId}
                    key={index}
                    id={`${id}-submenu-${index}`}
                    label={
                        <>
                            {labelWithIcon}
                            {selectedIcon}
                        </>
                    }
                    onClick={onClick}
                    parentMenuOpen={Boolean(anchorEl)}
                    anchorOrigin={nestedAnchorOrigin}
                    transformOrigin={nestedTransformOrigin}
                    className={styles['nested-menu']}
                    iconFill={iconFill}
                >
                    {subMenu.map(createMenu)}
                </NestedMenuItem>
            );
        }

        if (onClick) {
            return (
                <MenuItem data-test={testId} key={index} onClick={handleClick} style={menuItemStyles}>
                    {labelWithIcon}
                    {selectedIcon}
                </MenuItem>
            );
        }

        return (
            <ListItem key={index} className={styles['nested-menu-item-title']} style={menuItemStyles}>
                {labelWithIcon}
            </ListItem>
        );
    };

    const nestedMenuItems = menuConfig.map(createMenu);

    return (
        <Menu
            className={styles['nested-menu']}
            id={id}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onClose}
        >
            {nestedMenuItems}
        </Menu>
    );
};
