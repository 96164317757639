import { ThemeOptions, createTheme } from '@material-ui/core';

// Create a theme instance.
export const lightThemeProps: ThemeOptions = {
    props: {
        MuiButton: {
            disableRipple: true,
            disableElevation: true,
        },
        MuiIconButton: {
            disableRipple: true,
        },
        MuiSwitch: {
            disableRipple: true,
        },
    },

    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: 12,
            },
        },
        MuiIconButton: {
            root: {
                borderRadius: '4px',
            },
        },
        MuiSwitch: {
            track: {
                opacity: 1,
                backgroundColor: 'var(--r-grey-3)',
            },
            thumb: {
                color: 'var(--r-catapult-white)',
                outline: '2px solid rgba(0, 0, 0, 0.2)',
            },
            switchBase: {
                '&:active': {
                    '& + $track': {
                        opacity: 0.68,
                    },
                },
                '&:hover': {
                    '& + $track': {
                        opacity: 0.92,
                    },
                },
                '&$checked:active': {
                    '& + $track': {
                        opacity: 0.68,
                    },
                },
                '&$checked:hover': {
                    '& + $track': {
                        opacity: 0.92,
                    },
                },
                '&$checked': {
                    '& + $track': {
                        backgroundColor: 'var(--r-catapult-orange)',
                        opacity: 1,
                    },
                },
            },
        },
        MuiChip: {
            colorPrimary: {
                backgroundColor: 'var(--r-orange-3)',
                color: 'var(--r-catapult-black)',
                '&:hover': {
                    backgroundColor: 'var(--r-orange-4)',
                },
            },
            deleteIconColorPrimary: {
                color: 'var(--r-catapult-black)',
                '&:hover': {
                    color: 'var(--r-catapult-black)',
                },
            },
            deleteIconSmall: {
                marginLeft: 0,
                marginRight: 8,
            },
            sizeSmall: {
                fontSize: '12px',
            },
        },
        MuiButton: {
            root: {
                borderRadius: '4px',
                fontSize: '14px',
                fontWeight: 700,
                letterSpacing: '2px',
                paddingLeft: '16px',
                paddingRight: '16px',
                transitionDuration: '250ms',
                transitionProperty: 'background-color',
                '&.Mui-focusVisible': {
                    outline: 'solid 4px var(--main-ltsfc-info-blue)',
                },
            },
            sizeLarge: {
                height: '44px',
            },
            sizeSmall: {
                fontSize: '14px',
                height: '28px',
                paddingLeft: '16px',
                paddingRight: '16px',
            },
            containedPrimary: {
                color: '#101010',
                '&:hover': {
                    backgroundColor: '#FF6314',
                },
                '&.Mui-focusVisible, &:active': {
                    backgroundColor: '#FF8B52',
                },
                '&$disabled': {
                    color: 'var(--r-grey-3)',
                    backgroundColor: 'var(--r-grey-5)',
                },
            },
            text: {
                '&:hover': {
                    backgroundColor: '#F1F1F1',
                },
                '&.Mui-focusVisible, &:active': {
                    backgroundColor: '#DEDEDE',
                },
                '&$disabled': {
                    color: '#858585',
                },
            },
            outlined: {
                '&:hover': {
                    backgroundColor: '#F1F1F1',
                },
                '&.Mui-focusVisible, &:active': {
                    backgroundColor: '#DEDEDE',
                },
                '&$disabled': {
                    color: '#858585',
                },
            },
        },
    },
    typography: {
        fontFamily: ['"Mark Pro"', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        fontSize: 14,
        h1: {
            fontSize: '48px',
            lineHeight: '60px',
            letterSpacing: '-0.5px',
            fontWeight: 'bold',
        },
        h2: {
            fontSize: '36px',
            lineHeight: '44px',
            letterSpacing: '-0.5px',
            fontWeight: 'bold',
        },
        h3: {
            fontSize: '24px',
            lineHeight: '32px',
            letterSpacing: '0px',
            fontWeight: 'bold',
        },
        h4: {
            fontSize: '20px',
            lineHeight: '28px',
            letterSpacing: '0px',
            fontWeight: 'bold',
        },
        h5: {
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0px',
            fontWeight: 'bold',
        },
        h6: {
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0px',
            fontWeight: 'bold',
        },
        body1: {
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0px',
            fontWeight: 'bold',
        },
        body2: {
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0px',
            fontWeight: 400,
        },
        caption: {
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '0px',
            fontWeight: 'normal',
        },
    },
    palette: {
        text: {
            // --r-catapult-black
            primary: '#101010',
        },
        primary: {
            // --r-catapult-orange
            main: '#FF5500',
        },
        secondary: {
            // --r-grey-4
            main: '#808080',
        },
        error: {
            // --r-main-ltsfc-alert-red
            main: '#e91616',
        },
        warning: {
            // --r-main-ltsfc-warning-yellow
            main: '#e39907',
        },
        info: {
            // --r-main-ltsfc-info-blue
            main: '#0074e0',
        },
        success: {
            // --r-main-dksfc-success-green
            light: '#5eb886',
            // --r-main-ltsfc-success-green
            main: '#3a825c',
            // --r-main-ltsfc-success-green-text
            dark: '#2a5f43',
        },
        type: 'light',
    },
};

const darkThemeProps: ThemeOptions = {
    ...lightThemeProps,
    palette: {
        text: {
            // --r-catapult-white
            primary: '#ffffff',
        },
        background: {
            // --r-catapult-black
            default: '#101010',
            // --r-grey-1
            paper: '#292f33',
        },
        primary: lightThemeProps.palette?.primary,
        secondary: lightThemeProps.palette?.secondary,
        error: {
            // --r-main-dksfc-alert-red
            main: '#ff6161',
        },
        warning: {
            // --r-main-dksfc-warning-yellow
            main: '#ffc758',
        },
        info: {
            // --r-main-dksfc-info-blue
            main: '#3ba1ff',
        },
        success: {
            // --r-main-dksfc-success-green
            light: '#5eb886',
            // --r-main-ltsfc-success-green
            main: '#3a825c',
            // --r-main-ltsfc-success-green-text
            dark: '#2a5f43',
        },
        type: 'dark',
    },
};

export const lightTheme = createTheme(lightThemeProps);
export const darkTheme = createTheme(darkThemeProps);
