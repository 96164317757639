export const capitalise = (s: unknown): string => {
    if (typeof s !== 'string') {
        return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const countMax = (count: number, max: number): string => {
    return count > max ? `${max}+` : `${count}`;
};

export const arrayToCsv = (data: string[][]): string => {
    return data
        .map(
            (row) =>
                row
                    .map(String) // convert every value to String
                    .map((v) => v.replaceAll('"', '""')) // escape double quotes
                    .map((v) => `"${v}"`) // quote value
                    .join(','), // comma-separated
        )
        .join('\r\n'); // rows starting on new lines
};

export const reverseName = (displayName: string): string => {
    if (!displayName) {
        return '';
    }

    const playerNameArray = displayName.toString().split(' ');

    if (playerNameArray.length > 1) {
        return playerNameArray.pop() + ', ' + playerNameArray.join(' ');
    }

    return displayName;
};

export const displayNameAsLastCommaFirst = (
    last_name: string,
    first_name: string,
    upper_case = false,
    first_inital = false,
): string => {
    let combinedName = `${last_name}, ${first_name}`;

    if (first_inital) {
        combinedName = `${last_name}, ${first_name.charAt(0)}`;
    }

    if (upper_case) {
        combinedName = combinedName.toUpperCase();
    }

    return combinedName;
};

export const isValidEmail = (email?: string): boolean => {
    return /\S+@\S+\.\S+/.test(email || '');
};
