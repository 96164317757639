import { useCallback, useEffect, useRef } from 'react';
import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';
import { TfActivity } from '../../api';
import { useContainerLayout } from 'shared/utils';

export const useLoadMoreObserver = (infiniteQuery: UseInfiniteQueryResult<InfiniteData<TfActivity[]>>) => {
    const handleObserver = useCallback(
        (entries: IntersectionObserverEntry[]) => {
            const [target] = entries;
            if (target.isIntersecting && infiniteQuery.hasNextPage) {
                infiniteQuery.fetchNextPage();
            }
        },
        [infiniteQuery],
    );
    const observerElem = useRef<HTMLDivElement>(null);

    // Handle observer for load more
    useEffect(() => {
        const element = observerElem.current;
        if (element) {
            const option = { threshold: 0 };

            const observer = new IntersectionObserver(handleObserver, option);
            observer.observe(element);
            return () => observer.unobserve(element);
        }
    }, [infiniteQuery.fetchNextPage, infiniteQuery.hasNextPage, handleObserver]);

    return { observerElem };
};

export const useActivityListHeightObserver = () => {
    const activityListRef = useRef<HTMLDivElement>(null);
    const sideMenuFooterRef = useRef<HTMLDivElement>(null);

    const activityListLayout = useContainerLayout(activityListRef);
    const sideMenuFooterLayout = useContainerLayout(sideMenuFooterRef);

    // handle activity list height
    useEffect(() => {
        if (!activityListLayout.screenTop || !sideMenuFooterLayout.screenTop || !activityListRef.current) {
            return;
        }
        const height = Math.max(0, sideMenuFooterLayout.screenTop - activityListLayout.screenTop);
        activityListRef.current.style.height = `${height}px`;
    }, [activityListLayout, sideMenuFooterLayout]);

    return { activityListRef, sideMenuFooterRef };
};
