import { useAuth } from './use-auth';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { Configuration } from 'oas';
import * as React from 'react';
import { useSnackbar } from 'shared';

export const useApiConfig = (apiVersion: string): { apiConfig: Configuration; axiosInstance: AxiosInstance } => {
    const apiConfig = new Configuration({ basePath: process.env.REACT_APP_API_HOST + '/' + apiVersion });
    const { errorSnackbar } = useSnackbar();

    const auth = useAuth();
    const axiosInstance: AxiosInstance = React.useMemo(() => {
        const newAxiosInstance = axios.create();

        if (auth.user) {
            newAxiosInstance.interceptors.response.use(
                (response: AxiosResponse) => response,
                async (error: AxiosError) => {
                    if (error.response) {
                        const status = error.response?.status;

                        // eslint-disable-next-line no-console
                        console.debug('interceptor status', status);

                        if (status === 401) {
                            auth.logout();
                        } else if (status >= 500 && status <= 599) {
                            const message = error.response.data.message;
                            errorSnackbar(message || 'Unknown error');
                        }
                    }

                    return error;
                },
            );
        }

        return newAxiosInstance;
    }, [auth, errorSnackbar]);

    return { apiConfig, axiosInstance };
};
