import * as React from 'react';
import { Box, DrawerProps, Link, MenuItem, Tooltip, Typography } from '@material-ui/core';
import {
    KEY_NOTIFICATIONS_SHOW_UNREAD_ONLY,
    NotificationFilterCategory,
    TfNotification,
    useGetNotifications,
    useGetSetting,
    useUpdateNotifications,
} from 'api';
import { ReactComponent as CloseIcon } from 'assets/icons/16px/cancel.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/16px/settings.svg';
import { useTranslations } from 'i18n';
import mixpanel from 'mixpanel-browser';
import { useUnreadNotificationsCount } from 'notifications';
import { NotificationTypeEnum } from 'oas';
import { Scrollbars } from 'react-custom-scrollbars';
import { Drawer, IconButton, Select } from 'shared';
import styles from './notifications-drawer.module.css';
import { NotificationsList } from './notifications-list';
import { SettingsDrawer } from './settings-drawer';
import { Spinner } from '@SBGSports/referee-react';

interface NotificationsDrawerProps extends DrawerProps {
    onClose: () => void;
}

type TypeFilter = NotificationFilterCategory | '';

export const NotificationsDrawer: React.FC<NotificationsDrawerProps> = (props) => {
    const { onClose, open } = props;
    const { __ } = useTranslations();
    const [isSettingsOpen, setIsSettingsOpen] = React.useState(false);
    const unreadOnlySetting = useGetSetting(KEY_NOTIFICATIONS_SHOW_UNREAD_ONLY);
    const { mutate: updateNotifications } = useUpdateNotifications();
    const { data: notifications } = useGetNotifications();
    const unreadNotificationCount = useUnreadNotificationsCount();
    const [filterValue, setFilterValue] = React.useState<TypeFilter>('');
    const [filteredNotifications, setFilteredNotifications] = React.useState<TfNotification[] | undefined>();

    const handleMarkAllAsRead = React.useCallback(() => {
        if (!filteredNotifications) {
            return;
        }

        updateNotifications({ ids: filteredNotifications.map((notification) => notification.id), read: true });
        mixpanel.track('BellNotificationPanelMarkAllAsReadClicked');
    }, [filteredNotifications, updateNotifications]);

    const handleFilterChange = React.useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        setFilterValue(event.target.value as TypeFilter);
        mixpanel.track('BellNotificationPanelFilterUpdated', { filterValue: event.target.value });
    }, []);

    const toggleSettings = React.useCallback(() => {
        setIsSettingsOpen(!isSettingsOpen);
    }, [isSettingsOpen]);

    const handleClose = React.useCallback(() => {
        onClose();
    }, [onClose]);

    React.useEffect(() => {
        const unreadOnly = unreadOnlySetting?.value === '1';
        setFilteredNotifications(
            notifications
                ?.filter((notification) => {
                    if (filterValue && notification.typeFilter && notification.typeFilter !== filterValue) {
                        return false;
                    }
                    return (
                        !unreadOnly ||
                        notification.type === NotificationTypeEnum.Info ||
                        (unreadOnly && !notification.read)
                    );
                })
                // Apply secondary sorting by name and notification type so order is always the same.
                // This matters because info notifications confirming updates must appear in the same
                // position as the original notification.
                .sort((n1, n2) => {
                    let ret = n1.created_at.localeCompare(n2.created_at);
                    if (ret !== 0) {
                        return -1 * ret;
                    }

                    if (n1.athletes?.length && n2.athletes?.length) {
                        ret = n1.athletes[0].last_name.localeCompare(n2.athletes[0].last_name);
                        if (ret !== 0) {
                            return ret;
                        }

                        ret = n1.athletes[0].first_name.localeCompare(n2.athletes[0].first_name);
                        if (ret !== 0) {
                            return ret;
                        }

                        ret = n1.type.localeCompare(n2.type);
                        if (ret !== 0) {
                            return ret;
                        }
                    }

                    return 0;
                }),
        );
    }, [unreadOnlySetting, filterValue, notifications]);

    React.useEffect(() => {
        if (open) {
            const ids = notifications
                ?.filter((notification) => !notification.notified)
                .map((notification) => notification.id);
            if (ids?.length) {
                updateNotifications({
                    ids,
                    notified: true,
                });
            }
        } else {
            setIsSettingsOpen(false);
        }
    }, [open, notifications, updateNotifications]);

    return (
        <>
            <Drawer variant="persistent" anchor="right" open={open}>
                <Box width={500} position="relative" display="flex" flexDirection="column" height="100%">
                    <div className={styles['close-button']}>
                        <IconButton aria-label="close" onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>

                    <Box m={3}>
                        <Typography variant="h3" className="notifications-drawer-title">
                            {__('notifications.notifications')}{' '}
                            <Tooltip title={__('misc.settings')}>
                                <IconButton
                                    onClick={toggleSettings}
                                    style={{ margin: '-8px 0' }}
                                    id="notifications-settings-button"
                                >
                                    <SettingsIcon fill="#101010" />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Box>
                    <Box mx={3} mb={3} display="flex" alignItems="center">
                        <>
                            <Box minWidth="50%" mr={3}>
                                <Select
                                    id="notification-type-filter"
                                    value={filterValue}
                                    onChange={handleFilterChange}
                                    size="small"
                                    fullWidth
                                    displayEmpty
                                >
                                    <MenuItem value="">{__('notifications.all_notifications')}</MenuItem>
                                    <MenuItem value={NotificationFilterCategory.Account}>
                                        {__('notifications.account_notifications')}
                                    </MenuItem>
                                    <MenuItem value={NotificationFilterCategory.Activity}>
                                        {__('notifications.activity_notifications')}
                                    </MenuItem>
                                </Select>
                            </Box>
                            <Box ml="auto">
                                {unreadNotificationCount > 0 ? (
                                    <Link
                                        id="notifications-mark-all-as-read"
                                        onClick={handleMarkAllAsRead}
                                        variant="body1"
                                        color="textSecondary"
                                        underline="always"
                                        style={{ whiteSpace: 'nowrap' }}
                                    >
                                        {__('notifications.mark_all_as_read')}
                                    </Link>
                                ) : null}
                            </Box>
                        </>
                    </Box>
                    {filteredNotifications ? (
                        filteredNotifications.length ? (
                            <Box flexGrow={1} mt={-1}>
                                <Scrollbars
                                    style={{ height: '100%' }}
                                    // hack to hide horizontal scrollbar
                                    renderThumbHorizontal={() => <div />}
                                >
                                    <Box pt={1}>
                                        <NotificationsList notifications={filteredNotifications} />
                                    </Box>
                                </Scrollbars>
                            </Box>
                        ) : (
                            <Box mx={3}>
                                {notifications?.length
                                    ? __('notifications.no_visible_notifications')
                                    : __('notifications.no_notifications')}
                            </Box>
                        )
                    ) : (
                        <Box display="flex" justifyContent="center">
                            <Spinner />
                        </Box>
                    )}
                </Box>
            </Drawer>
            <SettingsDrawer open={open && isSettingsOpen} onBack={toggleSettings} onClose={handleClose} />
        </>
    );
};
