import { SelectOption } from '@SBGSports/referee-react';
import { REPORTING } from 'reporting/constants';
export const ZENDESK_WIDGET_ZINDEX = 1301;
export const INTERVAL_MIGRATION_PULL = 30 * 1000;

export const NOTIFICATION_REFRESH_INTERVAL = 30 * 1000;

export const NOTIFICATION_HELP_LINK_VECTOR_PLUS = 'https://support.catapultsports.com/hc/en-us/articles/4411569932559';
export const NOTIFICATION_HELP_LINK_VECTOR_CORE =
    'https://core.catapultsports.com/hc/en-us/articles/7209599224207-OpenField-Notifications';

export const DATE_FNS_CASUAL_DATE_TIME_FORMAT = 'PPp';
export const DATE_FNS_CASUAL_TIME_FORMAT = 'p';

export const DATE_FNS_CASUAL_DATE_FORMAT = 'PP';

export const DATE_FNS_ACTIVITY_EDITOR_DATETIME_FORMAT = 'E, LLL d, yyyy, h:mm a ';
export const DATE_FNS_ACTIVITY_EDITOR_TIME_FORMAT = 'h:mm a ';
export const DATE_FNS_ACTIVITY_EDITOR_PERIOD_TIME_FORMAT = 'h:mm:ss a';

export const DATE_FNS_SHORT_DATE_FORMAT = 'EEE. MMM. d, yyyy';
export const DATE_FNS_LONG_DATE_TIME_FORMAT = 'EEEE, MMMM d, yyyy - hh:mm:ss a';
export const DATE_FNS_LONG_DATE_FORMAT = 'EEEE, MMMM d, yyyy';
export const DATE_FNS_SHORT_DATE_TIME_FORMAT = 'EEE. MMM. d, yyyy - hh:mm a';
export const DATE_TABLE_SHORT_DATE_FORMAT = 'ddd. MMM. D';
export const DATE_FNS_HEADER_SHORT_DATE_FORMAT = 'EEEE. MMMM. d, yyyy';
export const DATE_FNS_DAILY_BREAKDOWN = 'EEE. MMM. d';

export const DATE_FNS_DD_MM_YYYY_FORMAT = 'dd/MM/yyyy';
export const DATE_FNS_MM_DD_YYYY_FORMAT = 'MM/dd/yyyy';
export const DATE_FNS_GLOBAL_FORMAT = 'yyyy-MM-dd';

export const ACTIVITY_MAX_DURATION_HOURS = 24;
export const PERIOD_MAX_DURATION_HOURS = 24;
export const PERIOD_MIN_DURATION_SECONDS = 1;
export const PERIOD_NAME_REGEX = /^[^<>|*?:\\/\"]*$/;

export const DEFAULT_THRESHOLD_PARAMETERS = [
    'total_distance',
    'high_speed_dist',
    'very_high_speed_dist',
    'high_speed_eff',
    'very_high_speed_eff',
    'player_load',
    'high_pl',
    'acc_3_eff',
    'dec_3_eff',
];

export const THRESHOLD_PARAMETERS = [
    'max_vel',
    'total_distance',
    'player_load',
    'field_time',
    'dist_per_min',
    'pl_per_min',
    'max_hr',
    'mean_hr',
    'hr_duration_band1',
    'hr_duration_band2',
    'hr_duration_band3',
    'hr_duration_band4',
    'hr_duration_band5',
    'hr_duration_band6',
    'hr_duration_band7',
    'hr_duration_band8',
    'percentage_hr_max',
    'red_zone_minutes',
    'percentage_max_vel',
    'velocity_distance_band1',
    'velocity_distance_band2',
    'velocity_distance_band3',
    'velocity_distance_band4',
    'velocity_distance_band5',
    'velocity_distance_band6',
    'velocity_distance_band7',
    'velocity_distance_band8',
    'velocity_duration_band1',
    'velocity_duration_band2',
    'velocity_duration_band3',
    'velocity_duration_band4',
    'velocity_duration_band5',
    'velocity_duration_band6',
    'velocity_duration_band7',
    'velocity_duration_band8',
    'velocity_effort_band2',
    'velocity_effort_band3',
    'velocity_effort_band4',
    'velocity_effort_band5',
    'velocity_effort_band6',
    'velocity_effort_band7',
    'velocity_effort_band8',
    'velocity_distance_set2_band1',
    'velocity_distance_set2_band2',
    'velocity_distance_set2_band3',
    'velocity_distance_set2_band4',
    'velocity_distance_set2_band5',
    'velocity_distance_set2_band6',
    'velocity_distance_set2_band7',
    'velocity_distance_set2_band8',
    'velocity_effort_set2_band2',
    'velocity_effort_set2_band3',
    'velocity_effort_set2_band4',
    'velocity_effort_set2_band5',
    'velocity_effort_set2_band6',
    'velocity_effort_set2_band7',
    'velocity_effort_set2_band8',
    'dec_1_eff',
    'dec_2_eff',
    'dec_3_eff',
    'acc_1_eff',
    'acc_2_eff',
    'acc_3_eff',
    'acc_1_to_3_efforts',
    'acc_2_to_3_efforts',
    'dec_1_to_3_efforts',
    'dec_2_to_3_efforts',
    'high_speed_duration',
    'high_speed_duration_percentage',
    'high_speed_dist',
    'high_speed_dist_percentage',
    'high_speed_eff',
    'high_speed_eff_percentage',
    'high_pl_duration',
    'high_pl_duration_percentage',
    'high_pl',
    'high_pl_percentage',
    'very_high_speed_duration',
    'very_high_speed_duration_percentage',
    'very_high_speed_dist',
    'very_high_speed_dist_percentage',
    'very_high_speed_eff',
    'very_high_speed_eff_percentage',
    'very_high_pl_duration',
    'very_high_pl_duration_percentage',
    'very_high_pl',
    'very_high_pl_percentage',
    'goalie_load',
    'goalie_load_per_min',
    'goalie_down_count',
    'fmp_very_low_duration',
    'fmp_very_low_duration_percentage',
    'fmp_low_duration',
    'fmp_low_duration_percentage',
    'fmp_run_med_duration',
    'fmp_run_med_duration_percentage',
    'fmp_run_high_duration',
    'fmp_run_high_duration_percentage',
    'fmp_dynamic_med_duration',
    'fmp_dynamic_med_duration_percentage',
    'fmp_dynamic_high_duration',
    'fmp_dynamic_high_duration_percentage',
    'fmp_total_run_duration',
    'fmp_total_run_duration_percentage',
    'fmp_total_dynamic_duration',
    'fmp_total_dynamic_duration_percentage',
    'metabolic_power_band1_total_distance',
    'metabolic_power_band2_total_distance',
    'metabolic_power_band3_total_distance',
    'metabolic_power_band4_total_distance',
    'metabolic_power_band5_total_distance',
    'metabolic_power_band6_total_distance',
    'metabolic_power_band7_total_distance',
    'metabolic_power_band8_total_distance',
    'metabolic_power_band1_distance_percentage',
    'metabolic_power_band2_distance_percentage',
    'metabolic_power_band3_distance_percentage',
    'metabolic_power_band4_distance_percentage',
    'metabolic_power_band5_distance_percentage',
    'metabolic_power_band6_distance_percentage',
    'metabolic_power_band7_distance_percentage',
    'metabolic_power_band8_distance_percentage',
    'metabolic_power_band1_total_duration',
    'metabolic_power_band2_total_duration',
    'metabolic_power_band3_total_duration',
    'metabolic_power_band4_total_duration',
    'metabolic_power_band5_total_duration',
    'metabolic_power_band6_total_duration',
    'metabolic_power_band7_total_duration',
    'metabolic_power_band8_total_duration',
    'metabolic_power_band1_duration_percentage',
    'metabolic_power_band2_duration_percentage',
    'metabolic_power_band3_duration_percentage',
    'metabolic_power_band4_duration_percentage',
    'metabolic_power_band5_duration_percentage',
    'metabolic_power_band6_duration_percentage',
    'metabolic_power_band7_duration_percentage',
    'metabolic_power_band8_duration_percentage',
    'hmld_gen2',
    'hmld_per_minute_gen2',
    'peak_meta_power',
    'average_metabolic_power',
    'equivalent_distance',
    'equivalent_distance_index',
];

export const THRESHOLD_PARAMETERS_TYPE: Record<string, string> = {
    max_vel: 'basic',
    total_distance: 'basic',
    player_load: 'basic',
    field_time: 'basic',
    dist_per_min: 'basic',
    pl_per_min: 'basic',
    max_hr: 'heart_rate',
    mean_hr: 'heart_rate',
    hr_duration_band1: 'heart_rate',
    hr_duration_band2: 'heart_rate',
    hr_duration_band3: 'heart_rate',
    hr_duration_band4: 'heart_rate',
    hr_duration_band5: 'heart_rate',
    hr_duration_band6: 'heart_rate',
    hr_duration_band7: 'heart_rate',
    hr_duration_band8: 'heart_rate',
    percentage_hr_max: 'heart_rate',
    red_zone_minutes: 'heart_rate',
    percentage_max_vel: 'velocity',
    velocity_distance_band1: 'velocity',
    velocity_distance_band2: 'velocity',
    velocity_distance_band3: 'velocity',
    velocity_distance_band4: 'velocity',
    velocity_distance_band5: 'velocity',
    velocity_distance_band6: 'velocity',
    velocity_distance_band7: 'velocity',
    velocity_distance_band8: 'velocity',
    velocity_duration_band1: 'velocity',
    velocity_duration_band2: 'velocity',
    velocity_duration_band3: 'velocity',
    velocity_duration_band4: 'velocity',
    velocity_duration_band5: 'velocity',
    velocity_duration_band6: 'velocity',
    velocity_duration_band7: 'velocity',
    velocity_duration_band8: 'velocity',
    velocity_effort_band2: 'velocity',
    velocity_effort_band3: 'velocity',
    velocity_effort_band4: 'velocity',
    velocity_effort_band5: 'velocity',
    velocity_effort_band6: 'velocity',
    velocity_effort_band7: 'velocity',
    velocity_effort_band8: 'velocity',
    velocity_distance_set2_band1: 'velocity',
    velocity_distance_set2_band2: 'velocity',
    velocity_distance_set2_band3: 'velocity',
    velocity_distance_set2_band4: 'velocity',
    velocity_distance_set2_band5: 'velocity',
    velocity_distance_set2_band6: 'velocity',
    velocity_distance_set2_band7: 'velocity',
    velocity_distance_set2_band8: 'velocity',
    velocity_effort_set2_band2: 'velocity',
    velocity_effort_set2_band3: 'velocity',
    velocity_effort_set2_band4: 'velocity',
    velocity_effort_set2_band5: 'velocity',
    velocity_effort_set2_band6: 'velocity',
    velocity_effort_set2_band7: 'velocity',
    velocity_effort_set2_band8: 'velocity',
    dec_1_eff: 'acceleration',
    dec_2_eff: 'acceleration',
    dec_3_eff: 'acceleration',
    acc_1_eff: 'acceleration',
    acc_2_eff: 'acceleration',
    acc_3_eff: 'acceleration',
    acc_1_to_3_efforts: 'acceleration',
    acc_2_to_3_efforts: 'acceleration',
    dec_1_to_3_efforts: 'acceleration',
    dec_2_to_3_efforts: 'acceleration',
    high_speed_duration: 'custom',
    high_speed_duration_percentage: 'custom',
    high_speed_dist: 'custom',
    high_speed_dist_percentage: 'custom',
    high_speed_eff: 'custom',
    high_speed_eff_percentage: 'custom',
    high_pl_duration: 'custom',
    high_pl_duration_percentage: 'custom',
    high_pl: 'custom',
    high_pl_percentage: 'custom',
    very_high_speed_duration: 'custom',
    very_high_speed_duration_percentage: 'custom',
    very_high_speed_dist: 'custom',
    very_high_speed_dist_percentage: 'custom',
    very_high_speed_eff: 'custom',
    very_high_speed_eff_percentage: 'custom',
    very_high_pl_duration: 'custom',
    very_high_pl_duration_percentage: 'custom',
    very_high_pl: 'custom',
    very_high_pl_percentage: 'custom',
    goalie_load: 'ice_hockey_goalie',
    goalie_load_per_min: 'ice_hockey_goalie',
    goalie_down_count: 'ice_hockey_goalie',
    fmp_very_low_duration: 'football_movement_profile',
    fmp_very_low_duration_percentage: 'football_movement_profile',
    fmp_low_duration: 'football_movement_profile',
    fmp_low_duration_percentage: 'football_movement_profile',
    fmp_run_med_duration: 'football_movement_profile',
    fmp_run_med_duration_percentage: 'football_movement_profile',
    fmp_run_high_duration: 'football_movement_profile',
    fmp_run_high_duration_percentage: 'football_movement_profile',
    fmp_dynamic_med_duration: 'football_movement_profile',
    fmp_dynamic_med_duration_percentage: 'football_movement_profile',
    fmp_dynamic_high_duration: 'football_movement_profile',
    fmp_dynamic_high_duration_percentage: 'football_movement_profile',
    fmp_total_run_duration: 'football_movement_profile',
    fmp_total_run_duration_percentage: 'football_movement_profile',
    fmp_total_dynamic_duration: 'football_movement_profile',
    fmp_total_dynamic_duration_percentage: 'football_movement_profile',
    metabolic_power_band1_total_distance: 'metabolic_power',
    metabolic_power_band2_total_distance: 'metabolic_power',
    metabolic_power_band3_total_distance: 'metabolic_power',
    metabolic_power_band4_total_distance: 'metabolic_power',
    metabolic_power_band5_total_distance: 'metabolic_power',
    metabolic_power_band6_total_distance: 'metabolic_power',
    metabolic_power_band7_total_distance: 'metabolic_power',
    metabolic_power_band8_total_distance: 'metabolic_power',
    metabolic_power_band1_distance_percentage: 'metabolic_power',
    metabolic_power_band2_distance_percentage: 'metabolic_power',
    metabolic_power_band3_distance_percentage: 'metabolic_power',
    metabolic_power_band4_distance_percentage: 'metabolic_power',
    metabolic_power_band5_distance_percentage: 'metabolic_power',
    metabolic_power_band6_distance_percentage: 'metabolic_power',
    metabolic_power_band7_distance_percentage: 'metabolic_power',
    metabolic_power_band8_distance_percentage: 'metabolic_power',
    metabolic_power_band1_total_duration: 'metabolic_power',
    metabolic_power_band2_total_duration: 'metabolic_power',
    metabolic_power_band3_total_duration: 'metabolic_power',
    metabolic_power_band4_total_duration: 'metabolic_power',
    metabolic_power_band5_total_duration: 'metabolic_power',
    metabolic_power_band6_total_duration: 'metabolic_power',
    metabolic_power_band7_total_duration: 'metabolic_power',
    metabolic_power_band8_total_duration: 'metabolic_power',
    metabolic_power_band1_duration_percentage: 'metabolic_power',
    metabolic_power_band2_duration_percentage: 'metabolic_power',
    metabolic_power_band3_duration_percentage: 'metabolic_power',
    metabolic_power_band4_duration_percentage: 'metabolic_power',
    metabolic_power_band5_duration_percentage: 'metabolic_power',
    metabolic_power_band6_duration_percentage: 'metabolic_power',
    metabolic_power_band7_duration_percentage: 'metabolic_power',
    metabolic_power_band8_duration_percentage: 'metabolic_power',
    hmld_gen2: 'metabolic_power',
    hmld_per_minute_gen2: 'metabolic_power',
    peak_meta_power: 'metabolic_power',
    average_metabolic_power: 'metabolic_power',
    equivalent_distance: 'metabolic_power',
    equivalent_distance_index: 'metabolic_power',
};
// Expects the system average set to start with "SYSTEM AVERAGE - COMPARE TO" have some text in between and end with (Do Not Edit)
export const SYSTEM_AVERAGE_SET_REGEX = '^SYSTEM AVERAGE - COMPARE TO [\\w-]+ \\w+$';
export const ACTIVITY_TEAM_MD_AVERAGE_SET_NAME = 'SYSTEM AVERAGE - COMPARE TO MD TEAM';
export const ACTIVITY_ATHLETE_MD_AVERAGE_SET_NAME = 'SYSTEM AVERAGE - COMPARE TO MD ATHLETE';
export const ACTIVITY_POSITION_MD_AVERAGE_SET_NAME = 'SYSTEM AVERAGE - COMPARE TO MD POSITION';
export const REPORTING_PARAMETER_GROUP_NAME = 'L3_SOLUTION_[DO_NOT_EDIT]';
export const REPORTING_PARAMETER_GROUP_NEW_NAME = 'Reporting_Parameters';

export const POSITION_SORT_ORDER = ['GK', 'CD', 'FB', 'CM', 'WM', 'FW'];

export const DAY_CODE_TAG_TYPE = 'DayCode';
export const PARTICIPATION_TAG_TYPE = 'Participation';
export const REPORTING_PARAMETERS_TYPE: Record<string, string> = {
    [REPORTING.accel_and_decel_efforts]: 'Acceleration',
    [REPORTING.accel_and_decel_efforts_per_minute]: 'Acceleration',
    [REPORTING.acceleration_efforts]: 'Acceleration',
    [REPORTING.deceleration_efforts]: 'Acceleration',
    [REPORTING.max_acceleration]: 'Acceleration',
    [REPORTING.max_deceleration]: 'Acceleration',
    [REPORTING.impacts]: 'Common',
    [REPORTING.max_velocity_percentage]: 'Common',
    [REPORTING.max_velocity]: 'Common',
    [REPORTING.meterage_per_minute]: 'Common',
    [REPORTING.player_load_per_min]: 'Common',
    [REPORTING.distance]: 'Common',
    [REPORTING.duration]: 'Common',
    [REPORTING.player_load]: 'Common',
    [REPORTING.work_rest_ratio]: 'Common',
    [REPORTING.average_heart_rate]: 'Heart Rate',
    [REPORTING.average_heart_rate_percentage]: 'Heart Rate',
    [REPORTING.heart_rate_band_1_duration]: 'Heart Rate',
    [REPORTING.heart_rate_band_2_duration]: 'Heart Rate',
    [REPORTING.heart_rate_band_3_duration]: 'Heart Rate',
    [REPORTING.heart_rate_band_4_duration]: 'Heart Rate',
    [REPORTING.heart_rate_band_5_duration]: 'Heart Rate',
    [REPORTING.heart_rate_band_6_duration]: 'Heart Rate',
    [REPORTING.max_heart_rate_percentage]: 'Heart Rate',
    [REPORTING.max_heart_rate]: 'Heart Rate',
    [REPORTING.red_zone]: 'Heart Rate',
    [REPORTING.heart_rate_exertion]: 'Heart Rate',
    [REPORTING.energy]: 'Metabolic Power',
    [REPORTING.high_metabolic_load_distance]: 'Metabolic Power',
    [REPORTING.high_speed_distance]: 'Velocity',
    [REPORTING.high_speed_distance_per_min]: 'Velocity',
    [REPORTING.sprint_distance_per_minute]: 'Velocity',
    [REPORTING.high_speed_efforts]: 'Velocity',
    [REPORTING.sprint_efforts]: 'Velocity',
    [REPORTING.standing_distance]: 'Velocity',
    [REPORTING.walking_distance]: 'Velocity',
    [REPORTING.jogging_distance]: 'Velocity',
    [REPORTING.running_distance]: 'Velocity',
    [REPORTING.high_intensity_distance]: 'Velocity',
    [REPORTING.sprint_distance]: 'Velocity',
};

export const ACTIVITY_BENCHMARKS_PARAMETERS = [
    'activity_id',
    'athlete_id',
    'period_id',
    'position_id',
    'team_id',
    'date',
    'start_time',
    ...Object.keys(REPORTING_PARAMETERS_TYPE),
];

export const white = 'var(--r-catapult-white)';
export const teal = 'var(--r-teal)';
export const tealShade = 'var(--r-teal-shade)';
export const slate = 'var(--r-slate)';
export const slateShade = 'var(--r-slate-shade)';
export const violet = 'var(--r-violet)';
export const violetShade = 'var(--r-violet-shade)';
export const blue = 'var(--r-blue)';
export const blueShade = 'var(--r-blue-shade)';
export const catapultOrange = 'var(--r-catapult-orange)';
export const darkOrange = '#CE4501';

export const DATE_FORMAT = [
    {
        id: 'Global',
        value: 'DD/MM/YYYY',
    },
    {
        id: 'American',
        value: 'MM/DD/YYYY',
    },
] as SelectOption[];

export const CSV_SEPARATOR = [
    {
        id: 'semicolon',
        value: ';',
    },
    {
        id: 'comma',
        value: ',',
    },
] as SelectOption[];

export const SPORTS_PLUGINS = [
    {
        id: 'Cricket',
        value: 'Cricket',
    },
    {
        id: 'FootballImpacts',
        value: 'FootballImpacts',
    },
    {
        id: 'FootballStrikes',
        value: 'FootballStrikes',
    },
    {
        id: 'GK.V2',
        value: 'GK.V2',
    },
    {
        id: 'Goalkeeping',
        value: 'Goalkeeping',
    },
    {
        id: 'Hockey',
        value: 'Hockey',
    },
    {
        id: 'Impacts',
        value: 'Impacts',
    },
    {
        id: 'LinemanContact',
        value: 'LinemanContact',
    },
    {
        id: 'MovementMetrics',
        value: 'MovementMetrics',
    },
    {
        id: 'Rugby',
        value: 'Rugby',
    },
    {
        id: 'RugbyTackles',
        value: 'RugbyTackles',
    },
] as SelectOption[];

export const ONE_HOUR = 3600000;

export const MILLISECONDS = 1000;

export const CATAPULT_BLACK_HEX = '#101010';
