import { Annotation } from 'oas/internal';
import { TfAnnotation } from './type';

export const transformAnnotation = (annotation: Annotation): TfAnnotation => ({
    id: annotation.id || '',
    name: annotation.name || '',
    startDateTime: new Date(annotation.start_time_ms || 0),
    endDateTime: new Date(annotation.end_time_ms || 0),
    duration: ((annotation.end_time_ms || 0) - (annotation.start_time_ms || 0)) / 1000,
    athleteCount: annotation?.athlete_ids?.length || 0, // This is a bug, should be annotation.athlete_ids.length
    athleteIds: annotation?.athlete_ids || [], // This is a bug, should be annotation.athlete_ids
});
