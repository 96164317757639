import * as React from 'react';
import { useGetTranslations } from 'api/translations';
import { addQueryToUrl, usePrevious } from 'shared';
import clsx from 'clsx';
import styles from './legacy-iframe.module.css';

type LegacyIframeProps = {
    path: string;
    fullHeight?: boolean;
};

const getSrc = (path: string, query: Record<string, string> = {}): string =>
    addQueryToUrl(`${process.env.REACT_APP_AUTH_HOST}${path}`, { ...query });

export const LegacyIframe: React.FC<LegacyIframeProps> = (props) => {
    const { path, fullHeight = false } = props;

    const [actualSrc, setActualSrc] = React.useState(getSrc(path));
    const { data: translations } = useGetTranslations();
    const iframeRef = React.useRef<HTMLIFrameElement>(null);
    const prevTranslations = usePrevious(translations);

    React.useEffect(() => {
        setActualSrc(getSrc(path));
    }, [path]);

    // Reload when language changes
    React.useEffect(() => {
        if (prevTranslations !== undefined && prevTranslations !== translations && iframeRef.current) {
            setActualSrc(`${getSrc(path, { [Math.random()]: '1' })}`);
        }
    }, [prevTranslations, translations, path, setActualSrc]);

    return (
        <div className={clsx(styles['legacy-iframe-container'], { [styles['full-height']]: fullHeight })}>
            <iframe
                id="legacy-iframe"
                className={styles['legacy-iframe']}
                src={actualSrc}
                title={path}
                ref={iframeRef}
            />
        </div>
    );
};
