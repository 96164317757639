import { useDeleteNotifications } from 'api';
import * as React from 'react';
import { Alert } from 'shared';
import { NotificationTypeProps } from './notification-type-props';

const DISMISS_AFTER_MS = 10000;

export const Info: React.FC<NotificationTypeProps> = (props) => {
    const { notification } = props;
    const { mutate: deleteNotifications } = useDeleteNotifications();
    const handleDismiss = React.useCallback(() => {
        deleteNotifications([notification.id]);
    }, [notification, deleteNotifications]);
    const timerSet = React.useRef(false);

    React.useEffect(() => {
        if (!timerSet.current) {
            timerSet.current = true;
            setTimeout(handleDismiss, DISMISS_AFTER_MS);
        }
    }, [handleDismiss]);

    return <Alert onDismiss={handleDismiss}>{notification.payload?.message}</Alert>;
};
