import { Drawer as MuiDrawer, DrawerProps } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = () =>
    createStyles({
        root: {
            padding: 0,
        },
        paperAnchorRight: {
            paddingTop: 74,
            zIndex: 1200,
        },
    });

export const Drawer = withStyles(styles)((props: DrawerProps) => {
    return <MuiDrawer PaperProps={{ elevation: 10 }} {...props} />;
});
