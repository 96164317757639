import { ModulesApi } from 'oas';
import { useApiQuery } from '../use-api-query';
import { UseQueryResult } from '@tanstack/react-query';

export const useGetModuleNames = (
    ...parameters: Parameters<typeof ModulesApi.prototype.getModuleNames>
): UseQueryResult<string[]> => {
    return useApiQuery(['module-names', parameters], (apiConfig, axiosInstance) => {
        return new ModulesApi(apiConfig, '', axiosInstance).getModuleNames(...parameters);
    });
};
/**
 * Get customer access to specific module
 *
 * Don't use these function to hide/show customer feature, use useHasFeature instead.
 * The list of current features, refer to: FrontendConfigFeaturesEnum
 *
 * @param moduleName
 * @returns
 */
export const useHasModule = (moduleName: string): boolean | null => {
    const { data: modules, isLoading } = useGetModuleNames();

    return isLoading
        ? null
        : modules?.map((module) => module.toLowerCase()).includes(moduleName.toLowerCase()) || false;
};
