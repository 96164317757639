import * as React from 'react';
import { debounce } from 'lodash';

export function useDebounce<T = unknown>(callback: (...args: T[]) => void, delay: number) {
    const ref = React.useRef<(...args: T[]) => void>();

    React.useEffect(() => {
        ref.current = callback;
    }, [callback]);

    const debouncedCallback = React.useMemo(() => {
        const func = (...args: T[]) => {
            ref.current?.(...args);
        };

        return debounce(func, delay);
    }, [delay]);

    return debouncedCallback;
}
