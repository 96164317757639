import { confirm as libConfirm, ConfirmModalProps } from '@rsuite/interactions';
import { Translator } from 'i18n';

export function confirm(
    __: Translator,
    message?: React.ReactNode,
    modalConfig?: ConfirmModalProps | undefined,
): Promise<boolean> {
    return libConfirm(message, {
        okButtonText: __('misc.confirm_yes'),
        cancelButtonText: __('misc.confirm_no'),
        ...modalConfig,
    });
}
