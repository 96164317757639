import * as React from 'react';
import { LinearProgress } from '@material-ui/core';
import { useAuth } from 'auth';
import { useIframeListener } from 'iframing';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

export const BaseLayout: React.FC<React.PropsWithChildren<{ isLoading?: boolean; dark?: boolean }>> = ({
    children,
    isLoading = false,
    dark = false,
}) => {
    const iframeLocationEvent = useIframeListener('__iframe_location');
    const history = useHistory();
    const auth = useAuth();

    React.useEffect(() => {
        switch (iframeLocationEvent?.payload) {
            case 'header':
                history.push('/');
                break;
            case 'login':
                auth.logout();
                break;
            default:
                if (
                    iframeLocationEvent?.payload.match(/settings\/activities\/[^\/]+$/) ||
                    iframeLocationEvent?.payload.match(/settings\/athletes\/[^\/]+$/)
                ) {
                    window.location.href = iframeLocationEvent.payload;
                }
        }
    }, [iframeLocationEvent, history, auth]);

    return (
        <>
            <Helmet>
                <style>{`body { background-color: ${
                    isLoading || dark ? 'var(--r-catapult-black)' : 'var(--r-catapult-white)'
                } }`}</style>
            </Helmet>
            {isLoading ? <LinearProgress /> : <>{children}</>}
        </>
    );
};
