import axios from 'axios';
import { EntityTagRequestObject, EntityTagsApi } from 'oas';
import { UseMutationResult } from '@tanstack/react-query';
import { useApiMutation } from '../use-api-mutation';

export const useCreateEntityTags = (): UseMutationResult<void, unknown, EntityTagRequestObject[]> => {
    return useApiMutation(async (attr, apiConfig, axiosInstance) => {
        const response = await new EntityTagsApi(apiConfig, '', axiosInstance).createEntityTag(attr);

        if (axios.isAxiosError(response)) {
            throw response;
        }
    });
};

export const useDeleteEntityTags = (): UseMutationResult<void, unknown, EntityTagRequestObject[]> => {
    return useApiMutation(async (attr, apiConfig, axiosInstance) => {
        const response = await new EntityTagsApi(apiConfig, '', axiosInstance).deleteEntityTagBatch(attr);

        if (axios.isAxiosError(response)) {
            throw response;
        }
    });
};

export const useDeleteEntityTag = (): UseMutationResult<void, unknown, EntityTagRequestObject> => {
    return useApiMutation(async (attr, apiConfig, axiosInstance) => {
        const response = await new EntityTagsApi(apiConfig, '', axiosInstance).deleteEntityTag(attr);

        if (axios.isAxiosError(response)) {
            throw response;
        }
    });
};
