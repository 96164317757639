import { Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Alert as MuiAlert, AlertProps as MuiAlertProps } from '@material-ui/lab';
import { ReactComponent as CloseIcon } from 'assets/icons/16px/cancel.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/icons/16px/check-circle.svg';
import { IconButton } from 'shared';

const styles = () =>
    createStyles({
        root: {
            alignItems: 'center',
            backgroundColor: 'var(--main-ltsfc-success-green-bg)',
            color: 'var(--r-catapult-black)',
            padding: 10,
        },
        icon: {
            marginLeft: 14,
        },
        action: {
            marginRight: 8,
        },
    });

interface AlertProps extends MuiAlertProps {
    onDismiss?: () => void;
}

export const Alert = withStyles(styles)((props: AlertProps) => {
    const { children, onDismiss, ...rest } = props;

    return (
        <MuiAlert
            action={
                onDismiss && (
                    <IconButton onClick={onDismiss} className="alert-close">
                        <CloseIcon />
                    </IconButton>
                )
            }
            iconMapping={{ success: <CheckCircleIcon fontSize="inherit" /> }}
            variant="outlined"
            {...rest}
        >
            <Typography variant="body1">{children}</Typography>
        </MuiAlert>
    );
});
