import { parseJSON } from 'date-fns';
import { ThresholdSet } from 'oas';

export interface TfThresholdSet extends Omit<ThresholdSet, 'created_at' | 'modified_at'> {
    created_at: Date;
    modified_at: Date;
}

export const transformThresholdSet = (ts: ThresholdSet): TfThresholdSet => {
    return {
        ...ts,
        created_at: parseJSON(ts.created_at),
        modified_at: parseJSON(ts.modified_at),
    };
};
