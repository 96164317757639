import axios, { AxiosError, AxiosResponse } from 'axios';
import { cloneDeep, merge } from 'lodash';

import { useDebounce } from '@SBGSports/referee-react';
import {
    ActivitiesApi,
    ActivityUpdateBatchRequest,
    ActivityUpdateBatchResponse,
    ActivityUpdateRequestPeriodAndAthletes,
    DeleteActivitiesRequest,
    ReprocessActivitiesRequest,
    RestoreActivitiesRequest,
    Rotation,
    UpdateActivitiesUserRequest,
} from 'oas';
import { InfiniteData, QueryKey, UseMutationOptions, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useApiMutation } from '../use-api-mutation';
import { TfActivity } from './transform';
import { useGetActivity } from './get';

export const useReprocessActivity = (): UseMutationResult<void, unknown, ReprocessActivitiesRequest> => {
    return useApiMutation(async (attr, apiConfig, axiosInstance) => {
        await new ActivitiesApi(apiConfig, '', axiosInstance).reprocessActivities(attr);
    });
};

export const useDeleteActivities = (): UseMutationResult<void, unknown, DeleteActivitiesRequest> => {
    const queryClient = useQueryClient();

    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            await new ActivitiesApi(apiConfig, '', axiosInstance).deleteActivities(attr);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ['activities'],
                });
            },
        },
    );
};

export const useRestoreActivities = (): UseMutationResult<void, unknown, RestoreActivitiesRequest> => {
    const queryClient = useQueryClient();

    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            await new ActivitiesApi(apiConfig, '', axiosInstance).restoreActivities(attr);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ['activities'],
                });
            },
        },
    );
};

export const useUpdateActivitiesUser = (): UseMutationResult<
    void,
    unknown,
    UpdateActivitiesUserRequest & { userId: string }
> => {
    const queryClient = useQueryClient();

    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            await new ActivitiesApi(apiConfig, '', axiosInstance).updateActivitiesUser(attr.userId, attr);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ['activities'],
                });
            },
        },
    );
};

export const useUpdateActivityName = (): UseMutationResult<void, unknown, { id: string; name: string }> => {
    const queryClient = useQueryClient();

    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            const response = await new ActivitiesApi(apiConfig, '', axiosInstance).updateActivity(attr.id, {
                name: attr.name,
            });
            if (axios.isAxiosError(response)) {
                throw response;
            }
        },
        {
            onSuccess: (_data, newAttr) => {
                if (!newAttr) {
                    return;
                }

                // Update this activity in the dataset of every cached query
                const dataSets = queryClient.getQueriesData<InfiniteData<TfActivity[]>>({
                    queryKey: ['activities'],
                });

                for (let i = 0; i < dataSets.length; i++) {
                    const dataSet = dataSets[i][1];
                    if (dataSet?.pages) {
                        for (let j = 0; j < dataSet?.pages.length; j++) {
                            dataSet.pages[j] = dataSet.pages[j].map((activity) => {
                                if (activity.id === newAttr.id) {
                                    return { ...activity, ...newAttr };
                                }
                                return activity;
                            });
                        }
                    }
                }
            },
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ['activities'],
                });
            },
        },
    );
};

export const useUpdateBatchActivity = (
    options?: UseMutationOptions<
        AxiosResponse<ActivityUpdateBatchResponse>,
        unknown,
        ActivityUpdateBatchRequest & { id: string }
    >,
): UseMutationResult<
    AxiosResponse<ActivityUpdateBatchResponse>,
    unknown,
    ActivityUpdateBatchRequest & { id: string }
> => {
    const queryClient = useQueryClient();
    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            const { id, ...rest } = attr;
            const response = await new ActivitiesApi(apiConfig, '', axiosInstance).putActivityBatch(id, rest);

            if (axios.isAxiosError(response)) {
                throw response;
            } else {
                return response;
            }
        },
        {
            onSuccess: (_data, variables) => {
                queryClient.invalidateQueries({
                    queryKey: ['activities', variables.id],
                });
                queryClient.invalidateQueries({
                    queryKey: ['activities', 'athletes', [variables.id]],
                });
            },
            ...options,
        },
    );
};

export const useUpdateBatchActivityForAutosave = (
    invalidateIncludes?: Parameters<typeof useGetActivity>[1],
    options?: UseMutationOptions<
        AxiosResponse<ActivityUpdateBatchResponse>,
        unknown,
        ActivityUpdateBatchRequest & { id: string }
    >,
): UseMutationResult<
    AxiosResponse<ActivityUpdateBatchResponse>,
    unknown,
    ActivityUpdateBatchRequest & { id: string }
> => {
    const queryClient = useQueryClient();

    const debouncedInvalidate = useDebounce((id: string) => {
        queryClient.invalidateQueries({
            queryKey: ['activities', id],
        });
        queryClient.invalidateQueries({
            queryKey: ['activities', 'athletes', [id]],
        });
    }, 5000);

    return useUpdateBatchActivity({
        onMutate: async (newAttr: ActivityUpdateBatchRequest & { id: string }) => {
            debouncedInvalidate(newAttr.id);

            let key: QueryKey = ['activities', newAttr.id];
            if (invalidateIncludes) {
                key = [...key, invalidateIncludes];
            }

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({
                queryKey: key,
            });

            // Snapshot the previous value
            const oldActivity = cloneDeep(queryClient.getQueryData<TfActivity>(key));
            if (oldActivity !== undefined) {
                // Optimistically update to the new value
                const newActivity = merge(oldActivity, newAttr) as TfActivity;
                newActivity.periods = newAttr.periods?.map((period: ActivityUpdateRequestPeriodAndAthletes) => {
                    const oldPeriod = oldActivity.periods?.find((p) => p.id === period.id);

                    // Update athletes
                    return {
                        ...period,
                        period_athletes:
                            period.athletes?.map((periodAthlete) => {
                                const oldRotations = oldPeriod?.period_athletes?.find(
                                    (pa) => pa.athlete_id === periodAthlete.athlete_id,
                                )?.rotations;

                                return {
                                    athlete_id: periodAthlete.athlete_id,
                                    rotations: oldRotations,
                                    is_injected: 0,
                                };
                            }) || [],
                        start_time: new Date(period.start_time_ms),
                        end_time: new Date(period.end_time_ms),
                        is_injected: 0,
                    };
                });

                queryClient.setQueryData(key, newActivity);
            }
        },
        // If the mutation fails, invalidate the query
        onError: (_err, newAttr) => {
            let key: QueryKey = ['activities', newAttr.id];
            if (invalidateIncludes) {
                key = [...key, invalidateIncludes];
            }

            queryClient.invalidateQueries({
                queryKey: key,
            });
        },
        onSuccess: (_data, variables) => {
            debouncedInvalidate(variables.id);
        },
        ...options,
    });
};

export const useUpdateAthleteRotations = (
    invalidateIncludes?: Parameters<typeof useGetActivity>[1],
    options?: UseMutationOptions<
        AxiosResponse<Rotation[]>,
        unknown,
        { activity_id: string; period_id: string; athlete_id: string; rotations: Rotation[] }
    >,
): UseMutationResult<
    AxiosResponse<Rotation[]>,
    unknown,
    { activity_id: string; period_id: string; athlete_id: string; rotations: Rotation[] }
> => {
    const queryClient = useQueryClient();
    // Key used to track pending mutations for this query
    const mutationKey: QueryKey = ['useUpdateAthleteRotations'];

    /**
     *
     * Get a filtered list of pending mutations for this query and invalidate the query if there are no more pending mutations
     *
     * The getMutationCache will return the full list of mutations that we need to filter out only the relevant ones
     *
     * @param key - The query key to invalidate
     */
    const invalidateQuery = (key: QueryKey) => {
        // Get list of pending mutations for this query filtered by the key
        const mutateQueries = queryClient
            .getMutationCache()
            .findAll({ mutationKey: mutationKey })
            .filter((item) => item.state.status === 'idle' || item.state.status === 'pending');

        // Because this is getting called from onSuccess and onError there will still be the count equal to 1
        if (mutateQueries.length === 1) {
            queryClient.invalidateQueries(
                invalidateIncludes ? { queryKey: [...key, invalidateIncludes] } : { queryKey: key },
            );
        }
    };

    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            const { activity_id, period_id, athlete_id, rotations } = attr;
            const response = await new ActivitiesApi(apiConfig, '', axiosInstance).updateAthleteRotations(
                activity_id,
                period_id,
                athlete_id,
                rotations,
            );

            if (axios.isAxiosError(response)) {
                throw response;
            } else {
                return response;
            }
        },
        {
            onSuccess: (_data, variables) => {
                invalidateQuery(['activities', variables.activity_id]);
            },
            onError: (_error: AxiosError, variables) => {
                if (_error.response?.status === 404) {
                    invalidateQuery(['activities', variables.activity_id]);
                }
            },
            ...{ ...options, retry: 5 },
        },
        mutationKey,
    );
};

export const useUpdateActivityVenueField = (): UseMutationResult<
    void,
    unknown,
    { id: string; name: string; venue_field_id: string }
> => {
    const queryClient = useQueryClient();

    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            const response = await new ActivitiesApi(apiConfig, '', axiosInstance).updateActivity(attr.id, {
                name: attr.name,
                venue_field_id: attr.venue_field_id,
            });
            if (axios.isAxiosError(response)) {
                throw response;
            }
        },
        {
            onSuccess: (_data, variables) => {
                queryClient.invalidateQueries({
                    queryKey: ['activities', variables.id],
                });
                queryClient.invalidateQueries({
                    queryKey: ['activities', 'deep'],
                });
            },
        },
    );
};
