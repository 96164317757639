import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { AuthValues } from 'auth';
import { useTranslations } from 'i18n';
import * as React from 'react';
import { getFormErrors } from 'shared';
import { LoginButton, LoginTextField } from './form-elements';

interface ErrorState {
    code?: string;
}

interface MfaFormProps {
    onSubmit: AuthValues['verifyMfa'];
    onCancel: () => void;
}

export const MfaForm: React.FC<MfaFormProps> = ({ onSubmit, onCancel }) => {
    const [errors, setErrors] = React.useState<ErrorState>({});
    const [loading, setLoading] = React.useState(false);
    const [code, setCode] = React.useState<string>('');
    const { __ } = useTranslations();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const newErrors: ErrorState = {};

        if (code.trim() === '') {
            newErrors.code = __('auth.enter_code');
        }

        if (Object.keys(newErrors).length !== 0) {
            setErrors(newErrors);
            return;
        }

        setLoading(true);
        try {
            await onSubmit(code);
        } catch (error) {
            setErrors(getFormErrors(error as Error, {}, 'code'));
        }
        setLoading(false);
    };

    const clearError = (field: string) => {
        setErrors({ ...errors, [field]: '' });
    };

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            textField: {
                marginTop: theme.spacing(2),
            },
        }),
    );
    const classes = useStyles();

    return (
        <>
            <form data-testid="login-mfa-form" onSubmit={handleSubmit} method="post" noValidate>
                <div>
                    <LoginTextField
                        autoFocus
                        inputProps={{
                            'data-testid': 'login-mfa-form-code',
                            style: {
                                fontSize: '120%',
                                letterSpacing: '2px',
                                textAlign: 'center',
                            },
                            maxLength: 6,
                        }}
                        className={classes.textField}
                        placeholder="000000"
                        name="code"
                        type="text"
                        value={code}
                        autoComplete="off"
                        required
                        onChange={(event) => {
                            clearError('code');
                            setCode(event.target.value);
                        }}
                        fullWidth
                        helperText={errors.code}
                        error={errors.code !== undefined}
                        disabled={loading}
                    />
                </div>
                <LoginButton type="submit" disabled={loading} data-testid="login-form-mfa-submit">
                    Confirm
                </LoginButton>
                <LoginButton
                    variant="text"
                    color="default"
                    fullWidth
                    disabled={loading}
                    data-testid="login-form-mfa-cancel"
                    onClick={onCancel}
                >
                    Cancel
                </LoginButton>
            </form>
        </>
    );
};
