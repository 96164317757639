import { Spinner, SpinnerProps } from '@SBGSports/referee-react';

type CentredSpinnerProps = SpinnerProps & {
    minHeight?: string;
};

export const CentredSpinner: React.FC<CentredSpinnerProps> = (props) => {
    const { minHeight = '80px', ...rest } = props;

    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                minHeight,
                width: '100%',
            }}
        >
            <Spinner {...rest} />
        </div>
    );
};
