import * as React from 'react';
import { parseJSON } from 'date-fns';
import { Notice } from 'oas';
import { DATE_FNS_CASUAL_DATE_FORMAT, useDateFormatter } from 'shared';

export interface TfNotice extends Notice {
    published_at_formatted: string | null;
}

export const useTransformNotice = (): ((row: Notice) => TfNotice) => {
    const formatDate = useDateFormatter();

    return React.useCallback(
        (row: Notice): TfNotice => ({
            ...row,
            published_at_formatted: row.published_at
                ? formatDate(parseJSON(row.published_at), DATE_FNS_CASUAL_DATE_FORMAT)
                : null,
            read: row.read,
        }),
        [formatDate],
    );
};
