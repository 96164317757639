import { capitalize, Select as MuiSelect, SelectProps as MuiSelectProps } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/16px/chevron-down.svg';

const styles = () =>
    createStyles({
        root: {
            backgroundColor: 'var(--r-catapult-white)',
            '&.Mui-disabled': {
                cursor: 'not-allowed',
                opacity: 0.5,
            },
        },
        disabled: {
            opacity: 0.5,
        },
    });
interface SelectProps extends MuiSelectProps {
    size?: 'small' | 'referee' | 'normal' | 'large';
}
export const Select = withStyles(styles)((props: SelectProps) => {
    const { size = 'normal', id, ...rest } = props;
    return (
        <MuiSelect
            displayEmpty
            variant="outlined"
            IconComponent={ChevronDownIcon}
            {...rest}
            className={`MuiInput-ext-size${capitalize(size)}`}
            id={id}
            MenuProps={{ id: id && `${id}-options` }}
        />
    );
});
