import axios from 'axios';
import {
    ActivitiesSortEnum,
    ActivityDictionary,
    ActivityDictionaryDataSourceEnum,
    ActivityDictionaryDataTypeEnum,
    ThresholdSetsSortEnum,
} from 'oas';
import * as React from 'react';

// If you know a better way of typing this.. please educate me
export function getSortParameter<T extends ActivitiesSortEnum | ThresholdSetsSortEnum>(
    sortColumn: string,
    sortDir: string,
    defaultSort: T,
): T {
    let sort;
    if (sortDir === 'desc') {
        sort = `-${sortColumn}`;
    } else {
        sort = sortColumn;
    }

    if (
        Object.values<string>(ActivitiesSortEnum).includes(sort) ||
        Object.values<string>(ThresholdSetsSortEnum).includes(sort)
    ) {
        return sort as T;
    }

    return defaultSort;
}

export const actDicHasData = (actDic: ActivityDictionary): boolean =>
    !!(
        actDic.data_type === ActivityDictionaryDataTypeEnum.Sd &&
        actDic.data_source === ActivityDictionaryDataSourceEnum.Munged &&
        actDic.has_data
    );

export const getValidationErrors = (responseError: unknown): [string, React.ReactNode | null] => {
    if (
        axios.isAxiosError(responseError) &&
        responseError.response &&
        (responseError.response.status === 400 || responseError.response.status === 422)
    ) {
        const { data, statusText } = responseError.response;
        const errorTitle = data.message || statusText || 'Unknown error';
        const errorMessages = [];
        if (data.errors) {
            const keys = Object.keys(data.errors);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                for (let j = 0; j < data.errors[key].length; j++) {
                    errorMessages.push(data.errors[key][j]);
                }
            }
        }

        const uniqueErrorMessages = [...new Set(errorMessages)];

        return [
            errorTitle,
            uniqueErrorMessages.length ? (
                <>
                    <ul>
                        {uniqueErrorMessages.map((errorMessage, index) => (
                            <li key={index}>{errorMessage}</li>
                        ))}
                    </ul>
                </>
            ) : null,
        ];
    } else {
        throw responseError;
    }
};
