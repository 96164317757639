import { CreateDeviceAthleteMappingRequest, DevicesApi } from 'oas';
import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useApiMutation } from '../use-api-mutation';
import axios from 'axios';

export const useCreateDeviceAthleteMapping = (): UseMutationResult<
    void,
    unknown,
    CreateDeviceAthleteMappingRequest
> => {
    const queryClient = useQueryClient();
    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            const response = await new DevicesApi(apiConfig, '', axiosInstance).postDeviceAthletes(attr);
            if (axios.isAxiosError(response)) {
                throw response;
            }
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ['activities', 'unmapped_devices'],
                });
                queryClient.invalidateQueries({
                    queryKey: ['activities', 'athletes'],
                });
            },
        },
    );
};
