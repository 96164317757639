import * as Sentry from '@sentry/react';

import { useGetFrontendConfig } from 'api';
import { useAuth } from 'auth';
import { BaseLayout } from 'layout';
import mixpanel from 'mixpanel-browser';
import { User } from 'oas';
import * as React from 'react';

const ObservabilityContext = React.createContext({});

export const ObservabilityProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isMixpanelInitialized, setIsMixpanelInitialized] = React.useState(false);
    const [isInitializing, setIsInitializing] = React.useState(false);
    const { user } = useAuth();
    const { data: frontendConfig } = useGetFrontendConfig({ enabled: !!user });

    React.useEffect(() => {
        if (frontendConfig && !isMixpanelInitialized && !isInitializing) {
            setIsInitializing(true);
            (async () => {
                // initialize mixpanel
                const token = frontendConfig.analytics.mixpanel.project_token;
                if (token) {
                    mixpanel.init(token, {
                        debug: process.env.REACT_APP_MIXPANEL_DEBUG ? true : false,
                        cookie_domain: frontendConfig.analytics.mixpanel.cookie_domain,
                    });
                    mixpanel.register(frontendConfig.analytics.super_properties);
                    if (user) {
                        mixpanel.identify(user.id);
                        setIsMixpanelInitialized(true);
                    } else {
                        mixpanel.reset();
                    }
                } else {
                    mixpanel.init('no-token');
                    mixpanel.disable();
                    setIsMixpanelInitialized(true);
                }
                setIsInitializing(false);
            })();
        }
    }, [isInitializing, isMixpanelInitialized, user, frontendConfig]);

    React.useEffect(() => {
        if (user) {
            const { ...userProps } = user;
            delete (userProps as Partial<User>).email;
            Sentry.setUser(userProps);
        } else {
            Sentry.setUser(null);
        }
    }, [user]);

    return (
        <ObservabilityContext.Provider value={{}}>
            {!user || isMixpanelInitialized ? children : <BaseLayout isLoading />}
        </ObservabilityContext.Provider>
    );
};
