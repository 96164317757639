import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useApiMutation } from '../use-api-mutation';
import { ConnectionCreateRequest, ConnectionUpdateRequest, ConnectionsApi, ImportGamesRequest } from 'oas';
import axios from 'axios';

export const useSaveConnection = (): UseMutationResult<void, unknown, ConnectionCreateRequest> => {
    return useApiMutation(async (attr, apiConfig, axiosInstance) => {
        await new ConnectionsApi(apiConfig, '', axiosInstance).createConnection(attr);
    });
};

export const useCreateConnection = (): UseMutationResult<void, unknown, ConnectionCreateRequest> => {
    const queryClient = useQueryClient();

    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            const response = await new ConnectionsApi(apiConfig, '', axiosInstance).createConnection(attr);

            if (axios.isAxiosError(response)) {
                throw response;
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: ['connections'],
                });
            },
        },
    );
};

export const useReimportConnectorActivities = (): UseMutationResult<void, unknown, ImportGamesRequest> => {
    return useApiMutation(async (attr, apiConfig, axiosInstance) => {
        await new ConnectionsApi(apiConfig, '', axiosInstance).importGames(attr);
    });
};

export interface UpdateConnection extends ConnectionUpdateRequest {
    apiId: string;
}
export const useUpdateConnection = (): UseMutationResult<void, unknown, UpdateConnection> => {
    const queryClient = useQueryClient();

    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            const { apiId, ...rest } = attr;
            const response = await new ConnectionsApi(apiConfig, '', axiosInstance).updateConnection(apiId, rest);

            if (axios.isAxiosError(response)) {
                throw response;
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: ['connections'],
                });
            },
        },
    );
};

export const useDeleteConnection = (): UseMutationResult<void, unknown, string> => {
    const queryClient = useQueryClient();

    return useApiMutation(
        async (apiId, apiConfig, axiosInstance) => {
            const response = await new ConnectionsApi(apiConfig, '', axiosInstance).deleteConnection(apiId);

            if (axios.isAxiosError(response)) {
                throw response;
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: ['connections'],
                });
            },
        },
    );
};
