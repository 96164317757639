import * as React from 'react';

interface IframeEvent<T> {
    id: string;
    payload: T;
}

export function useIframeListener<T = string>(eventId: string): IframeEvent<T> | undefined {
    const [event, setEvent] = React.useState<IframeEvent<T>>();

    React.useEffect(() => {
        const handler = function (nativeEvent: MessageEvent) {
            const legacyBackend = process.env.REACT_APP_AUTH_HOST;
            if (nativeEvent.origin === legacyBackend) {
                if (
                    typeof nativeEvent.data === 'object' &&
                    typeof nativeEvent.data.eventId === 'string' &&
                    nativeEvent.data.eventId === eventId
                ) {
                    const newEvent = {
                        id: nativeEvent.data.eventId,
                        payload: nativeEvent.data.eventData,
                    };

                    // eslint-disable-next-line no-console
                    console.debug('iframe event', newEvent);

                    setEvent(newEvent);
                }
            }
        };

        window.addEventListener('message', handler);

        return function cleanup() {
            window.removeEventListener('message', handler);
        };
    }, [eventId]);

    return event;
}
