import { Notification, NotificationsApi, NotificationsUpdateRequest } from 'oas';
import { UseMutationResult } from '@tanstack/react-query';
import { useApiMutation, useOptimisticOptions } from '../use-api-mutation';

export const useUpdateNotifications = (): UseMutationResult<void, unknown, NotificationsUpdateRequest> => {
    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            await new NotificationsApi(apiConfig, '', axiosInstance).patchNotifications(attr);
        },
        useOptimisticOptions<Notification[], NotificationsUpdateRequest>(
            ['notifications', []],
            (newAttr) => (oldItems) => {
                return oldItems
                    ? oldItems.map((oldItem) => {
                          if (newAttr.ids.includes(oldItem.id)) {
                              return mergeItems(newAttr, oldItem);
                          }
                          return oldItem;
                      })
                    : [];
            },
        ),
    );
};

export const useDeleteNotifications = (): UseMutationResult<void, unknown, string[]> => {
    return useApiMutation(
        async (ids, apiConfig, axiosInstance) => {
            await new NotificationsApi(apiConfig, '', axiosInstance).deleteNotifications({ ids });
        },
        useOptimisticOptions<Notification[] | undefined, string[]>(['notifications', []], (ids) => (oldItems) => {
            return oldItems
                ? oldItems.map((oldItem) => {
                      if (ids.includes(oldItem.id)) {
                          return { ...oldItem, isDeleting: true };
                      }
                      return oldItem;
                  })
                : [];
        }),
    );
};

export const useActionNotifications = (): UseMutationResult<void, unknown, string[]> => {
    return useApiMutation(
        async (ids, apiConfig, axiosInstance) => {
            await new NotificationsApi(apiConfig, '', axiosInstance).actionNotifications({ ids });
        },
        useOptimisticOptions<Notification[] | undefined, string[]>(['notifications', []], (ids) => (oldItems) => {
            return oldItems
                ? oldItems.map((oldItem) => {
                      if (ids.includes(oldItem.id)) {
                          return { ...oldItem, isActioning: true };
                      }
                      return oldItem;
                  })
                : [];
        }),
    );
};

const mergeItems = (newAttr: NotificationsUpdateRequest, oldItem: Notification): Notification => {
    const newItem = { ...oldItem };
    if (newAttr.read !== undefined) {
        newItem.read = newAttr.read ? 1 : 0;
    }

    if (newAttr.notified !== undefined) {
        newItem.notified = newAttr.notified ? 1 : 0;
    }

    return newItem;
};
