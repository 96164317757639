export const addQueryToUrl = (url: string, query: Record<string, string>): string => {
    const urlParts = new URL(url);

    const entries = Object.entries(query);
    for (let i = 0; i < entries.length; i++) {
        const entry = entries[i];
        urlParts.searchParams.append(entry[0], entry[1]);
    }

    return urlParts.toString();
};

export const isValidUrl = (url: string): boolean => {
    try {
        new URL(url);
    } catch (e) {
        return false;
    }
    return true;
};
