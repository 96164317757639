import { UseMutationResult } from '@tanstack/react-query';
import { OAuthAuthorizeTokenRequest, OAuthAuthorizeTokenResponse, AuthenticationApi } from 'oas';
import { useApiMutation } from '../../use-api-mutation';

export const useApproveAuthorizeTokenForCodeGrant = (): UseMutationResult<
    OAuthAuthorizeTokenResponse,
    unknown,
    OAuthAuthorizeTokenRequest
> => {
    return useApiMutation(
        async (attr, apiConfig, axiosInstance) =>
            (await new AuthenticationApi(apiConfig, '', axiosInstance).postAuthorizeTokenForCodeGrant(attr))?.data,
    );
};

export const useDeclineAuthorizeTokenForCodeGrant = (): UseMutationResult<
    OAuthAuthorizeTokenResponse,
    unknown,
    OAuthAuthorizeTokenRequest
> => {
    return useApiMutation(
        async (attr, apiConfig, axiosInstance) =>
            (await new AuthenticationApi(apiConfig, '', axiosInstance).deleteAuthorizeTokenForCodeGrant(attr))?.data,
    );
};
