import { OAuthAuthorizeDataResponse, AuthenticationApi } from 'oas';
import { useApiQuery } from '../../use-api-query';
import { UseQueryResult } from '@tanstack/react-query';

export const useGetAuthorizeDataForCodeGrant = (
    ...parameters: Parameters<typeof AuthenticationApi.prototype.getAuthorizeDataForCodeGrant>
): UseQueryResult<OAuthAuthorizeDataResponse> =>
    useApiQuery(['oauth', 'authorize', parameters], (apiConfig, axiosInstance) =>
        new AuthenticationApi(apiConfig, '', axiosInstance).getAuthorizeDataForCodeGrant(...parameters),
    );
