import {
    ActivityAthlete,
    Athlete as OasAthlete,
    AverageSet,
    DeepActivity,
    DeepActivityAthlete,
    LiveInfoDistanceUnitEnum,
    LiveInfoSpeedUnitEnum,
    Parameter,
    ParameterUnitTypeEnum,
    StatRow,
    StatsFilter,
    Benchmark,
    Tags,
} from 'oas';
import {
    AthleteName,
    AverageSetsModel,
    CustomUnitType,
    DefaultChartParameterOptions,
    DefaultTableParameterOptions,
    ExtendedAthlete,
    ParameterUnitTypeInfo,
    PercentageBreakdownModel,
    ReportingMetric,
    SelectedAverageSets,
    StatsModel,
    StatType,
} from 'reporting/types';
import {
    ACTIVITY_BENCHMARKS_PARAMETERS,
    average,
    calculateOverall,
    getFormattedDuration,
    percentage,
    POSITION_SORT_ORDER,
    round,
    slate,
    SYSTEM_AVERAGE_SET_REGEX,
    teal,
    violet,
} from 'shared';
import { isEmpty, max } from 'lodash';
import { TfReportingParameterGroup } from '../api/parameters/transform';
import { SelectedParameters } from './reducers';
import { StatsQuery } from 'api/stats/mutate';
import {
    ATHLETE,
    CUSTOM_UNIT_TYPE,
    DEFAULT_ACTIVITY_BENCHMARKS,
    MATCH_DAY,
    REPORTING_PRIMARY_FIELDS,
    REPORTING,
} from './constants';
import { Translator } from 'i18n';
import { GridOptions, RowClassParams } from 'ag-grid-community';
import Highcharts from 'highcharts/highstock';
import moment from 'moment';
import { SelectOption } from '@SBGSports/referee-react';
import { STATEMENT_TYPE } from './components/metric-statement/constants';

export enum AverageSetType {
    Athlete = 'ATHLETE',
    Position = 'POSITION',
    Team = 'TEAM',
}

export enum ChartSortingEnum {
    Position,
    Name,
    Date,
    Custom,
    Period,
}

export interface StatFilterValues {
    activity_id?: string[];
    athlete_id?: string[];
    period_id?: string[];
    date?: string;
    activity_athlete_tag_id?: string[];
    week_id?: string;
}

export enum DistanceMultiplier {
    Meters = 1,
    Kilometers = 0.001,
    Miles = 0.000621371,
    Yards = 1.09361296,
}

export enum SpeedMultiplier {
    MetersPerSecond = 1,
    KilometersPerHour = 3.6,
    YardsPerSecond = 1.0936133,
    MilesPerHour = 2.23693629,
}

export function getDistanceUnit(unit: LiveInfoDistanceUnitEnum | undefined, shortUnitKey?: boolean) {
    switch (unit) {
        case LiveInfoDistanceUnitEnum.Meters:
            return shortUnitKey ? 'misc.short_metres_lowercase' : 'misc.metres';
        case LiveInfoDistanceUnitEnum.Kilometers:
            return shortUnitKey ? 'misc.short_kilometres_lowercase' : 'units.km';
        case LiveInfoDistanceUnitEnum.Miles:
            return shortUnitKey ? 'misc.short_miles_lowercase' : 'units.mi';
        case LiveInfoDistanceUnitEnum.Yards:
            return shortUnitKey ? 'misc.short_yards_lowercase' : 'units.y';
        default:
            return 'misc.metres';
    }
}

export function getSpeedUnit(unit: LiveInfoSpeedUnitEnum | undefined) {
    switch (unit) {
        case LiveInfoSpeedUnitEnum.MetersPerSecond:
            return 'units.ms_abbrev';
        case LiveInfoSpeedUnitEnum.KilometersPerHour:
            return 'units.kph_abbrev';
        case LiveInfoSpeedUnitEnum.MilesPerHour:
            return 'units.mph_abbrev';
        case LiveInfoSpeedUnitEnum.YardsPerSecond:
            return 'units.ys_abbrev';
        default:
            return 'units.ms_abbrev';
    }
}

export function getParameterNameUnit(
    unitType: ParameterUnitTypeEnum | undefined,
    speedUnit: LiveInfoSpeedUnitEnum,
    distanceUnit: LiveInfoDistanceUnitEnum,
    __: Translator,
) {
    switch (unitType) {
        case ParameterUnitTypeEnum.Speed:
            return `(${__(getSpeedUnit(speedUnit))})`;
        case ParameterUnitTypeEnum.Distance:
            return `(${__(getDistanceUnit(distanceUnit, true))})`;
        case ParameterUnitTypeEnum.Percentage:
            return '(%)';
        default:
            return '';
    }
}

export function getParameterNameAndUnit(
    unitType: ParameterUnitTypeEnum | undefined,
    speedUnit: LiveInfoSpeedUnitEnum,
    distanceUnit: LiveInfoDistanceUnitEnum,
    name: string,
    __: Translator,
) {
    switch (unitType) {
        case ParameterUnitTypeEnum.Speed:
            return `${__(name)} (${__(getSpeedUnit(speedUnit)).toLocaleUpperCase()})`;
        case ParameterUnitTypeEnum.Distance:
            return `${__(name)} (${__(getDistanceUnit(distanceUnit, true)).toLocaleUpperCase()})`;
        case ParameterUnitTypeEnum.Percentage:
            return `${__(name)} (%)`;
        default:
            return '';
    }
}

export const getParameterUnitType = (parameters: Parameter[], slug: string): ParameterUnitTypeInfo => {
    const custom = CUSTOM_UNIT_TYPE.find((type) => type.list.includes(slug));
    if (custom) {
        return custom.unitInfo;
    }
    const unitType = parameters.find((param) => param.slug === slug)?.unit_type ?? '';
    const unitTypeKey = unitType?.charAt(0)?.toUpperCase() + unitType?.slice(1);
    const unitTypeEnum =
        ParameterUnitTypeEnum[unitTypeKey as keyof typeof ParameterUnitTypeEnum] ?? ParameterUnitTypeEnum.Abitrary;

    return {
        unitType: unitTypeEnum,
        isAverage: false,
    };
};
export const getMappedDefaultBenchmark = (fallbackBenchmark?: Benchmark): StatsModel => {
    if (!fallbackBenchmark) {
        return DEFAULT_ACTIVITY_BENCHMARKS;
    }

    const stats = fallbackBenchmark?.parameters?.reduce((result, item) => {
        result[item.slug ?? ''] = item.target ?? 0;
        return result;
    }, {} as { [key: string]: number });

    return {
        id: 'defaultBenchmark',
        statType: StatType.Activity,
        stats: stats,
        volumeMetrics: [
            {
                metricName: 'reporting.distance_in_unit',
                metricValue:
                    fallbackBenchmark?.parameters?.find((parameter) => parameter.slug === REPORTING.distance)?.target ??
                    0,
                unitKey: 'misc.short_metres_lowercase',
            },
            {
                metricName: 'reporting.hs_distance_in_unit',
                metricValue:
                    fallbackBenchmark?.parameters?.find((parameter) => parameter.slug === REPORTING.high_speed_distance)
                        ?.target ?? 0,
                unitKey: 'misc.short_metres_lowercase',
            },
            {
                metricName: 'reporting.accel-decel_number',
                metricValue:
                    fallbackBenchmark?.parameters?.find(
                        (parameter) => parameter.slug === REPORTING.accel_and_decel_efforts,
                    )?.target ?? 0, //accel + decel average efforts (session)
            },
        ],
        intensityMetrics: [
            {
                metricName: 'reporting.unit_per_minute',
                metricValue:
                    fallbackBenchmark?.parameters?.find((parameter) => parameter.slug === REPORTING.meterage_per_minute)
                        ?.target ?? 0,
                unitKey: 'misc.metres',
            },
            {
                metricName: 'reporting.hs_distance_unit_per_min',
                metricValue:
                    fallbackBenchmark?.parameters?.find(
                        (parameter) => parameter.slug === REPORTING.high_speed_distance_per_min,
                    )?.target ?? 0,
                unitKey: 'misc.short_metres_lowercase',
            },
            {
                metricName: 'reporting.accel_decel_per_min',
                metricValue:
                    fallbackBenchmark?.parameters?.find(
                        (parameter) => parameter.slug === REPORTING.accel_and_decel_efforts_per_minute,
                    )?.target ?? 0, //accel + decel average efforts (session) per minute,
            },
        ],
    };
};

export function getDefaultBenchmark(unitKey: LiveInfoDistanceUnitEnum, fallbackBenchmark?: Benchmark): StatsModel {
    const defaultBenchmark = getMappedDefaultBenchmark(fallbackBenchmark);
    const multiplier: number = DistanceMultiplier[LiveInfoDistanceUnitEnum[unitKey] as keyof typeof DistanceMultiplier];

    return {
        ...defaultBenchmark,
        volumeMetrics: defaultBenchmark.volumeMetrics.map((metric) => {
            return {
                ...metric,
                metricValue:
                    metric.unitTypeInfo?.unitType === ParameterUnitTypeEnum.Speed ||
                    metric.unitTypeInfo?.unitType === ParameterUnitTypeEnum.Distance
                        ? metric.metricValue * multiplier
                        : metric.metricValue,
            };
        }),
        intensityMetrics: defaultBenchmark.intensityMetrics.map((metric) => {
            return {
                ...metric,
                metricValue:
                    metric.unitTypeInfo?.unitType === ParameterUnitTypeEnum.Speed ||
                    metric.unitTypeInfo?.unitType === ParameterUnitTypeEnum.Distance
                        ? metric.metricValue * multiplier
                        : metric.metricValue,
            };
        }),
    };
}

export function buildStatsModel(
    data: StatRow | undefined,
    stat: StatType,
    groupedParameters: TfReportingParameterGroup[] = [],
    unitKey?: LiveInfoDistanceUnitEnum,
): StatsModel {
    const allParameters: Parameter[] = groupedParameters.flatMap((group) => group.parameters);
    const distanceVolume = data?.[REPORTING.distance];
    const highSpeedDistanceVolume = data?.[REPORTING.high_speed_distance];
    const accelDecelEffortsVolume = data?.[REPORTING.accel_and_decel_efforts];
    const averageDuration = data?.[REPORTING.duration];
    const distanceIntensity = distanceVolume / (averageDuration / 60);
    const highSpeedDistanceIntensity = highSpeedDistanceVolume / (averageDuration / 60);
    const accelDecelEffortsIntensity = accelDecelEffortsVolume / (averageDuration / 60);

    const distanceVolumeType = getParameterUnitType(allParameters, REPORTING.distance);
    const highSpeedDistanceVolumeType = getParameterUnitType(allParameters, REPORTING.high_speed_distance);
    const accelDecelEffortsVolumeType = getParameterUnitType(allParameters, REPORTING.accel_and_decel_efforts);
    const highSpeedDistanceIntensityType = getParameterUnitType(allParameters, REPORTING.high_speed_distance_per_min);
    const accelDecelEffortsIntensityType = getParameterUnitType(
        allParameters,
        REPORTING.accel_and_decel_efforts_per_minute,
    );

    return {
        id: data?.[stat + '_id'],
        stats: data,
        statType: stat,
        volumeMetrics: [
            {
                metricName: 'reporting.distance_in_unit',
                metricValue: distanceVolume,
                unitKey: getDistanceUnit(unitKey, true),
                unitTypeInfo: distanceVolumeType,
            },
            {
                metricName: 'reporting.hs_distance_in_unit',
                metricValue: highSpeedDistanceVolume,
                unitKey: getDistanceUnit(unitKey, true),
                unitTypeInfo: highSpeedDistanceVolumeType,
            },
            {
                metricName: 'reporting.accel-decel_number',
                metricValue: accelDecelEffortsVolume,
                unitTypeInfo: accelDecelEffortsVolumeType,
            },
        ],
        intensityMetrics: [
            {
                metricName: 'reporting.unit_per_minute',
                metricValue: distanceIntensity,
                unitKey: getDistanceUnit(unitKey, false),
                unitTypeInfo: highSpeedDistanceIntensityType,
            },
            {
                metricName: 'reporting.hs_distance_unit_per_min',
                metricValue: highSpeedDistanceIntensity,
                unitKey: getDistanceUnit(unitKey, true),
                unitTypeInfo: accelDecelEffortsIntensityType,
            },
            {
                metricName: 'reporting.accel_decel_per_min',
                metricValue: accelDecelEffortsIntensity,
                unitTypeInfo: accelDecelEffortsIntensityType,
            },
        ],
    };
}

export function calculateLoadScores(stats: StatRow[], averageSets: AverageSetsModel, statType: StatType) {
    const singleAthleteSelected =
        new Set<string>(
            stats
                .filter((statsToFilter) => statsToFilter['athlete_id'] !== undefined)
                .map((stat) => {
                    return stat['athlete_id'];
                }),
        ).size === 1;

    return stats.map((statRow) => {
        const volumeIntensityModel = buildStatsModel(statRow, statType);
        statRow['volume'] = calculateMetricAveragePercentage(
            volumeIntensityModel.volumeMetrics,
            statType === StatType.Athlete || singleAthleteSelected
                ? findAthleteBenchmark(averageSets, statRow['athlete_id'], statRow['position_id']).volumeMetrics
                : averageSets.teamAverageSet
                ? averageSets.teamAverageSet.volumeMetrics
                : averageSets.defaultBenchmark.volumeMetrics,
        );

        statRow['intensity'] = calculateMetricAveragePercentage(
            volumeIntensityModel.intensityMetrics,
            statType === StatType.Athlete || singleAthleteSelected
                ? findAthleteBenchmark(averageSets, statRow['athlete_id'], statRow['position_id']).intensityMetrics
                : averageSets.teamAverageSet
                ? averageSets.teamAverageSet.intensityMetrics
                : averageSets.defaultBenchmark.intensityMetrics,
        );

        const volumeMetricPercentages = getMetricPercentageArray(
            volumeIntensityModel.volumeMetrics,
            statType === StatType.Athlete || singleAthleteSelected
                ? findAthleteBenchmark(averageSets, statRow['athlete_id'], statRow['position_id']).volumeMetrics
                : averageSets.teamAverageSet
                ? averageSets.teamAverageSet.volumeMetrics
                : averageSets.defaultBenchmark.volumeMetrics,
        );

        const intensityMetricPercentages = getMetricPercentageArray(
            volumeIntensityModel.intensityMetrics,
            statType === StatType.Athlete || singleAthleteSelected
                ? findAthleteBenchmark(averageSets, statRow['athlete_id'], statRow['position_id']).intensityMetrics
                : averageSets.teamAverageSet
                ? averageSets.teamAverageSet.intensityMetrics
                : averageSets.defaultBenchmark.intensityMetrics,
        );

        statRow['overall'] = calculateOverall(average(volumeMetricPercentages), average(intensityMetricPercentages));

        if (statRow[REPORTING.max_deceleration]) {
            statRow[REPORTING.max_deceleration] = Math.abs(statRow[REPORTING.max_deceleration]);
        }

        return statRow;
    });
}

export function buildStatsQuery(
    filters: StatFilterValues,
    averageSets: AverageSetsModel,
    groupBy = StatType.Activity,
    additionalParams?: string[],
): StatsQuery {
    const statsFilter: StatsFilter[] = Object.entries(filters)
        .map((value) => {
            return {
                name: value[0],
                comparison: value[0] === 'date' || value[0] === 'week_id' ? '>=' : '=',
                values: value[1],
            } as StatsFilter;
        })
        .filter((value) => value.values && value.values.length > 0);

    const parameters = additionalParams
        ? [...ACTIVITY_BENCHMARKS_PARAMETERS, ...additionalParams]
        : ACTIVITY_BENCHMARKS_PARAMETERS;

    return {
        averageSets: averageSets,
        statType: groupBy,
        statsRequest: {
            filters: statsFilter,
            group_by: [groupBy],
            parameters: parameters,
            sorting: ['position_name'],
            source: 'cached_stats',
        },
    };
}

export function getPeriodIdsFromActivity(activity: DeepActivity | undefined): string[] {
    return activity?.periods?.map((item) => item.id ?? '') ?? [];
}

export function getMetricPercentageArray(metrics: ReportingMetric[], benchmarkMetrics: ReportingMetric[]): number[] {
    const filteredMetrics = metrics.filter(
        (metric) =>
            isValidNumber(metric.metricValue) &&
            benchmarkMetrics?.find(
                (benchmark) =>
                    benchmark.metricName === metric.metricName && isValidBenchmarkValue(benchmark.metricValue),
            ) !== undefined,
    );

    return filteredMetrics?.map((metric) => {
        const benchmarkValue =
            benchmarkMetrics?.find((target) => target.metricName === metric.metricName)?.metricValue || 0;

        return percentage(metric.metricValue, benchmarkValue) || 0;
    });
}

export function calculateMetricAveragePercentage(comparison: ReportingMetric[], benchmark: ReportingMetric[]) {
    const metricPercentages = getMetricPercentageArray(comparison, benchmark);
    const averagePercent = average(metricPercentages) || 0;
    return round(averagePercent, 0);
}
export function maxMetricPercentage(comparison: ReportingMetric[], benchmark: ReportingMetric[]) {
    const metricPercentages = getMetricPercentageArray(comparison, benchmark);
    return round(max(metricPercentages) ?? 0, 0);
}

export function calculateVolumeIntensityPercentages(
    comparison: StatsModel,
    benchmark: StatsModel,
    formatMetric: (key: string | undefined, val: number, isAverage?: boolean | undefined) => string | number,
): PercentageBreakdownModel {
    return {
        id: comparison.id,
        volumeMetrics: comparison.volumeMetrics.map((metric) => {
            const benchmarkValue =
                benchmark.volumeMetrics?.find((target) => target.metricName === metric.metricName)?.metricValue || 0;

            const hasInvalidData = !isValidBenchmarkValue(benchmarkValue);
            return {
                ...metric,
                metricValue: formatMetric(
                    metric.unitTypeInfo?.unitType,
                    metric.metricValue,
                    metric.unitTypeInfo?.isAverage,
                ) as number,
                metricPercentage: hasInvalidData ? 0 : round(percentage(metric.metricValue, benchmarkValue) || 0, 0),
                hasInvalidData,
            };
        }),
        intensityMetrics: comparison.intensityMetrics.map((metric) => {
            const benchmarkValue =
                benchmark.intensityMetrics?.find((target) => target.metricName === metric.metricName)?.metricValue || 0;

            const hasInvalidData = !isValidBenchmarkValue(benchmarkValue);
            return {
                ...metric,
                metricValue: formatMetric(
                    metric.unitTypeInfo?.unitType,
                    metric.metricValue,
                    metric.unitTypeInfo?.isAverage,
                ) as number,
                metricPercentage: hasInvalidData ? 0 : round(percentage(metric.metricValue, benchmarkValue) || 0, 0),
                hasInvalidData,
            };
        }),
    };
}

export const splitName = (name: string | undefined): AthleteName => {
    const names = name?.split(' ') ?? [];
    const firstName = names?.length > 0 ? names[0] : '';
    const lastName = names?.length > 1 ? names[1] : '';
    return {
        firstName,
        lastName,
    };
};

export const positionSort = (posA: string, posB: string) => {
    if (POSITION_SORT_ORDER.includes(posA) && POSITION_SORT_ORDER.includes(posB)) {
        return POSITION_SORT_ORDER.indexOf(posA) - POSITION_SORT_ORDER.indexOf(posB);
    } else {
        return posA.localeCompare(posB);
    }
};

export const reversePositionSort = (posA: string, posB: string) => {
    const reversedSortOrder = [...POSITION_SORT_ORDER].reverse();
    if (reversedSortOrder.includes(posA) && reversedSortOrder.includes(posB)) {
        return reversedSortOrder.indexOf(posA) - reversedSortOrder.indexOf(posB);
    } else {
        return posB.localeCompare(posA);
    }
};

export const tryNumericSort = (num1: number | string, num2: number | string) => {
    if (typeof num1 === 'number' && typeof num2 === 'number') {
        return (num1 - num2) * -1;
    } else {
        return num1.toString().localeCompare(num2.toString()) * -1;
    }
};

export const getDefaultTableParameters = (options: DefaultTableParameterOptions): SelectedParameters => {
    if (options.returnEmptySelection) {
        return {
            groupingMap: new Map(),
            paramList: [],
        };
    }
    const defaultSlugs = [REPORTING.volume, REPORTING.intensity, REPORTING.overall];

    const groupMap = new Map([['Load Scores', new Set([REPORTING.volume, REPORTING.intensity, REPORTING.overall])]]);

    const parameters =
        options.parameters?.flatMap((group) => group.parameters).filter((param) => defaultSlugs.includes(param.slug)) ??
        [];

    return {
        groupingMap: groupMap,
        paramList: parameters,
    };
};

export const getDefaultChartParameters = (options: DefaultChartParameterOptions): SelectedParameters => {
    if (options.returnEmptySelection) {
        return {
            groupingMap: new Map(),
            paramList: [],
        };
    } else if (options.defaultLength && options.defaultLength < 2) {
        return {
            groupingMap: new Map([['Load Scores', new Set([REPORTING.volume])]]),
            paramList:
                options.parameters
                    ?.flatMap((group) => group.parameters)
                    ?.filter((param) => param.slug === REPORTING.volume) ?? [],
        };
    }
    const defaultSlugs = [REPORTING.volume, REPORTING.intensity];

    const groupMap = new Map([['Load Scores', new Set([REPORTING.volume, REPORTING.intensity])]]);

    const parameters =
        options.parameters
            ?.flatMap((group) => group.parameters)
            ?.filter((param) => defaultSlugs.includes(param.slug)) ?? [];

    return {
        groupingMap: groupMap,
        paramList: parameters,
    };
};

export function sortExtendedAthletes(
    athletes: ExtendedAthlete[],
    sorting: ChartSortingEnum,
    reverseSort: boolean,
    reversePositions: boolean,
    parameterToSortBy?: string,
): ExtendedAthlete[] {
    return [...athletes].sort((a, b) => {
        return (
            (reversePositions ? reversePositionSort(a.position, b.position) : positionSort(a.position, b.position)) ||
            (sorting === ChartSortingEnum.Custom
                ? reverseSort
                    ? b.athleteStats[parameterToSortBy ?? REPORTING.volume] -
                      a.athleteStats[parameterToSortBy ?? REPORTING.intensity]
                    : a.athleteStats[parameterToSortBy ?? REPORTING.volume] -
                      b.athleteStats[parameterToSortBy ?? REPORTING.intensity]
                : sorting === ChartSortingEnum.Name
                ? reverseSort
                    ? b.athleteName.lastName?.localeCompare(a.athleteName.lastName ?? '')
                    : a.athleteName.lastName?.localeCompare(b.athleteName.lastName ?? '')
                : 1)
        );
    });
}

export function sortPeriodChart(
    athletePeriods: StatsModel[],
    sorting: ChartSortingEnum,
    reverseSort: boolean,
    parameterToSortBy: string,
): StatsModel[] {
    return [...athletePeriods].sort((a, b) => {
        return sorting === ChartSortingEnum.Custom
            ? reverseSort
                ? b.stats?.[parameterToSortBy] - a.stats?.[parameterToSortBy]
                : a.stats?.[parameterToSortBy] - b.stats?.[parameterToSortBy]
            : sorting === ChartSortingEnum.Name
            ? reverseSort
                ? b.stats?.['period_name'].localeCompare(a.stats?.['period_name'] ?? '')
                : a.stats?.['period_name'].localeCompare(b.stats?.['period_name'] ?? '')
            : 1;
    });
}

export function sortLongitudinalChart(
    statRow: StatRow[],
    sorting: ChartSortingEnum,
    reverseSort: boolean,
    parameterToSortBy: string,
    isUSLocale?: boolean,
): StatRow[] {
    return [...statRow].sort((a, b) => {
        const aParam = isNaN(a[parameterToSortBy]) ? 0 : a[parameterToSortBy];
        const bParam = isNaN(b[parameterToSortBy]) ? 0 : b[parameterToSortBy];

        if (sorting === ChartSortingEnum.Custom) {
            return reverseSort ? bParam - aParam : aParam - bParam;
        } else if (sorting === ChartSortingEnum.Name) {
            return reverseSort
                ? splitName(b['athlete_name']).lastName.localeCompare(splitName(a['athlete_name']).lastName ?? '')
                : splitName(a['athlete_name']).lastName.localeCompare(splitName(b['athlete_name']).lastName ?? '');
        } else if (sorting === ChartSortingEnum.Period) {
            return reverseSort
                ? b['period_name'].localeCompare(a['period_name'])
                : a['period_name'].localeCompare(b['period_name']);
        } else if (sorting === ChartSortingEnum.Date) {
            const aDate = moment(a['date'], isUSLocale ? 'MM/DD/YYYY' : 'DD/MM/YYYY');
            const bDate = moment(b['date'], isUSLocale ? 'MM/DD/YYYY' : 'DD/MM/YYYY');

            if (reverseSort) {
                return bDate.diff(aDate);
            } else {
                return aDate.diff(bDate);
            }
        }

        return 1;
    });
}

export const mapDeepActivityAthlete = (athlete: DeepActivityAthlete): OasAthlete => {
    const names = splitName(athlete?.name);
    return {
        id: athlete.id ?? '',
        first_name: names.firstName,
        last_name: names.lastName,
        gender: null,
        date_of_birth_date: '',
        position: '',
        position_name: '',
    };
};

export const mapActivityAthlete = (athlete: ActivityAthlete): OasAthlete => {
    return {
        id: athlete.id ?? '',
        first_name: athlete.first_name ?? '',
        last_name: athlete.last_name ?? '',
        gender: null,
        date_of_birth_date: '',
        position: '',
        position_name: '',
    };
};

export const isSystemAverageSet = (name: string): boolean => {
    const averageSetRegex = new RegExp(SYSTEM_AVERAGE_SET_REGEX);
    return averageSetRegex.test(name);
};

export const findAverageSetUsingTypeAndSelectText = (
    text: string,
    type: AverageSetType,
    averageSets: AverageSet[] | undefined,
) => {
    return averageSets?.find(
        (averageSet) => isSystemAverageSet(averageSet.name) && averageSet.name?.includes(`${text} ${type.toString()}`),
    );
};

export const getSelectedAverageSetsMatchingText = (text: string, averageSets: undefined | AverageSet[]) => {
    return {
        text,
        team: findAverageSetUsingTypeAndSelectText(text, AverageSetType.Team, averageSets),
        athlete: findAverageSetUsingTypeAndSelectText(text, AverageSetType.Athlete, averageSets),
        position: findAverageSetUsingTypeAndSelectText(text, AverageSetType.Position, averageSets),
    };
};

export const getMatchDayAverageSets = (
    averageSets: AverageSet[] | undefined,
    comparison: string,
    hasAthlete = false,
): SelectedAverageSets => {
    const text = comparison === ATHLETE && !hasAthlete ? MATCH_DAY.toUpperCase() : comparison.toUpperCase();
    if (!averageSets) {
        return { text, athlete: undefined, position: undefined, team: undefined };
    }
    return {
        text,
        team: findAverageSetUsingTypeAndSelectText(text, AverageSetType.Team, averageSets),
        athlete: findAverageSetUsingTypeAndSelectText(text, AverageSetType.Athlete, averageSets),
        position: findAverageSetUsingTypeAndSelectText(text, AverageSetType.Position, averageSets),
    };
};

export const getImageFullUrl = (image: string | undefined): string | undefined => {
    const legacyBackend = process.env.REACT_APP_AUTH_HOST;
    if (image) {
        return legacyBackend + image;
    }

    return undefined;
};

export const getIntendedAthleteAverageSet = (averageSets: AverageSetsModel, athleteId: string) => {
    const intendedAthleteAverage =
        averageSets?.athleteAverageSet &&
        averageSets?.athleteAverageSet?.find((benchmark) => benchmark.id === athleteId);

    if (!isEmptyStat(intendedAthleteAverage?.stats)) {
        return intendedAthleteAverage;
    }
    return undefined;
};

export const getIntendedPositionAverageSet = (averageSets: AverageSetsModel, positionId: string) => {
    const intendedPositionAverage =
        averageSets?.positionAverageSet &&
        averageSets?.positionAverageSet?.find((benchmark) => benchmark.id === positionId);

    if (!isEmptyStat(intendedPositionAverage?.stats)) {
        return intendedPositionAverage;
    }
    return undefined;
};

export const getIntendedTeamAverageSet = (averageSets: AverageSetsModel) => {
    const intendedTeamAverage = averageSets?.teamAverageSet;

    if (!isEmptyStat(intendedTeamAverage?.stats)) {
        return intendedTeamAverage;
    }
    return undefined;
};

export const findAthleteBenchmark = (averageSets: AverageSetsModel, athleteId: string, positionId: string) => {
    return (
        getIntendedAthleteAverageSet(averageSets, athleteId) ||
        getIntendedPositionAverageSet(averageSets, positionId) ||
        getIntendedTeamAverageSet(averageSets) ||
        averageSets.defaultBenchmark
    );
};

export const isEmptyStat = (stats: StatRow | undefined): boolean => {
    if (isEmpty(stats)) {
        return true;
    }

    let hasAllZeroValues = true;
    for (const key of REPORTING_PRIMARY_FIELDS) {
        if (isFinite(stats?.[key]) && stats?.[key] !== 0) {
            hasAllZeroValues = false;
            return hasAllZeroValues;
        }
    }
    return hasAllZeroValues;
};

export const SharedGridOptions: GridOptions = {
    getRowStyle: (params: RowClassParams) => {
        if (params.data.id === 'average') {
            return { fontWeight: 700 };
        }
    },
};

export const addBackgroundColor = (options: GridOptions, color = 'white'): GridOptions => {
    return {
        ...options,
        getRowStyle: (params: RowClassParams) => {
            const baseStyle = options.getRowStyle ? options.getRowStyle(params) : {};
            return {
                ...baseStyle,
                backgroundColor: color,
            };
        },
    };
};

export const calcTableHeight = (rows: Array<unknown>, hasDoubleRows?: boolean, hasFooter?: boolean) => {
    // 6 ch per row, plus 6 for header and / or footer, max height capped at 56ch
    let header = 6;
    let bodyHeight = rows.length * 6;
    let minHeight = 15;
    if (hasDoubleRows) {
        bodyHeight *= 1.5;
        minHeight *= 1.5;
    }
    if (hasFooter) {
        header *= 2;
    }
    const height = bodyHeight + header;

    if (height < minHeight) {
        return `${minHeight}ch`;
    }
    return height > 56 ? '56ch' : `${height}ch`;
};

export const getTableMetrics = (
    parameters: Parameter[],
    stat: StatRow,
    averageBuilder: Record<string, number[]> | null,
    initialValue: Record<string, number | string>,
    formatMetric: (key: string | undefined, val: number, isAverage?: boolean | undefined) => string | number,
    withAverage = true,
    isPercentage = false,
    isACRatio = false,
    benchmarkStats?: StatRow,
) => {
    const customUnitTypeMap: { [slug: string]: CustomUnitType } = {};
    CUSTOM_UNIT_TYPE.forEach((type) => {
        type.list.forEach((slug) => {
            customUnitTypeMap[slug] = type;
        });
    });

    for (const parameter of parameters) {
        const nextValue = stat[parameter.slug];
        const customType = customUnitTypeMap[parameter.slug];
        const unitType = customType ? customType.unitInfo?.unitType : parameter.unit_type;
        const isAverage = customType ? customType.unitInfo?.isAverage : false;

        if (isPercentage && benchmarkStats && parameter.unit_type !== ParameterUnitTypeEnum.Percentage) {
            const benchmarkValue = benchmarkStats[parameter.slug];
            const value = round(percentage(nextValue, benchmarkValue) || 0, 0);
            initialValue[parameter.slug] = value;
        } else if (isACRatio) {
            initialValue[parameter.slug] = round(nextValue, 2);
        } else {
            initialValue[parameter.slug] = formatMetric(unitType, nextValue, isAverage);
        }

        if (withAverage && averageBuilder) {
            if (averageBuilder[parameter.slug]) {
                averageBuilder[parameter.slug][0] += nextValue;
            } else {
                averageBuilder[parameter.slug] = [nextValue, parameter.unit_type];
            }
        }
    }

    return initialValue;
};

export const getTableComparisonMetrics = (
    parameters: Parameter[],
    stat1: StatRow,
    stat2: StatRow | undefined,
    // eslint-disable-next-line
    average2: any,
    // eslint-disable-next-line
    initialValue: any,
) => {
    const customUnitTypeMap: { [slug: string]: CustomUnitType } = {};
    CUSTOM_UNIT_TYPE.forEach((type) => {
        type.list.forEach((slug) => {
            customUnitTypeMap[slug] = type;
        });
    });

    for (const nextCell of parameters) {
        const nextValue1 = stat1[nextCell.slug];
        const nextValue2 = stat2?.[nextCell.slug] ?? 0;
        const customType = customUnitTypeMap[nextCell.slug];
        const unitType = customType ? customType.unitInfo?.unitType : nextCell.unit_type;
        const isAverage = customType ? customType.unitInfo?.isAverage : false;
        const originalUnitType = nextCell.unit_type;

        initialValue[nextCell.slug] = [nextValue1, nextValue2, unitType, isAverage, originalUnitType];

        if (average2[nextCell.slug]) {
            average2[nextCell.slug][0] += nextValue1;
            average2[nextCell.slug][1] += nextValue2;
        } else {
            average2[nextCell.slug] = [nextValue1, nextValue2, nextCell.unit_type, true];
        }
    }

    return initialValue;
};

export const getStartTimeEndTimeDuration = (activity: DeepActivity | undefined) => {
    const periodsArray = activity?.periods ?? [];
    const firstPeriod = periodsArray[0] || {};
    const lastPeriod = periodsArray[periodsArray.length - 1] || {};

    const firstPeriodStartTime = firstPeriod.start_time || 0;
    const lastPeriodEndTime = lastPeriod.end_time || 0;

    const noStartPeriodTime = !firstPeriodStartTime;
    const noEndPeriodTime = !lastPeriodEndTime;

    const activityStartTime = activity?.start_time || 0;
    const activityEndTime = activity?.end_time || 0;

    const startTime = firstPeriodStartTime || activityStartTime;
    const endTime = noEndPeriodTime ? activityEndTime : lastPeriodEndTime;

    const totalDuration =
        noStartPeriodTime || noEndPeriodTime
            ? getFormattedDuration(activityStartTime, activityEndTime)
            : getFormattedDuration(lastPeriodEndTime, firstPeriodStartTime);

    return {
        startTime,
        endTime,
        duration: totalDuration,
    };
};

export const formatComparison = (comparison: string | undefined, suffix: string): string => {
    return `${comparison || 'MD'} ${suffix}`;
};

export const isActivityDemoData = (activity?: DeepActivity): boolean => {
    return !!activity?.activity_tags.find((tag) => tag?.tag_name?.toLowerCase() === 'demo data');
};

export const getDisplayColor = (
    paramSlug: string,
    selectedParameters: SelectedParameters,
    data?: Highcharts.Options['series'],
) => {
    if (paramSlug === 'volume') {
        return teal;
    } else if (paramSlug === 'intensity') {
        return slate;
    } else if (paramSlug === 'overall') {
        return violet;
    } else if (selectedParameters.paramList.some((p) => p.slug === 'volume')) {
        return slate;
    } else if (
        selectedParameters.paramList.some((p) => p.slug === 'intensity') ||
        selectedParameters.paramList.some((p) => p.slug === 'overall')
    ) {
        return teal;
    } else if (data && data.length > 0) {
        return slate;
    } else {
        return teal;
    }
};

export const getFlattenedParameterSlugs = (parameters: TfReportingParameterGroup[] | undefined) => {
    const paramSlugs: string[] = [];
    parameters?.forEach((group) => {
        group.parameters.forEach((param) => paramSlugs.push(param.slug));
    });
    return paramSlugs;
};

export const isValidBenchmarkValue = (benchmarkValue: number) => {
    return !isNaN(benchmarkValue) && isFinite(benchmarkValue) && benchmarkValue !== 0;
};

export const isValidNumber = (value: number) => {
    return !isNaN(value) && isFinite(value);
};

export const getTagVariant = (tagName: string | undefined) => {
    switch (tagName?.toUpperCase()) {
        case 'FULL': {
            return 'successText';
        }
        case 'PART': {
            return 'informationText';
        }
        case 'MODIFIED': {
            return 'informationText';
        }
        case 'INJURED': {
            return 'alertText';
        }
        case 'REHAB': {
            return 'alertText';
        }
        case 'OTHER': {
            return 'neutral';
        }
        case 'N/A': {
            return 'neutral';
        }
        default: {
            return 'neutral';
        }
    }
};

export const filterSideMenuTagsFromTags = (tags: Tags[] | undefined, currentId: string): SelectOption[] => {
    return (
        tags
            ?.sort((tag1, tag2) => {
                if (tag1.name?.toLocaleLowerCase() === 'other') {
                    return 1;
                } else if (tag2.name?.toLocaleLowerCase() === 'other') {
                    return -1;
                } else {
                    return (
                        parseInt(tag1?.name?.replace('MD', '') || '0') - parseInt(tag2?.name?.replace('MD', '') || '0')
                    );
                }
            })
            ?.map((tag) => {
                const idText = tag.name?.includes('MD')
                    ? tag.name?.replace('MD', MATCH_DAY)?.replace(' ', '') ?? ''
                    : tag.name?.toLocaleLowerCase() ?? '';
                return {
                    id: idText,
                    value: tag.name ?? '',
                    isSelected: currentId === idText,
                };
            }) ?? []
    );
};

export const convertMonthDayYearToDayMonthYear = (date: string) => {
    const dateArray = date.split('/');
    return `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`;
};

export const getStatementTypeText = (statementType: string | undefined, __: Translator, isTitle = false) => {
    switch (statementType) {
        case STATEMENT_TYPE.daily:
            return __('misc.daily');
        case STATEMENT_TYPE.weekly:
            return __('misc.weekly');
        case STATEMENT_TYPE.fourWeekly:
            return isTitle ? __('misc.four_week_block') : __('misc.four_week');
        default:
            return '';
    }
};
