export const STATEMENT_TYPE = {
    daily: 'daily',
    weekly: 'weekly',
    fourWeekly: 'fourWeekly',
};

export const LONGITUDINAL_BASE_URL = '/reporting/longitudinal';

export const DAILY_STATEMENT_DAY_COUNT = 7;

export const LOWER_BAND_VALUE = 75;
export const MID_BAND_VALUE = 100;
export const HIGH_BAND_VALUE = 125;
