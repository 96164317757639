import { Box, DrawerProps, FormControlLabel, Switch, Typography, useTheme } from '@material-ui/core';
import {
    KEY_NOTIFICATIONS_NOTIFY_MAX_HR,
    KEY_NOTIFICATIONS_NOTIFY_MAX_VEL,
    KEY_NOTIFICATIONS_NOTIFY_TEAMS,
    KEY_NOTIFICATIONS_SHOW_UNREAD_ONLY,
    useGetSetting,
    useGetTeams,
    useSettingMutation,
} from 'api';
import { ReactComponent as CloseIcon } from 'assets/icons/16px/cancel.svg';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/16px/chevron-left.svg';
import { useTranslations } from 'i18n';
import mixpanel from 'mixpanel-browser';
import { Team } from 'oas';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { Drawer, IconButton, MultiSelect, MultiSelectProps, Option, usePrevious } from 'shared';
import styles from './notifications-drawer.module.css';

interface SettingsDrawerProps extends DrawerProps {
    onBack: () => void;
    onClose: () => void;
}

interface ProfileSettings {
    [KEY_NOTIFICATIONS_NOTIFY_MAX_VEL]: boolean;
    [KEY_NOTIFICATIONS_NOTIFY_MAX_HR]: boolean;
}

export const SettingsDrawer: React.FC<SettingsDrawerProps> = (props) => {
    const { onBack, onClose, open, ...rest } = props;
    const { __ } = useTranslations();
    const [unreadOnly, setUnreadOnly] = React.useState(false);
    const [selectedTeams, setSelectedTeams] = React.useState<Team[]>([]);

    const unreadOnlySetting = useGetSetting(KEY_NOTIFICATIONS_SHOW_UNREAD_ONLY);
    const maxVelSetting = useGetSetting(KEY_NOTIFICATIONS_NOTIFY_MAX_VEL);
    const maxHrSetting = useGetSetting(KEY_NOTIFICATIONS_NOTIFY_MAX_HR);
    const selectedTeamsSetting = useGetSetting(KEY_NOTIFICATIONS_NOTIFY_TEAMS);
    const settingMutation = useSettingMutation();
    const { data: teams } = useGetTeams();

    const toggleUnreadOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
        settingMutation.mutate({
            key: KEY_NOTIFICATIONS_SHOW_UNREAD_ONLY,
            value: event.target.checked ? '1' : '0',
        });
        mixpanel.track(`BellNotificationSettingsToggle${KEY_NOTIFICATIONS_SHOW_UNREAD_ONLY}`, {
            toggleValue: event.target.checked,
        });
    };

    const [profileToggles, setProfileToggles] = React.useState<ProfileSettings>({
        [KEY_NOTIFICATIONS_NOTIFY_MAX_VEL]: true,
        [KEY_NOTIFICATIONS_NOTIFY_MAX_HR]: true,
    });

    const getToggleSettingHandler = (setting: keyof ProfileSettings) => {
        return () => {
            const newValue = !(profileToggles[setting] !== false);
            settingMutation.mutate({ key: setting, value: newValue ? '1' : '0' });
            mixpanel.track(`BellNotificationSettingsToggle${setting}`, { toggleValue: newValue });
        };
    };

    const handleTeamsChange: MultiSelectProps['onChange'] = (_event, newValue) => {
        const newTeamIds = (newValue as Option[]).map((option) => option.id);
        const newTeams = (teams || []).filter((team) => newTeamIds.includes(team.id));
        settingMutation.mutate({
            key: KEY_NOTIFICATIONS_NOTIFY_TEAMS,
            value: newTeams.map((team) => team.id).join(','),
        });
        mixpanel.track('BellNotificationSettingsUpdateTeams', { teams: newTeams.map((team) => team.name) });
    };

    React.useEffect(() => {
        setUnreadOnly(unreadOnlySetting?.value === '1');
    }, [unreadOnlySetting]);

    React.useEffect(() => {
        setProfileToggles({
            [KEY_NOTIFICATIONS_NOTIFY_MAX_VEL]: !maxVelSetting || maxVelSetting.value === '1',
            [KEY_NOTIFICATIONS_NOTIFY_MAX_HR]: !maxHrSetting || maxHrSetting.value === '1',
        });
    }, [maxVelSetting, maxHrSetting]);

    React.useEffect(() => {
        if (selectedTeamsSetting) {
            const selectedTeamIds = selectedTeamsSetting.value.split(',').filter((id) => id !== '');
            setSelectedTeams(teams?.filter((team) => selectedTeamIds.includes(team.id)) || []);
        } else {
            setSelectedTeams(teams?.filter((team) => team.is_default) || []);
        }
    }, [selectedTeamsSetting, teams]);

    const wasOpen = usePrevious(open);
    React.useEffect(() => {
        if (open !== wasOpen && wasOpen !== undefined) {
            mixpanel.track(open ? 'BellNotificationSettingsPanelOpened' : 'BellNotificationSettingsPanelClosed', {
                teams: selectedTeams.map((team) => team.name),
                [KEY_NOTIFICATIONS_SHOW_UNREAD_ONLY]: unreadOnly,
                ...profileToggles,
            });
        }
    }, [open, wasOpen, selectedTeams, profileToggles, unreadOnly]);

    const theme = useTheme();

    return (
        <Drawer
            variant="persistent"
            anchor="right"
            open={open}
            {...rest}
            SlideProps={{
                style: {
                    opacity: 0,
                },
                onEntering: (node) => {
                    node.style.transition = theme.transitions.create(['opacity'], {
                        easing: theme.transitions.easing.easeOut,
                        duration: 100,
                    });
                    node.style.transform = 'none';
                    node.style.opacity = '100%';
                    node.style.pointerEvents = 'initial';
                },
                onExiting: (node) => {
                    node.style.transition = theme.transitions.create(['opacity'], {
                        easing: theme.transitions.easing.easeOut,
                        duration: 100,
                    });
                    node.style.transform = 'none';
                    node.style.opacity = '0';
                    node.style.pointerEvents = 'none';
                },
            }}
        >
            <Box width={500} position="relative" display="flex" flexDirection="column" height="100%">
                <div className={styles['back-button']}>
                    <IconButton aria-label="close" onClick={onBack} id="notification-settings-drawer-back-button">
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <div className={styles['close-button']}>
                    <IconButton aria-label="close" onClick={onClose} id="notification-settings-drawer-close-button">
                        <CloseIcon />
                    </IconButton>
                </div>
                <Box m={3} textAlign="center">
                    <Typography variant="h4" id="notification-settings-title">
                        {__('misc.settings')}
                    </Typography>
                </Box>
                <Box m={3} mt={2}>
                    <Typography variant="h5">{__('misc.general')}</Typography>
                </Box>
                <Box mx={3}>
                    <FormControlLabel
                        style={{ margin: 0 }}
                        control={
                            <Switch
                                size="small"
                                checked={unreadOnly}
                                onChange={toggleUnreadOnly}
                                color="primary"
                                id="notification-settings-toggle-unread-only"
                            />
                        }
                        label={__('notifications.only_show_unread')}
                    />
                </Box>
                <Box m={3}>
                    <Typography variant="h5">{__('notifications.activity_notifications')}</Typography>
                </Box>
                <Box m={3} mt={0}>
                    <Typography variant="body1">{__('notifications.i_want_to_be_notified')}</Typography>
                </Box>
                <Box mx={3} mb={2}>
                    <FormControlLabel
                        style={{ margin: 0 }}
                        control={
                            <Switch
                                size="small"
                                checked={profileToggles[KEY_NOTIFICATIONS_NOTIFY_MAX_VEL]}
                                onChange={getToggleSettingHandler(KEY_NOTIFICATIONS_NOTIFY_MAX_VEL)}
                                color="primary"
                                id="notification-settings-toggle-max-vel"
                            />
                        }
                        label={
                            <ReactMarkdown className="strip-p">{__('notifications.when_new_max_vel')}</ReactMarkdown>
                        }
                    />
                </Box>
                <Box mx={3} mb={2}>
                    <FormControlLabel
                        style={{ margin: 0 }}
                        control={
                            <Switch
                                size="small"
                                checked={profileToggles[KEY_NOTIFICATIONS_NOTIFY_MAX_HR]}
                                onChange={getToggleSettingHandler(KEY_NOTIFICATIONS_NOTIFY_MAX_HR)}
                                color="primary"
                                id="notification-settings-toggle-max-hr"
                            />
                        }
                        label={<ReactMarkdown className="strip-p">{__('notifications.when_new_max_hr')}</ReactMarkdown>}
                    />
                </Box>
                <Box mx={3} mt={1} mb={2}>
                    <Typography variant="body1">
                        <label htmlFor="notification-settings-select-teams">{__('notifications.which_teams')}</label>
                    </Typography>
                </Box>
                <Box mx={3} mb={2}>
                    <MultiSelect options={teams || []} value={selectedTeams || []} onChange={handleTeamsChange} />
                </Box>
            </Box>
        </Drawer>
    );
};
