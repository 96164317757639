import * as React from 'react';
import { Prompt } from 'react-router-dom';

interface UnsavedChangesPromptProps {
    message: string;
    when: boolean;
    ignoreHash?: boolean;
    onNavigateAway?: boolean;
}

export const UnsavedChangesPrompt: React.FC<UnsavedChangesPromptProps> = (props) => {
    const { when, message, onNavigateAway = true, ignoreHash = false } = props;

    React.useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (when) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [when]);

    return (
        // https://v5.reactrouter.com/core/api/Prompt
        <Prompt
            when={when && onNavigateAway}
            message={(_location, action) => {
                if (ignoreHash && action !== 'PUSH') {
                    return true;
                }

                return message;
            }}
        />
    );
};
