import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { NotificationView } from '../notification-view';
import { NotificationTypeProps } from './notification-type-props';
import { useTranslations } from 'i18n';
import { parseJSON } from 'date-fns';

export const ActivityOwnership: React.FC<NotificationTypeProps> = (props) => {
    const { notification, handleToggleRead } = props;

    const { __ } = useTranslations();
    const [title, setTitle] = React.useState<React.ReactNode>(<></>);
    const [contents, setContents] = React.useState<React.ReactNode>(<></>);
    const createdAt = parseJSON(notification.created_at);

    React.useEffect(() => {
        if (!notification.activities) {
            return;
        }

        if (notification.activities.length === 1) {
            setTitle(
                __('notifications.activity_ownership_single_title', {
                    activityName: notification.activities[0].name,
                }),
            );
            setContents(__('notifications.activity_ownership_single_body'));
        } else {
            setTitle(
                __('notifications.activity_ownership_multiple_title', {
                    numberOfActivities: `${notification.activities.length}`,
                }),
            );
            if (notification.activities.length === 2) {
                setContents(
                    <ReactMarkdown>
                        {__('notifications.activity_ownership_multiple_2_body', {
                            activity1: notification.activities[0].name,
                            activity2: notification.activities[1].name,
                        })}
                    </ReactMarkdown>,
                );
            } else if (notification.activities.length === 3) {
                setContents(
                    <ReactMarkdown>
                        {__('notifications.activity_ownership_multiple_3_body', {
                            activity1: notification.activities[0].name,
                            activity2: notification.activities[1].name,
                            activity3: notification.activities[2].name,
                        })}
                    </ReactMarkdown>,
                );
            } else if (notification.activities.length === 4) {
                setContents(
                    <ReactMarkdown>
                        {__('notifications.activity_ownership_multiple_4_body', {
                            activity1: notification.activities[0].name,
                            activity2: notification.activities[1].name,
                            activity3: notification.activities[2].name,
                        })}
                    </ReactMarkdown>,
                );
            } else {
                // 4 or more
                setContents(
                    <ReactMarkdown>
                        {__('notifications.activity_ownership_multiple_5_or_more_body', {
                            activity1: notification.activities[0].name,
                            activity2: notification.activities[1].name,
                            activity3: notification.activities[2].name,
                            otherCount: `${notification.activities.length - 3}`,
                        })}
                    </ReactMarkdown>,
                );
            }
        }
    }, [notification, __]);

    return notification.activities?.length ? (
        <NotificationView
            title={title}
            contents={contents}
            createdAt={createdAt}
            isRead={notification.read === 1}
            onToggleRead={handleToggleRead}
        />
    ) : (
        <></>
    );
};
