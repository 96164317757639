import { TfNotification, transformNotification } from 'api';
import { NotificationsApi } from 'oas';
import { UseQueryResult } from '@tanstack/react-query';
import { NOTIFICATION_REFRESH_INTERVAL } from 'shared';
import { useApiQuery } from '../use-api-query';
import { useAuth } from 'auth';

export const useGetNotifications = (
    ...parameters: Parameters<typeof NotificationsApi.prototype.getNotifications>
): UseQueryResult<TfNotification[]> => {
    const { user } = useAuth();
    return useApiQuery(
        ['notifications', parameters],
        (apiConfig, axiosInstance) =>
            new NotificationsApi(apiConfig, '', axiosInstance).getNotifications(...parameters).then((response) => ({
                ...response,
                data: response.data.map(transformNotification),
            })),
        {
            refetchInterval: NOTIFICATION_REFRESH_INTERVAL,
            enabled:
                user &&
                !(user.need_to_set_password || user.need_to_accept_eula || user.need_to_setup_mfa || !user?.email),
        },
    );
};
