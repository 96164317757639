import * as React from 'react';
import { Typography } from '@SBGSports/referee-react';
import styles from './pages.module.css';

interface ErrorLayoutProps {
    code: string;
    title: string;
    message?: React.ReactElement | string;
}

export const ErrorDisplay: React.FC<ErrorLayoutProps> = ({ code, title, message }) => {
    return (
        <>
            <Typography variant={'heading-1'} className={styles['error-code-text']}>
                {code}
            </Typography>
            <Typography variant="heading-2" className={styles['error-display-text']}>
                {title}
            </Typography>
            <Typography variant="body-2" className={styles['error-display-text']}>
                {message}
            </Typography>
        </>
    );
};
