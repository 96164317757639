import { useInView } from 'react-intersection-observer';

interface RenderInViewportProps {
    children: React.ReactNode;
}

export const RenderWhenInViewport: React.FC<RenderInViewportProps> = ({ children }) => {
    const { ref, inView } = useInView();

    return <div ref={ref}>{inView ? children : <div />}</div>;
};
