import * as React from 'react';
import { useAuth } from 'auth';
import { useHasFeature } from 'api';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { FrontendConfigFeaturesEnum } from 'oas';
import { useGetMappedUserLanguage } from '../language/use-get-mapped-user-language';

export const ZenDesk: React.FC = () => {
    const { user } = useAuth();
    const [isZDloaded, setIsZDloaded] = React.useState(false);
    const vectorCoreHelpCenter = 'a18e100a-5532-4b15-813b-225eb53241f7';
    const openFieldWebHelpCenter = '9a1e2e81-cca2-46d3-95fc-83b94c5d3688';

    const userLanguage = useGetMappedUserLanguage();
    const showVectorCoreHelpCenter = useHasFeature(FrontendConfigFeaturesEnum.ShowCoreHelpCenter);

    const { indeterminate, isAuthenticated } = useAuth();
    const isLoading = indeterminate || !isAuthenticated;

    React.useEffect(() => {
        if (user && !isLoading && showVectorCoreHelpCenter !== null && isZDloaded) {
            ZendeskAPI('messenger:set', 'locale', userLanguage);
        }
    }, [isLoading, showVectorCoreHelpCenter, isZDloaded, user, userLanguage]);

    return (
        <>
            {process.env.REACT_APP_ENVIRONMENT !== 'dev' && !isLoading && showVectorCoreHelpCenter !== null ? (
                <Zendesk
                    zendeskKey={showVectorCoreHelpCenter ? vectorCoreHelpCenter : openFieldWebHelpCenter}
                    onLoaded={() => setIsZDloaded(true)}
                />
            ) : null}
        </>
    );
};
