import * as React from 'react';
import { useAuthUserGuard } from 'auth';
import { UserPermissionsEnum } from 'oas';
import { Error404 } from 'pages';
import { usePermission } from './use-permission';
import { Redirect } from 'react-router-dom';

interface PermissionGuardProps {
    permission: UserPermissionsEnum;
}

export const PermissionGuard: React.FC<React.PropsWithChildren<PermissionGuardProps>> = ({ permission, children }) => {
    // Hooks
    const redirectUri = useAuthUserGuard();
    const allowed = usePermission(permission);

    if (redirectUri) {
        return <Redirect to={redirectUri} />;
    }

    return (
        <>
            {allowed ? (
                children
            ) : (
                <>
                    <div style={{ display: 'none' }}> PermissionGuard: {permission}</div>
                    <Error404 />
                </>
            )}
        </>
    );
};
