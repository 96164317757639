import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import {
    AthleteThresholdsApi,
    CreateThresholdSetRequest,
    DeleteThresholdSetsRequest,
    RestoreThresholdSetsRequest,
    ThresholdAlertSettings,
    ThresholdSetUpdateRequest,
} from 'oas';
import { useApiMutation } from '../use-api-mutation';
import { TfThresholdSet, transformThresholdSet } from './transform';
import axios from 'axios';

export const useDeleteThresholdSets = (): UseMutationResult<void, unknown, DeleteThresholdSetsRequest> => {
    const queryClient = useQueryClient();

    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            await new AthleteThresholdsApi(apiConfig, '', axiosInstance).deleteThresholdSets(attr);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ['threshold_sets'],
                });
            },
        },
    );
};

export const useRestoreThresholdSets = (): UseMutationResult<void, unknown, RestoreThresholdSetsRequest> => {
    const queryClient = useQueryClient();

    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            await new AthleteThresholdsApi(apiConfig, '', axiosInstance).restoreThresholdSets(attr);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ['threshold_sets'],
                });
            },
        },
    );
};

export const useCreateThresholdSet = (): UseMutationResult<
    TfThresholdSet,
    unknown,
    CreateThresholdSetRequest & ThresholdSetUpdateRequest
> => {
    const queryClient = useQueryClient();
    const updater = useUpdateThresholdSet();

    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            const createdThresholdSet = (
                await new AthleteThresholdsApi(apiConfig, '', axiosInstance).createThresholdSet({ name: attr.name })
            )?.data;
            const newAttr = { ...attr, id: createdThresholdSet.id };

            return updater.mutateAsync(newAttr);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ['threshold_sets'],
                });
            },
        },
    );
};

export const useUpdateThresholdSet = (): UseMutationResult<
    TfThresholdSet,
    unknown,
    ThresholdSetUpdateRequest & { id: string }
> => {
    const queryClient = useQueryClient();

    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            return transformThresholdSet(
                (
                    await new AthleteThresholdsApi(apiConfig, '', axiosInstance).updateThresholdSet(
                        attr.id,
                        ['thresholdSetAthletes', 'thresholdSetAthletes.athlete', 'thresholdSetAthletes.parameters'],
                        attr,
                    )
                )?.data,
            );
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ['threshold_sets'],
                });
            },
        },
    );
};

export const useUpdateThresholdAlerts = (): UseMutationResult<void, unknown, ThresholdAlertSettings> => {
    const queryClient = useQueryClient();

    return useApiMutation(
        async (attr, apiConfig, axiosInstance) => {
            const response = await new AthleteThresholdsApi(apiConfig, '', axiosInstance).updateThresholdAlerts(attr);
            if (axios.isAxiosError(response)) {
                throw response;
            }

            return response?.data;
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ['threshold_alerts'],
                });
            },
        },
    );
};
