import * as React from 'react';
import { Select, SelectOption } from '@SBGSports/referee-react';
import { useTranslations } from 'i18n';
import { AverageSetSummary, Tags } from '../../oas';
import { useGetAllTagsOfTagType, useGetAverageSummary } from 'api';
import { DAY_CODE_TAG_TYPE } from '../../shared';
import mixpanel from 'mixpanel-browser';
import { ACTIONS, EVENTS } from '../../analytics/analytics-events';
import { ATHLETE, MATCH_DAY } from 'reporting/constants';

interface CompareToFilterProps {
    defaultSelected: string;
    onFilterChange: (selectedOption: string) => void;
    addAthleteOption?: boolean;
}

const generateCompareToOptions = (tags: Tags[] | undefined, currentId: string): SelectOption[] => {
    return (
        tags
            ?.filter((tag) => tag.name?.includes(MATCH_DAY))
            ?.sort(
                (tag1, tag2) =>
                    parseInt(tag1?.name?.replace(MATCH_DAY, '') || '0') -
                    parseInt(tag2?.name?.replace(MATCH_DAY, '') || '0'),
            )
            ?.map((tag) => {
                const idText = tag.name?.replace(' ', '') ?? '';
                return {
                    id: idText,
                    value: tag.name ?? '',
                    isSelected: currentId === idText,
                };
            }) ?? []
    );
};

export const shouldDisableAverageOption = (optionId: number | string, data: AverageSetSummary[]): boolean => {
    const upperCaseOptionId = String(optionId).toUpperCase();
    if (upperCaseOptionId === 'OTHER' || upperCaseOptionId === MATCH_DAY) {
        return false;
    }

    const foundOption = data.find((option: AverageSetSummary) => option.name === String(optionId));
    return foundOption?.is_empty ?? true;
};

export const CompareToFilter: React.FC<CompareToFilterProps> = (props) => {
    const { __ } = useTranslations();
    const { defaultSelected, onFilterChange, addAthleteOption } = props;
    const { data: tags } = useGetAllTagsOfTagType(DAY_CODE_TAG_TYPE);

    const matchDayCompareToOptions = React.useMemo(() => {
        return generateCompareToOptions(tags, defaultSelected);
    }, [defaultSelected, tags]);

    const { data: averageSummary } = useGetAverageSummary(
        'team',
        matchDayCompareToOptions?.map((option) => String(option.id)),
    );

    const compareToOptions: SelectOption[] = React.useMemo(() => {
        const matchDayOptions: SelectOption[] = matchDayCompareToOptions?.map((option) => {
            return {
                ...option,
                isDisabled: shouldDisableAverageOption(option.id, averageSummary ?? []),
            };
        });
        if (addAthleteOption) {
            const athleteCompareToOptions: SelectOption[] = [
                { value: 'Athlete', id: ATHLETE, isSelected: defaultSelected === ATHLETE.toUpperCase() },
            ];
            return athleteCompareToOptions.concat(matchDayOptions);
        }
        return matchDayOptions;
    }, [addAthleteOption, averageSummary, defaultSelected, matchDayCompareToOptions]);

    const defaultOption = compareToOptions?.find(
        (option: SelectOption) => String(option.id).toUpperCase() === defaultSelected,
    );
    const handleCompareToOptionSelect = (selectedOptions: SelectOption[]) => {
        const selected: string = selectedOptions?.map((selectedOption) => selectedOption.id?.toString() ?? '')?.[0];
        mixpanel.track(EVENTS.reporting.compareToFilter, {
            action: 'Option ' + ACTIONS.selected,
            selectedOption: selected,
        });
        onFilterChange(selected);
    };

    return (
        <Select
            testId={'compare-to-filter'}
            label={__('reporting.compare_to')}
            menuItems={compareToOptions}
            defaultOption={defaultOption}
            onSelectItem={handleCompareToOptionSelect}
            size="small"
            maxDisplayValues={2}
            isExternalUpdated
            deselectAllOnClear
        />
    );
};
