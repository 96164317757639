import { Connection, ConnectionType, ConnectionsApi } from 'oas';
import { useApiQuery } from '../use-api-query';
import { UseQueryResult } from '@tanstack/react-query';

export const useGetConnections = (): UseQueryResult<Connection[]> => {
    return useApiQuery(['connections'], (apiConfig, axiosInstance) =>
        new ConnectionsApi(apiConfig, '', axiosInstance).getConnections(),
    );
};

export const useGetConnectionTypes = (): UseQueryResult<ConnectionType[]> => {
    return useApiQuery(['connectionTypes'], (apiConfig, axiosInstance) =>
        new ConnectionsApi(apiConfig, '', axiosInstance).getConnectionTypes(),
    );
};
