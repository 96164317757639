import * as React from 'react';

export interface ContainerLayoutShape {
    width?: number;
    height?: number;
    screenTop?: number;
    screenLeft?: number;
}

const getLayout = (elementRef: React.RefObject<HTMLElement>) => ({
    width: elementRef.current?.offsetWidth,
    height: elementRef.current?.offsetHeight,
    screenTop: elementRef.current?.getBoundingClientRect()?.top,
    screenLeft: elementRef.current?.getBoundingClientRect().left,
});

export const useContainerLayout = (elementRef: React.RefObject<HTMLElement>) => {
    const [layout, setLayout] = React.useState<ContainerLayoutShape>(getLayout(elementRef));

    React.useLayoutEffect(() => {
        if (!elementRef.current) {
            return;
        }
        const handleLayoutChange = () => {
            setLayout(getLayout(elementRef));
        };

        const resizeObserver = new ResizeObserver(handleLayoutChange);
        resizeObserver.observe(elementRef.current);

        window.addEventListener('resize', handleLayoutChange);
        return () => {
            resizeObserver.disconnect();
            window.removeEventListener('resize', handleLayoutChange);
        };
        // In some situations, this only works when using elementRef.current
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef.current]);

    return layout;
};
