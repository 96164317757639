import { AthleteAvatar } from 'athletes';
import { Translator } from 'i18n';
import { Notification } from 'oas';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { round } from 'shared';
import styles from '../notification-view.module.css';

export const getProfileVars = (
    __: Translator,
    myNotification: Notification,
): {
    athleteName: string;
    athleteIcon: React.ReactNode;
    oldMax: number;
    newMax: number;
    changeDetails: React.ReactNode;
    periodDetails: React.ReactNode;
} => {
    const athlete = myNotification.athletes?.[0];
    const athleteName = `${athlete?.first_name} ${athlete?.last_name}`;
    const athleteIcon = athlete ? <AthleteAvatar athlete={athlete} size="small" /> : null;
    const oldMax = myNotification.payload?.old_max || 0;
    const newMax = myNotification.payload?.new_max || 0;
    const changePercent = oldMax === 0 ? '-' : round(((newMax - oldMax) / oldMax) * 100, 1);
    const changeDetails =
        (oldMax > 0 && <span className={styles['profile-value-change']}>(+{changePercent}%)</span>) || '';
    const periodDetails = (
        <ReactMarkdown>
            {__('notifications.period_in_activity', {
                periodName: myNotification.period?.name || '',
                activityName: myNotification.activities?.[0].name || '',
            })}
        </ReactMarkdown>
    );

    return {
        athleteName,
        athleteIcon,
        oldMax,
        newMax,
        changeDetails,
        periodDetails,
    };
};
