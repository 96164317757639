import { parseJSON } from 'date-fns';
import { LeagueMigration, LeagueTeamActivity, LeagueTeamChild } from 'oas';

export interface TfLeagueTeamChild {
    customerId: number;
    customerName: string;
    last_sync: string;
    latest_game: string | undefined;
    latest_game_status: string;
    total_migrations: string;
}

export const transformLeagueTeamChild = (row: LeagueTeamChild): TfLeagueTeamChild => {
    return {
        customerId: row.customer.id,
        customerName: row.customer.name,
        last_sync: row.last_sync_time,
        latest_game: row.last_activity?.name,
        latest_game_status: row.latest_game_status,
        total_migrations: `${row.total_migrations}/${row.total_activities}`,
    };
};

export interface TfLeagueMigration {
    id: string;
    team_name: string;
    customer_id: number;
    activity_name: string;
    migration_status: string;
    migration_status_message: string;
    modified_at: Date;
}

export const transformMigration = (row: LeagueMigration): TfLeagueMigration => {
    return {
        id: row.activity.id,
        team_name: row.customer.name,
        customer_id: row.customer.id,
        activity_name: row.activity.name,
        migration_status: row.status,
        migration_status_message: row.status_message || '',
        modified_at: parseJSON(row.modified_at),
    };
};

export interface TfLeagueTeamActivity {
    id: string;
    activity_name: string;
    activity_dateorig: string;
    activity_date: Date;
    last_full_sync: Date | null;
    last_full_sync_ago: string | null;
    files: string;
    athletes: number;
    master_athlete_count: number;
    migration_status: string;
    migrate_job_id: string | null;
    period_count: number;
    annotation_count: number;
}

export const transformLeagueTeamActivity = (row: LeagueTeamActivity): TfLeagueTeamActivity => {
    return {
        id: row.id,
        activity_name: row.name,
        activity_dateorig: row.activity_date,
        activity_date: parseJSON(row.activity_date),
        last_full_sync: row.last_fullsync_time ? parseJSON(row.last_fullsync_time) : null,
        last_full_sync_ago: row.last_fullsync_time_ago || null,
        files: `${row.parent_documents.length}/${row.child_documents.length}`,
        athletes: row.athlete_count,
        master_athlete_count: row.master_athlete_count,
        migration_status: row.migration_status,
        migrate_job_id: row.migrate_job_id,
        period_count: row.period_count,
        annotation_count: row.annotation_count,
    };
};
