import * as React from 'react';

export const keyboardEventToSeconds = (event: React.KeyboardEvent): number | undefined => {
    let addSeconds;
    switch (event.key) {
        case 'ArrowUp':
            addSeconds = 1;
            break;
        case 'ArrowDown':
            addSeconds = -1;
            break;
        case 'PageUp':
            addSeconds = 60;
            break;
        case 'PageDown':
            addSeconds = -60;
            break;
        default:
            return;
    }

    if (event.shiftKey) {
        addSeconds *= 60;
    }

    return addSeconds;
};
