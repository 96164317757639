import { useHasFeature } from 'api';
import { useAuth } from 'auth';
import { FrontendConfigFeaturesEnum } from 'oas';
import * as React from 'react';
import { useGetMappedUserLanguage } from '../language/use-get-mapped-user-language';

declare global {
    interface Window {
        usetifulTags: {
            userId?: string;
            language?: string;
            accountType?: string;
        };
    }
}

export const Usetiful: React.FC = () => {
    const { user } = useAuth();
    const userLanguage = useGetMappedUserLanguage();
    const addedUserId = React.useRef<string>();
    const { indeterminate, isAuthenticated } = useAuth();
    const isLoaded = !indeterminate && isAuthenticated;
    const showCoreOnboarding = useHasFeature(FrontendConfigFeaturesEnum.ShowCoreOnboarding);

    React.useEffect(() => {
        if (isLoaded && user && showCoreOnboarding !== null) {
            const userAccountType = showCoreOnboarding ? 'vectorCore' : 'vectorPro';
            const userId = `${user.id}-${process.env.REACT_APP_ENVIRONMENT}`;
            if (addedUserId.current !== userId) {
                addedUserId.current = userId;
                window.usetifulTags = {
                    userId: user.id,
                    language: userLanguage,
                    accountType: userAccountType,
                };
                const headEl = document.getElementsByTagName('head')[0];
                const scriptEl = document.createElement('script');
                scriptEl.async = true;
                scriptEl.src = 'https://www.usetiful.com/dist/usetiful.js';
                scriptEl.setAttribute('id', 'usetifulScript');
                scriptEl.dataset.token = '1078151311b23a2de48bacac007b0aaf';
                headEl.appendChild(scriptEl);
            }
        }
    }, [user, userLanguage, isLoaded, showCoreOnboarding]);

    return <></>;
};
