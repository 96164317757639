import { useApiMutation } from 'api/use-api-mutation';
import axios from 'axios';
import { DemoDataApi } from 'oas';
import { UseMutationResult, useQueryClient } from '@tanstack/react-query';

export const useRemoveDemoData = (): UseMutationResult<unknown, unknown, void> => {
    const queryClient = useQueryClient();

    return useApiMutation(
        async (_null, apiConfig, axiosInstance) => {
            const response = await new DemoDataApi(apiConfig, '', axiosInstance).removeDemoData();

            if (axios.isAxiosError(response)) {
                throw response;
            }
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ['activities'],
                });
                queryClient.invalidateQueries({
                    queryKey: ['demoDataStatus'],
                });
            },
        },
    );
};
