import { InlineResponse2003, PingApi } from 'oas';
import { useApiQuery } from '../use-api-query';
import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

export const useGetPing = (options?: Partial<UseQueryOptions>): UseQueryResult<InlineResponse2003> => {
    return useApiQuery(
        ['ping'],
        (apiConfig, axiosInstance) => new PingApi(apiConfig, '', axiosInstance).ping(),
        options,
    );
};
