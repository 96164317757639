import { CustomUnitType, ParticipationTag, StatType, StatsModel } from './types';
import { ParameterUnitTypeEnum } from '../oas';
import { ColDef } from 'ag-grid-community';

export const NON_AVAILABLE_TEAM_MEMBERS = 'N/A';

export const NA_PARTICIPATION: ParticipationTag = { name: NON_AVAILABLE_TEAM_MEMBERS, variant: 'neutral' };
export const DEFAULT_PARTICIPATION_TAGS: ParticipationTag[] = [
    { name: 'Full', variant: 'successText' },
    { name: 'Modified', variant: 'informationText' },
    { name: 'Part', variant: 'informationText' },
    { name: 'Injured', variant: 'alertText' },
    { name: 'Rehab', variant: 'alertText' },
    { name: 'Other', variant: 'neutral' },
];
export const PARTICIPATION_TAGS: ParticipationTag[] = [...DEFAULT_PARTICIPATION_TAGS, NA_PARTICIPATION];

export const ACTIVITY_REPORT_TABS = {
    summary: 'SUMMARY',
    periods: 'PERIODS',
    athletes: 'ATHLETES',
};

export const ATHLETE_REPORT_TABS = {
    summary: 'SUMMARY',
    periods: 'PERIODS',
    traces: 'TRACES',
};

export const EmptyVolumeIntensity = {
    id: '',
    stats: {},
    statType: StatType.Activity,
    volumeMetrics: [
        {
            metricName: 'reporting.distance_in_unit',
            metricValue: 0,
            unitKey: 'misc.short_metres_lowercase',
        },
        {
            metricName: 'reporting.hs_distance_in_unit',
            metricValue: 0,
            unitKey: 'misc.short_metres_lowercase',
        },
        {
            metricName: 'reporting.accel-decel_number',
            metricValue: 0,
        },
    ],
    intensityMetrics: [
        {
            metricName: 'reporting.unit_per_minute',
            metricValue: 0,
            unitKey: 'misc.metres',
        },
        {
            metricName: 'reporting.hs_distance_unit_per_min',
            metricValue: 0,
            unitKey: 'misc.short_metres_lowercase',
        },
        {
            metricName: 'reporting.accel_decel_per_min',
            metricValue: 0,
        },
    ],
};

export const REPORT_TYPE = {
    activity: 'activity',
    athlete: 'athlete-in-activity',
    longitudinal: 'longitudinal',
    status: 'status',
    match_day: 'match-day',
};

export const REPORTING: Record<string, string> = {
    max_acceleration: 'max_effort_acceleration',
    max_deceleration: 'max_effort_deceleration',
    acceleration_efforts: 'gen2_acceleration_band6plus_average_effort_count',
    deceleration_efforts: 'gen2_acceleration_band3plus_average_effort_count',
    accel_and_decel_efforts: 'accel&decel_efforts',
    accel_and_decel_efforts_per_minute: 'accel&decel_efforts_per_minute',
    duration: 'average_duration_session',
    distance: 'average_distance_session',
    player_load: 'average_player_load_session',
    max_velocity: 'max_vel',
    max_velocity_percentage: 'percentage_max_velocity',
    meterage_per_minute: 'meterage_per_minute',
    player_load_per_min: 'player_load_per_minute',
    work_rest_ratio: 'vel_work_rest_ratio',
    impacts: 'imaimpacts_band2_average_count_session',
    max_heart_rate: 'max_heart_rate',
    average_heart_rate: 'mean_heart_rate',
    max_heart_rate_percentage: 'percentage_max_heart_rate',
    average_heart_rate_percentage: 'percentage_avg_heart_rate',
    heart_rate_exertion: 'hr_exertion',
    red_zone: 'red_zone',
    heart_rate_band_1_duration: 'heart_rate_band1_average_duration_session',
    heart_rate_band_2_duration: 'heart_rate_band2_average_duration_session',
    heart_rate_band_3_duration: 'heart_rate_band3_average_duration_session',
    heart_rate_band_4_duration: 'heart_rate_band4_average_duration_session',
    heart_rate_band_5_duration: 'heart_rate_band5_average_duration_session',
    heart_rate_band_6_duration: 'heart_rate_band6_average_duration_session',
    energy: 'energy',
    high_metabolic_load_distance: 'high_metabolic_load_distance',
    high_speed_distance: 'high_speed_distance',
    high_speed_distance_per_min: 'high_speed_distance_per_minute',
    high_speed_efforts: 'high_speed_efforts',
    sprint_distance_per_minute: 'sprint_distance_per_minute',
    standing_distance: 'velocity_band1_average_distance_session',
    walking_distance: 'velocity_band2_average_distance_session',
    jogging_distance: 'velocity_band3_average_distance_session',
    running_distance: 'velocity_band4_average_distance_session',
    high_intensity_distance: 'velocity_band5_average_distance_session',
    sprint_distance: 'velocity_band6_average_distance_session',
    sprint_efforts: 'sprint_efforts',
    volume: 'volume',
    intensity: 'intensity',
    overall: 'overall',
};

export const REPORTING_PRIMARY_FIELDS = [REPORTING.volume, REPORTING.intensity, REPORTING.overall];

export const DEFAULT_BENCHMARK_STATS = {
    [REPORTING.distance]: 10200,
    [REPORTING.high_speed_distance]: 625,
    [REPORTING.accel_and_decel_efforts]: 235,
    [REPORTING.meterage_per_minute]: 110,
    [REPORTING.high_speed_distance_per_min]: 6.5,
    [REPORTING.accel_and_decel_efforts_per_minute]: 2.5,
    [REPORTING.volume]: 100,
    [REPORTING.intensity]: 100,
    [REPORTING.overall]: 100,
};

export const MAX_REPORTING_PARAMETERS = [
    REPORTING.max_acceleration,
    REPORTING.max_deceleration,
    REPORTING.max_velocity,
    REPORTING.max_velocity_percentage,
    REPORTING.max_heart_rate,
];

export const PER_MIN_REPORTING_PARAMETERS: Record<string, string> = {
    [REPORTING.accel_and_decel_efforts_per_minute]: REPORTING.accel_and_decel_efforts,
    [REPORTING.player_load_per_min]: REPORTING.player_load,
    [REPORTING.meterage_per_minute]: REPORTING.distance,
    [REPORTING.sprint_distance_per_minute]: REPORTING.sprint_distance,
    [REPORTING.high_speed_distance_per_min]: REPORTING.high_speed_distance,
};

export const DEFAULT_ACTIVITY_BENCHMARKS: StatsModel = {
    id: 'defaultBenchmark',
    statType: StatType.Activity,
    stats: DEFAULT_BENCHMARK_STATS,
    volumeMetrics: [
        {
            metricName: 'reporting.distance_in_unit',
            metricValue: DEFAULT_BENCHMARK_STATS[REPORTING.distance],
            unitKey: 'misc.short_metres_lowercase',
        },
        {
            metricName: 'reporting.hs_distance_in_unit',
            metricValue: DEFAULT_BENCHMARK_STATS[REPORTING.high_speed_distance],
            unitKey: 'misc.short_metres_lowercase',
        },
        {
            metricName: 'reporting.accel-decel_number',
            metricValue: DEFAULT_BENCHMARK_STATS[REPORTING.accel_and_decel_efforts], //accel + decel average efforts (session)
        },
    ],
    intensityMetrics: [
        {
            metricName: 'reporting.unit_per_minute',
            metricValue: DEFAULT_BENCHMARK_STATS[REPORTING.meterage_per_minute],
            unitKey: 'misc.metres',
        },
        {
            metricName: 'reporting.hs_distance_unit_per_min',
            metricValue: DEFAULT_BENCHMARK_STATS[REPORTING.high_speed_distance_per_min],
            unitKey: 'misc.short_metres_lowercase',
        },
        {
            metricName: 'reporting.accel_decel_per_min',
            metricValue: DEFAULT_BENCHMARK_STATS[REPORTING.accel_and_decel_efforts_per_minute], //accel + decel average efforts (session) per minute,
        },
    ],
};

export const CUSTOM_UNIT_TYPE: CustomUnitType[] = [
    {
        group: 'zeroDecimal',
        list: [
            REPORTING.max_heart_rate_percentage,
            REPORTING.average_heart_rate_percentage,
            REPORTING.max_velocity_percentage,
            REPORTING.volume,
            REPORTING.intensity,
            REPORTING.overall,
        ],
        unitInfo: {
            unitType: ParameterUnitTypeEnum.Abitrary,
            isAverage: false,
        },
    },
    {
        group: 'oneDecimal',
        list: [
            REPORTING.max_acceleration,
            REPORTING.max_deceleration,
            REPORTING.player_load_per_min,
            REPORTING.work_rest_ratio,
        ],
        unitInfo: {
            unitType: ParameterUnitTypeEnum.Abitrary,
            isAverage: true,
        },
    },
    {
        group: 'twoDecimals',
        list: [
            REPORTING.accel_and_decel_efforts_per_minute,
            REPORTING.max_velocity,
            REPORTING.sprint_distance_per_minute,
        ],
        unitInfo: {
            unitType: ParameterUnitTypeEnum.Percentage,
            isAverage: false,
        },
    },
];

export const MATCH_DAY = 'MD';
export const ATHLETE = 'athlete';
export const COMPARE_TO = 'compare-to';
export const COMPARISON_ATHLETE = 'comparison-athlete';

export const DEFAULT_TABLE_COLUMN_DEF: ColDef = {
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    resizable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    suppressMovable: true,
    flex: 1,
    minWidth: 100,
    width: 100,
};

export const AMERICAN_LOCALE = 'American';

export const SCREEN_SIZE = window.screen.width;
export const MOBILE_SCREEN_SIZE = 568;
export const TABLET_MIN_SCREEN_SIZE = 768;
export const LAPTOP_MIN_SCREEN_SIZE = 1024;
export const GAUGE_BREAKPOINT_RESOLUTION = 1200;
export const LAPTOP_MAX_SCREEN_SIZE = 1440;

export const TABLE_VIEW_OPTION = {
    absolute: 'Absolute',
    percentage: 'Percentage',
    ac_ratio: 'A:C Ratio',
};

export const NORMAL_MAX_DISPLAYED_PERCENTAGE = 999;
export const LARGE_MAX_DISPLAYED_PERCENTAGE = 99999;

export const ACUTE_DURATION_SETTING = 'AcuteDuration';
export const CHRONIC_DURATION_SETTING = 'ChronicDuration';

export const ACUTE_RATIO_DAY_COUNT = 7;
export const CHRONIC_RATIO_DAY_COUNT = 28;
export const CHRONIC_RATIO_PAST_DATE_DAY_COUNT = 27;

export const SIX_WEEKS_COUNT = 6;
export const SIX_BLOCK_COUNT = 6;
export const TWENTY_SEVEN_WEEKS_COUNT = 27;

export const DAY_IN_MILLISECONDS = 86400000;
export const SEVEN_DAYS_IN_MILLISECONDS = 604800000;
export const SIX_WEEKS_IN_DAYS = 42;

export const DAY_ID_ARRAY = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const REPORTING_STATUS_VIEW_PATH = '/reporting/status';

export const REDIRECT_REPORT_TYPE = 'na';

export const AC_RATIO_LOW_THRESHOLD = 0.8;
export const AC_RATIO_HIGH_THRESHOLD = 1.3;

export const CHART_PARAMETERS_DEFAULT_LENGTH = 2;

export const NAME = 'name';
export const PERIOD_NAME = 'period_name';
export const START_TIME = 'start_time';

export const DEFAULT_TABLE_PARAM_MAX = 10;
