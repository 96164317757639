import * as React from 'react';

export interface ScreenSizeShape {
    width: number;
    height: number;
}

/**
 * Provides information about the screen size
 */
export const useWindowSize = (): ScreenSizeShape => {
    const [windowSize, setWindowSize] = React.useState<ScreenSizeShape>({
        width: window.innerWidth,
        height: innerHeight,
    });

    React.useEffect(() => {
        function onResize() {
            setWindowSize({
                width: window.innerWidth,
                height: innerHeight,
            });
        }

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return windowSize;
};
