import * as React from 'react';
import { useGetNotices } from 'api/notices';

export function useUnreadUpdatesCount(): number {
    const [unreadUpdateCount, setUnreadUpdateCount] = React.useState(0);
    const notices = useGetNotices();
    React.useEffect(() => {
        if (!notices.data) {
            return;
        }

        setUnreadUpdateCount(notices.data.filter((item) => !item.read).length);
    }, [notices]);

    return unreadUpdateCount;
}
